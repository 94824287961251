import React from 'react'
import styled from 'styled-components'

import BackButton from 'functional/BackButton'
import Logo from 'presentational/Logo'
import H1 from 'presentational/H1'
import Paragraph from 'presentational/Paragraph'

import { withTranslation } from 'react-i18next'

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  overflow-y: scroll;
`

const LimitWidth = styled.div`
  max-width: 640px;
  padding: 10vh 20px;
`

/**
 * Screen that displays all the legal agreements with the user.
 * @author Stjepan Golemac
 */
const LegalScreen = ({ t }) => (
  <Center>
    <LimitWidth>
      <BackButton />
      <Logo />
      <H1>{t('legal.title')}</H1>
      <Paragraph>{t('legal.paragraph1')}</Paragraph>
      <Paragraph>{t('legal.paragraph2')}</Paragraph>
      <Paragraph>{t('legal.paragraph3')}</Paragraph>
      <Paragraph>{t('legal.paragraph4')}</Paragraph>
      <Paragraph>{t('legal.paragraph5')}</Paragraph>
    </LimitWidth>
  </Center>
)

/** @component */
export default withTranslation('common')(LegalScreen)
