import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import FAQIcon from 'react-icons/lib/md/help'
import ContactIcon from 'react-icons/lib/md/question-answer'
import DocumentationIcon from 'react-icons/lib/md/library-books'

import H1 from 'presentational/H1'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import SearchInput from 'presentational/SearchInput'
import CardButton from 'presentational/CardButton'

const StyledH1 = styled(H1)`
  margin-top: 0;
`

const SearchBoxContainer = styled.div`
  padding: 55px 0;
  text-align: center;
`

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SeperationText = styled.p`
  text-align: center;
  font-size: 16px;
  margin: 40px 0;
  color: #808080;
`

const CardButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

class HelpScreen extends Component {
  render() {
    const {
      history: { push },
      t,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('help.title')}</H1>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('help.sub_header_description')}</Paragraph>
        </SubHeaderContainer>
        <ContentContainer>
          <CardButtonContainer>
            <CardButton
              title={t('help.faq_title')}
              description={t('help.faq_description')}
              icon={<FAQIcon />}
              onClick={() => push('/support/help/faq')}
            />
            <CardButton
              title={t('help.contact_title')}
              description={t('help.contact_description')}
              icon={<ContactIcon />}
              onClick={() => push('/support/help/contact')}
            />
            <CardButton
              title={t('help.documentation_title')}
              description={t('help.documentation_description')}
              icon={<DocumentationIcon />}
              onClick={() => push('/support/help/documentation')}
            />
          </CardButtonContainer>
        </ContentContainer>
      </LoggedInScreenContainer>
    )
  }
}

export default withTranslation('common')(HelpScreen)
