import React, { useState } from 'react'
import styled from 'styled-components'

import { shouldRenderError } from 'util'

import InputError from 'presentational/InputError'
import LabelButton from 'presentational/LabelButton'

import { useTranslation } from 'react-i18next'

const FileInputWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  padding: 5px 0;

  position: relative;
`

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const FileButtonInput = ({ input, meta, maxSize = -1, onSizeError }) => {
  const [file, setFile] = useState()
  const { error, submitError } = meta
  const { t, i18n } = useTranslation('common')

  return (
    <FileInputWrapper>
      <LabelButton htmlFor="assetFile">
        {file ? file : t('default.choose_file')}
      </LabelButton>
      <FileInput
        type="file"
        id="assetFile"
        accept="image/png, image/jpeg, .pdf, video/*"
        onChange={e => {
          const file = e.currentTarget.files[0]
          const fileReader = new FileReader()

          fileReader.onload = loadEvent => {
            const binaryData = loadEvent.target.result

            const base64 = binaryData.split(',')[1]
            if (maxSize != -1 && maxSize < file.size) {
              onSizeError()
            } else {
              setFile(file.name)
              input.onChange({ base64, name: file.name })
            }
          }

          fileReader.readAsDataURL(file)
        }}
      />
      {shouldRenderError(meta) && (
        <InputError>{error || submitError}</InputError>
      )}
    </FileInputWrapper>
  )
}

export default FileButtonInput
