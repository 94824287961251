import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

import CopyIcon from 'react-icons/lib/fa/clone'
import TimeIcon from 'react-icons/lib/md/access-time'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import H3 from 'presentational/H3'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import TextInput from 'presentational/TextInput'
import Paragraph from 'presentational/Paragraph'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  HeaderButtonContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import ConfirmNewAPIKeyModal from 'functional/ConfirmNewAPIKeyModal'
import { NotificationContext } from 'functional/NotificationProvider'

import ApiContainer from 'containers/ApiContainer'
import { withHandlers } from 'util/form'
import { formatDate } from 'util/date'
import { isURL } from 'util/validations'

const AutoWithButton = styled(Button)`
  width: auto;
  margin-right: 10px;
`

const ContentContainerWithoutPadding = styled(ContentContainer)`
  padding-left: 0;
`

const InputSection = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fafafa;
  width: 400px;
  justify-content: space-between;
`

const KeyInput = styled.input`
  border: none;
  width: 350px;
  background-color: #fafafa;
  color: #554c47;
  font-family: monospace;
  font-style: italic;
`

const CopyButton = styled(CopyIcon)`
  cursor: pointer;
`

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`

const CopyText = styled.p`
  margin: 0;
  padding-left: 10px;
  color: #5be45a;
  transition: 300ms all ease;
  will-change: opacity;

  opacity: ${props => (props.show ? '1' : '0')};
`

const StyledTimeIcon = styled(TimeIcon)`
  margin-right: 5px;
`

const GeneratedTimestamp = styled(Paragraph)`
  margin-top: 20px;
`

const LoaderWithMargin = styled(Loader)`
  margin-left: 10px;
`

const Codearea = styled.textarea`
  min-height: ${props => props.minHeight};
  width: 500px;
  background-color: #eee;
  border: none;
  resize: none;
`

const Highlight = styled.span`
  color: #d36841;
`

const WebhookSection = ({
  updateWebhook,
  webhookData,
  testWebhook,
  testWebhookLoading,
}) => {
  const { t, i18n } = useTranslation('common')

  return (
    <NotificationContext.Consumer>
      {context => (
        <ContentContainerWithoutPadding>
          <Section>
            <H2>{t('api.hook_title')}</H2>
            <Paragraph>{t('api.hook_description')}</Paragraph>
            <Form
              onSubmit={withHandlers(
                updateWebhook,
                () => {
                  context.methods.toggleTopNotification(
                    'success',
                    t('api.hook_save_success')
                  )
                },
                () => {
                  context.methods.toggleTopNotification(
                    'error',
                    t('api.hook_save_failed')
                  )
                }
              )}
              initialValues={webhookData}
              render={({ handleSubmit, submitting, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <FormFieldWrapper>
                    <Field
                      name="webhook"
                      component={TextInput}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                      label={t('api.hook_label')}
                      validate={isURL}
                    />
                  </FormFieldWrapper>
                  <AutoWithButton type="submit" disabled={submitting}>
                    {t('api.hook_save_button')}
                  </AutoWithButton>
                  <AutoWithButton
                    onClick={withHandlers(
                      testWebhook,
                      () =>
                        context.methods.toggleTopNotification(
                          'success',
                          t('api.webhook_test_success')
                        ),
                      () =>
                        context.methods.toggleTopNotification(
                          'error',
                          t('api.webhook_test_failed')
                        )
                    )}
                    disabled={submitting}
                  >
                    {t('api.webhook_test_button')}
                  </AutoWithButton>
                  {(submitting || testWebhookLoading) && <LoaderWithMargin />}
                </form>
              )}
            />
          </Section>
        </ContentContainerWithoutPadding>
      )}
    </NotificationContext.Consumer>
  )
}

const KeySection = ({
  generateApiKey,
  getApiKey,
  getLoading,
  data,
  requestLoading,
  getError,
}) => {
  useEffect(() => {
    if (data === null && !getLoading && getError === null) getApiKey()
  })

  const [copySuccess, setCopySuccess] = useState(false)
  const textAreaRef = useRef(null)
  const { t, i18n } = useTranslation('common')

  const copyToClipboard = event => {
    textAreaRef.current.select()
    document.execCommand('copy')
    event.target.focus()
    window.getSelection().removeAllRanges()
    setCopySuccess(true)

    setTimeout(() => {
      setCopySuccess(false)
    }, 1000)
  }

  return (
    <NotificationContext.Consumer>
      {context => (
        <ContentContainerWithoutPadding>
          <Section>
            <H2>{t('api.key_title')}</H2>
            <Paragraph>{t('api.key_description')}</Paragraph>
            <GeneratedTimestamp>
              <StyledTimeIcon size="20px" />
              {data !== null
                ? t('api.key_created', { date: formatDate(data.createdAt) })
                : ''}
            </GeneratedTimestamp>
            <InputContainer>
              <InputSection>
                <KeyInput
                  value={data ? data.key : ''}
                  innerRef={textAreaRef}
                  onChange={() => {}}
                />
                <CopyButton onClick={copyToClipboard} />
              </InputSection>
              <CopyText show={copySuccess}>{t('default.copied')}!</CopyText>
            </InputContainer>
            <AutoWithButton
              onClick={
                data
                  ? () =>
                      context.methods.openModal(() => (
                        <ConfirmNewAPIKeyModal
                          onSubmit={withHandlers(
                            generateApiKey,
                            () => {
                              context.methods.toggleTopNotification(
                                'success',
                                t('api.key_create_success')
                              )
                            },
                            () => {
                              context.methods.toggleTopNotification(
                                'error',
                                t('api.key_create_failed')
                              )
                            }
                          )}
                          onClose={() => context.methods.closeModal()}
                        />
                      ))
                  : withHandlers(
                      generateApiKey,
                      () => {
                        context.methods.toggleTopNotification(
                          'success',
                          t('api.key_create_success')
                        )
                      },
                      () => {
                        context.methods.toggleTopNotification(
                          'error',
                          t('api.key_create_failed')
                        )
                      }
                    )
              }
            >
              {t('api.key_create_button')}
            </AutoWithButton>
            {requestLoading ? <Loader /> : ''}

            <H3>{t('api.documentation_header')}</H3>
            <div>
              <p>
                GET /public/leads?key=<Highlight>apiKey</Highlight>
              </p>
              <Paragraph>
                {t('api.documentation_get_all_leads_description')}
              </Paragraph>
              <Codearea
                minHeight={'130px'}
                onChange={() => {}}
                value={
                  '[\n  {\n    "email": "test@email.com",\n    "registeredAt": "2020-02-06T11:09:01+00:00",\n    "id": "2cb201c9-47fd-492c-82d5-d6460010c4c6"\n  },\n  ...\n]'
                }
              ></Codearea>
            </div>
            <div>
              <p>
                GET /public/leads/<Highlight>leadId</Highlight>?key=
                <Highlight>apiKey</Highlight>
              </p>
              <Paragraph>{t('api.documentation_get_one_lead')}</Paragraph>
              <Codearea
                minHeight={'140px'}
                onChange={() => {}}
                value={
                  '{\n  "@context": "/v1/contexts/Lead",\n  "@id": "/v1/leads/2cb201c9-47fd-492c-82d5-d6460010c4c6",\n  "@type": "Lead",\n  "email": "test@email.com",\n  "registeredAsset": "/v1/content_assets/61",\n  "registeredAt": "2020-02-06T11:09:01+00:00",\n  "user": "/v1/users/1"\n}'
                }
              ></Codearea>
            </div>
            <div>
              <p>
                GET /public/leads?key=<Highlight>apiKey</Highlight>&search=
                <Highlight>kriterium</Highlight>
              </p>
              <Paragraph>{t('api.documentation_search_leads')}</Paragraph>
              <Codearea
                minHeight={'130px'}
                onChange={() => {}}
                value={
                  '[\n  {\n    "email": "kriterium@email.com",\n    "registeredAt": "2020-02-06T11:09:01+00:00",\n    "id": "2cb2afc9-37fd-452c-22d5-d6460010c4c6"\n  },\n  ...\n]'
                }
              ></Codearea>
            </div>
          </Section>
        </ContentContainerWithoutPadding>
      )}
    </NotificationContext.Consumer>
  )
}

const APIScreen = ({
  generateApiKey,
  getApiKey,
  getLoading,
  data,
  requestLoading,
  getWebhook,
  updateWebhook,
  webhookData,
  getWebhookLoading,
  getWebookError,
  getError,
  testWebhook,
  testWebhookLoading,
}) => {
  useEffect(() => {
    if (webhookData === null && !getWebhookLoading && getWebookError == null)
      getWebhook()
  })

  const { t, i18n } = useTranslation('common')

  return (
    <LoggedInScreenContainer>
      <HeaderContainer>
        <H1>{t('api.title')}</H1>
        <HeaderButtonContainer>
          <NavLink to="/settings">
            <Button kind="secondary">{t('default.back_to_overview')}</Button>
          </NavLink>
        </HeaderButtonContainer>
      </HeaderContainer>
      <SubHeaderContainer>
        <Paragraph>{t('api.sub_header_description')}</Paragraph>
      </SubHeaderContainer>
      <KeySection
        getError={getError}
        generateApiKey={generateApiKey}
        getApiKey={getApiKey}
        getLoading={getLoading}
        requestLoading={requestLoading}
        data={data}
      />
      <WebhookSection
        webhookData={webhookData}
        updateWebhook={updateWebhook}
        testWebhook={testWebhook}
        testWebhookLoading={testWebhookLoading}
      />
    </LoggedInScreenContainer>
  )
}

const ApiScreenWrapper = props => (
  <Subscribe to={[ApiContainer]}>
    {({
      state: {
        getLoading,
        data,
        requestLoading,
        getWebhookLoading,
        webhookData,
        getError,
        getWebookError,
        testWebhookLoading,
      },
      getApiKey,
      generateApiKey,
      updateWebhook,
      getWebhook,
      testWebhook,
    }) => (
      <APIScreen
        getLoading={getLoading}
        data={data}
        getApiKey={getApiKey}
        getError={getError}
        generateApiKey={generateApiKey}
        requestLoading={requestLoading}
        updateWebhook={updateWebhook}
        getWebhook={getWebhook}
        getWebhookLoading={getWebhookLoading}
        webhookData={webhookData}
        getWebookError={getWebookError}
        testWebhook={testWebhook}
        testWebhookLoading={testWebhookLoading}
        {...props}
      />
    )}
  </Subscribe>
)

export default ApiScreenWrapper
