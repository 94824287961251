import React from 'react'
import { Subscribe } from 'unstated'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'

import Table from 'functional/Table'
import ContactDeleteModal from 'functional/ContactDeleteModal'
import { NotificationContext } from 'functional/NotificationProvider'
import ContactAddToBlacklistModal from 'functional/ContactAddToBlacklistModal'

import LeadsContainer from 'containers/LeadsContainer'

import { cleanId } from 'util/api'
import { formatDate } from 'util/date'

const ContactsTable = ({ history: { push } }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <Subscribe to={[LeadsContainer]}>
      {({ state: { data }, getLeads, getLeadsCanLoadMore, deleteLeads }) => {
        return (
          <NotificationContext.Consumer>
            {context => (
              <Table
                getData={getLeads}
                data={data}
                idKey="@id"
                mapRow={({ email, registeredAt, projectName }) => [
                  email,
                  !registeredAt ? '' : formatDate(registeredAt),
                  projectName,
                ]}
                defaultSort={{
                  'order[registeredAt]': 'desc',
                }}
                headers={[
                  {
                    label: t('default.email'),
                    value: 'order[email]',
                    sortable: true,
                  },
                  {
                    label: t('default.created_at'),
                    value: 'order[registeredAt]',
                    sortable: true,
                  },
                  {
                    label: t('default.project_name'),
                    value: 'order[projectName]',
                    sortable: false,
                  },
                ]}
                filter="email"
                bulkOps={[
                  {
                    label: t('default.delete_selected'),
                    handler: deleteLeads,
                    confirmModal: true,
                  },
                ]}
                singleOps={[
                  {
                    label: t('default.edit'),
                    handler: id => push(`/contacts/edit/${cleanId(id)}`),
                  },
                  {
                    label: t('contacts.blacklisted_add_button'),
                    handler: (id, item) =>
                      context.methods.openModal(() => (
                        <ContactAddToBlacklistModal
                          defaultEmail={item.email}
                          onClose={context.methods.closeModal}
                          toggleTopNotification={() => {
                            context.methods.toggleTopNotification(
                              'success',
                              t('contacts.blacklisted_success_notification')
                            )
                          }}
                        />
                      )),
                  },
                  {
                    label: t('default.delete'),
                    handler: (id, item) =>
                      context.methods.openModal(() => (
                        <ContactDeleteModal
                          id={id}
                          email={item.email}
                          onClose={context.methods.closeModal}
                        />
                      )),
                  },
                ]}
                canLoadMore={getLeadsCanLoadMore()}
                contextMenuLeftOriented
              />
            )}
          </NotificationContext.Consumer>
        )
      }}
    </Subscribe>
  )
}

export default withRouter(ContactsTable)
