import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

export default class BlacklistContainer extends Container {
  state = {
    data: null,
    metaData: null,

    getLoading: false,
    getError: null,

    addOneLoading: false,
    addOneError: null,

    deleteOneLoading: false,
    deleteOneError: null,
  }

  getBlacklist = async (params, pageChanged) => {
    try {
      await this.setState({ getLoading: true, getError: null })

      let page

      if (pageChanged) {
        if (!this.state.metaData || !this.state.metaData['hydra:view']) {
          await this.setState({ getLoading: false })
          return
        }

        const { 'hydra:next': nextPage } = this.state.metaData['hydra:view']

        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ getLoading: false })
          return
        }
      }

      const res = await apiClient.get(
        `blacklists?${qs.stringify({ ...params, page })}`
      )
      const data = res.data['hydra:member']

      await this.setState({
        getLoading: false,
        data: pageChanged ? [...this.state.data, ...data] : data,
        metaData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getLoading: false,
        getError: handleFailure(e, true),
      })

      throw e
    }
  }

  getBlacklistCanLoadMore = () => {
    if (!this.state.metaData || !this.state.metaData['hydra:view']) return false

    return !!this.state.metaData['hydra:view']['hydra:next']
  }

  addOne = async ({ emailPlain }) => {
    try {
      await this.setState({ addOneLoading: true, addOneError: null })

      await apiClient.post(`blacklists`, { emailPlain })

      await this.setState({ addOneLoading: false })

      this.getBlacklist()
    } catch (e) {
      console.warn(e)

      await this.setState({
        addOneLoading: false,
        addOneError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteOne = async id => {
    try {
      await this.setState({ deleteOneLoading: true, deleteOneError: null })

      await apiClient.delete(`blacklists/${cleanId(id)}`)

      await this.setState({ deleteOneLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        deleteOneLoading: false,
        deleteOneError: handleFailure(e, true),
      })

      throw e
    }
  }
}
