import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import DangerButton from 'presentational/DangerButton'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'

import { withHandlers } from 'util/form'
import LabelsContainer from 'containers/LabelsContainer'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const ButtonLeft = styled(DangerButton)`
  margin-right: 5px;
  width: auto;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
  width: auto;
`

const ConfirmLabelDeleteModal = ({
  deleteLabel,
  closeModal,
  toggleTopNotification,
  id,
}) => {
  const { t, i18n } = useTranslation('common')
  return (
    <React.Fragment>
      <H3>{t('labels.deletion_modal_header')}</H3>
      <Paragraph>{t('labels.deletion_modal_description')}</Paragraph>
      <ButtonWrapper>
        <Subscribe to={[LabelsContainer]}>
          {({ getLabels, deleteLabel, state: { deleteLoading } }) => (
            <React.Fragment>
              <ButtonLeft
                onClick={withHandlers(
                  () => deleteLabel(id),
                  () => {
                    toggleTopNotification(
                      'success',
                      t('labels.deletion_success')
                    )
                    closeModal()
                  },
                  () => {
                    toggleTopNotification('error', t('labels.deletion_failed'))
                    closeModal()
                  }
                )}
              >
                {t('default.delete')}
              </ButtonLeft>
              {deleteLoading && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </React.Fragment>
          )}
        </Subscribe>
        <ButtonRight kind="secondary" onClick={() => closeModal()}>
          {t('default.cancel')}
        </ButtonRight>
      </ButtonWrapper>
    </React.Fragment>
  )
}

ConfirmLabelDeleteModal.propTypes = {
  closeModal: PropTypes.func,
  deleteLabel: PropTypes.func,
  toggleTopNotification: PropTypes.func,
  id: PropTypes.string,
}

ConfirmLabelDeleteModal.defaultProps = {
  closeModal: () => {},
  deleteWebsite: () => {},
  toggleTopNotification: () => {},
  id: '',
}

export default ConfirmLabelDeleteModal
