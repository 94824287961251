import React, { Component } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import faqDataDE from '../../../translation/de/faq.json'
import faqDataEN from '../../../translation/en/faq.json'

import H1 from 'presentational/H1'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'
import ExpandableList from 'functional/ExpandableList'

const ContentWithPaddingContainer = styled(ContentContainer)`
  padding: 30px 50px;
`

class HelpFAQScreen extends Component {
  render() {
    const { t, i18n } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('help.faq_site_title')}</H1>
          <HeaderButtonContainer>
            <NavLink to="/support/help">
              <Button kind="secondary">
                {this.props.t('default.back_to_overview')}
              </Button>
            </NavLink>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('help.faq_site_description')}</Paragraph>
        </SubHeaderContainer>
        <ContentWithPaddingContainer>
          <ExpandableList
            listData={i18n.language === 'german' ? faqDataDE : faqDataEN}
          />
        </ContentWithPaddingContainer>
      </LoggedInScreenContainer>
    )
  }
}

export default withTranslation('common')(HelpFAQScreen)
