import React from 'react'
import { Provider } from 'unstated'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import theme from 'theme/theme'

import LoginScreen from 'functional/LoginScreen'
import Logout from 'functional/Logout'
import ForgotPasswordScreen from 'functional/ForgotPasswordScreen'
import RegistrationScreen from 'functional/RegistrationScreen'
import RegistrationSuccessScreen from 'functional/RegistrationSuccessScreen'
import ConfirmAccountScreen from 'functional/ConfirmAccountScreen'
import LegalScreen from 'functional/LegalScreen'
import SetNewPasswordScreen from 'functional/SetNewPasswordScreen'
import ConfirmNewEmailScreen from 'functional/ConfirmNewEmailScreen'
import OnboardingWizardScreen from 'functional/OnboardingWizardScreen'
import NotificationProvider from 'functional/NotificationProvider'

import LoggedInUserSubroutes from './LoggedInUserSubroutes'

const UserRouter = () => (
  <Provider>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/legal" component={LegalScreen} />
            <Route
              exact
              path="/confirm-account/:id/:token"
              component={ConfirmAccountScreen}
            />
            <Route
              exact
              path="/registration-success"
              component={RegistrationSuccessScreen}
            />
            <Route
              exact
              path="/confirm-new-email/:id/:email/:token"
              component={ConfirmNewEmailScreen}
            />
            <Route exact path="/registration" component={RegistrationScreen} />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordScreen}
            />
            <Route
              exact
              path="/set-new-password/:id/:token"
              component={SetNewPasswordScreen}
            />
            <Route
              exact
              path="/onboarding-wizard"
              component={OnboardingWizardScreen}
            />
            <Route exact path="/login" component={LoginScreen} />
            <Route exact path="/logout" component={Logout} />
            <Route path="/" component={LoggedInUserSubroutes} />
          </Switch>
        </BrowserRouter>
      </NotificationProvider>
    </ThemeProvider>
  </Provider>
)

export default UserRouter
