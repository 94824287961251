import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Button from 'presentational/Button'
import InputError from 'presentational/InputError'

import Tag from 'presentational/Tag'

import { NotificationContext } from 'functional/NotificationProvider'

const Container = styled.div`
  display: flex;
`

const InnerContainer = styled.div`
  margin-left: 20px;
`

const Label = styled.label`
  margin: 10px 0 0 40px;
`

const LeftInputError = styled(InputError)`
  text-align: left;
`

const List = styled.ul`
  padding: 0;
  margin-top: 0;
  list-style: none;
`

const RemoveIcon = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 3.5em;
  background: gray;
  border: none;
  cursor: pointer;

  :before,
  :after {
    content: '';
    position: absolute;
    width: 2px;
    height: 50%;
    background: white;
    top: 50%;
    margin-top: -8px;
    margin-left: -1px;
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }
`

const ListItem = ({ label, color, onClick, value }) => (
  <Fragment>
    <Tag
      onClick={() => onClick(value)}
      noMargin
      deleteButton
      tag={{ color: color, name: label }}
    />
  </Fragment>
)

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const WrapButton = styled(Button)`
  width: auto;
`

const makeRemoveItem = input => clickedValue => {
  const { value, onChange } = input

  if (value && Array.isArray(value)) {
    const indexToRemove = value.findIndex(item => item.value === clickedValue)

    onChange([
      ...value.slice(0, indexToRemove),
      ...value.slice(indexToRemove + 1),
    ])
  }
}

const makeAddItem = input => selectedItems => {
  const { value, onChange } = input

  if (value && Array.isArray(value)) {
    const newItems = selectedItems.filter(
      curr =>
        !value.find(entry => JSON.stringify(entry) === JSON.stringify(curr))
    )

    onChange([...value, ...newItems])
    return
  }

  onChange(selectedItems)
}

const ListInput = ({
  label,
  input,
  meta: { touched, error, submitError },
  modal: Modal,
}) => {
  const { t } = useTranslation('common')

  return (
    <Container>
      <Label>{label}</Label>
      <InnerContainer>
        {input.value && !!input.value.length && (
          <List>
            {input.value.map((item, index) => (
              <ListItem
                key={item.value + index}
                onClick={makeRemoveItem(input)}
                {...item}
              />
            ))}
          </List>
        )}
        <NotificationContext.Consumer>
          {({ methods: { openModal, closeModal } }) => (
            <WrapButton
              kind="dark"
              onClick={() => {
                input.onFocus()
                openModal(() => (
                  <Modal
                    close={() => {
                      input.onBlur()
                      closeModal()
                    }}
                    value={input.value || []}
                    onConfirm={makeAddItem(input)}
                  />
                ))
              }}
            >
              + {t('labels.input_label_button')}
            </WrapButton>
          )}
        </NotificationContext.Consumer>
        {touched && (error || submitError) && (
          <LeftInputError>{error || submitError}</LeftInputError>
        )}
      </InnerContainer>
    </Container>
  )
}

ListInput.propTypes = {
  modal: PropTypes.any.isRequired,
  label: PropTypes.string,
  noInline: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    data: PropTypes.object,
    dirty: PropTypes.bool,
    error: PropTypes.any,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitError: PropTypes.any,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
}

export default ListInput
