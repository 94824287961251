import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'presentational/Button'

import OpenIcon from 'react-icons/lib/md/add'
import CloseIcon from 'react-icons/lib/md/remove'

const TitleContainer = styled.div`
  width: 100%;
  height: ${props => (props.small ? 'auto' : '50px')};
  border-bottom: 1px solid #554c47;
  line-height: ${props => (props.small ? '30px' : '50px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`

const ContentContainer = styled.div`
  font-size: 14px;
  color: #554c47;
  display: flex;
  justify-content: space-between;
  padding: ${props => (props.shown ? '15px 10px' : '0px 10px')};
  max-height: ${props => (props.shown ? '500px' : '0px')};
  overflow: ${props => (props.shown ? 'visible' : 'hidden')};
  transition: max-height 300ms ease-in-out 0s, padding 300ms ease-in-out 0s;
`

const TitleText = styled.p`
  margin: 0 10px;
  flex-grow: 1;
`

const Dot = styled.div`
  height: 5px;
  width: 5px;
  background: black;
  border-radius: 50%;
  margin-left: 5px;
`

const StyledCloseIcon = styled(CloseIcon)`
  margin-right: 10px;
  color: #554c47;
  font-size: 25px;
`

const StyledOpenIcon = styled(OpenIcon)`
  margin-right: 10px;
  color: #554c47;
  font-size: 25px;
`

const SmallButton = styled(Button)`
  padding: 5px;
  height: 30px;
  width: 130px;
`

const ExpandableList = ({
  listData,
  withoutDot = false,
  small = false,
  button = null,
  onButtonClick,
}) => {
  const [openItems, setOpenItems] = useState([])

  const handleTitleClick = index => {
    const newItems = [...openItems]
    if (openItems.includes(index)) {
      newItems.splice(newItems.indexOf(index), 1)
    } else {
      newItems.push(index)
    }

    setOpenItems(newItems)
  }

  const buildItem = (item, key) => {
    const isOpen = openItems.includes(key)
    return (
      <div key={key}>
        <TitleContainer onClick={() => handleTitleClick(key)} small={small}>
          {withoutDot ? '' : <Dot />}
          <TitleText>{item.title}</TitleText>
          {isOpen ? <StyledCloseIcon /> : <StyledOpenIcon />}
        </TitleContainer>
        <ContentContainer shown={isOpen}>
          <div>{item.content}</div>
          {button !== null && (
            <div>
              <SmallButton onClick={() => onButtonClick(key)}>
                {button}
              </SmallButton>
            </div>
          )}
        </ContentContainer>
      </div>
    )
  }

  const buildList = () => (
    <div>{listData.map((item, key) => buildItem(item, key))}</div>
  )

  return <div>{buildList()}</div>
}

export default ExpandableList
