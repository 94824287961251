import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Subscribe } from 'unstated'

import { NotificationContext } from 'functional/NotificationProvider'

import H3 from 'presentational/H3'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import DangerButton from 'presentational/DangerButton'
import Paragraph from 'presentational/Paragraph'

import WebsiteContainer from 'containers/WebsiteContainer'

import { withHandlers } from 'util/form'
import { cleanId } from 'util/api'

const LoaderContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 20px;
`

const ButtonLeft = styled(DangerButton)`
  margin-right: 5px;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
`

const ResetTrackingDataModal = ({ onClose, website }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <NotificationContext.Consumer>
      {context => (
        <Subscribe to={[WebsiteContainer]}>
          {({
            state: { resetAllLoading, resetAllError, resetLoading, resetError },
            resetAllTrackingData,
            resetTrackingData,
          }) => {
            return (
              <React.Fragment>
                <H3>{t('profile.reset_modal_header')}</H3>
                <Paragraph>
                  {website
                    ? t('profile.reset_modal_single_description', {
                        website: website.title,
                      })
                    : t('profile.reset_modal_description')}
                </Paragraph>
                <ButtonWrapper>
                  <ButtonLeft
                    onClick={withHandlers(
                      website
                        ? () => resetTrackingData(cleanId(website['@id']))
                        : resetAllTrackingData,
                      () => {
                        context.methods.toggleTopNotification(
                          'success',
                          website
                            ? t('profile.reset_modal_single_success', {
                                website: website.title,
                              })
                            : t('profile.reset_modal_success')
                        )
                        context.methods.closeModal()
                      },
                      () => {
                        context.methods.toggleTopNotification(
                          'error',
                          website
                            ? t('profile.reset_modal_single_failed', {
                                website: website.title,
                              })
                            : t('profile.reset_modal_failed')
                        )
                        context.methods.closeModal()
                      }
                    )}
                  >
                    {t('profile.reset_modal_confirm')}
                  </ButtonLeft>
                  <LoaderContainer>
                    {(resetLoading && !resetError) ||
                    (resetAllLoading && !resetAllError) ? (
                      <Loader />
                    ) : (
                      ''
                    )}
                  </LoaderContainer>

                  <ButtonRight onClick={onClose} kind="secondary">
                    {t('default.cancel')}
                  </ButtonRight>
                </ButtonWrapper>
              </React.Fragment>
            )
          }}
        </Subscribe>
      )}
    </NotificationContext.Consumer>
  )
}

ResetTrackingDataModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ResetTrackingDataModal
