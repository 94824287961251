import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import LicensePlan from 'presentational/LicensePlan'
import DangerButton from 'presentational/DangerButton'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  HeaderButtonContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import { NotificationContext } from 'functional/NotificationProvider'
import UpgradeLicenseModal from 'functional/UpgradeLicenseModal'
import CancelLicenseModal from 'functional/CancelLicenseModal'

import { withHandlers } from 'util/form'

const ContentContainerWithoutPadding = styled(ContentContainer)`
  padding: 0;
`

const StatusMessage = styled(H3)`
  text-align: center;
`

const Plan = styled.span`
  color: #a0b6cb;
`

const StatusSubMessage = styled(Paragraph)`
  text-align: center;
`

const HelpLink = styled(NavLink)`
  color: #a0b6cb;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const UpgradeButton = styled(Button)`
  width: auto;
`

const UpgradeButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const PlansContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const CancelButton = styled(DangerButton)`
  width: auto;
`

const StatusSection = () => {
  const { t, i18n } = useTranslation('common')
  return (
    <NotificationContext.Consumer>
      {context => (
        <ContentContainerWithoutPadding>
          <Section>
            <StatusMessage>
              <Trans t={t} i18nKey="license.status_message">
                <Plan>{{ plan: 'Family & Friends' }}</Plan>
              </Trans>
            </StatusMessage>
            <StatusSubMessage>
              <Trans t={t} i18nKey="license.status_sub_message">
                <HelpLink to="/support/help"></HelpLink>.
              </Trans>
            </StatusSubMessage>
            {/*
            <UpgradeButtonContainer>
              <UpgradeButton
                onClick={() =>
                  context.methods.openModal(() => (
                    <UpgradeLicenseModal
                      closeModal={context.methods.closeModal}
                    />
                  ))
                }
              >
                {t('license.status_upgrade_button')}
              </UpgradeButton>
            </UpgradeButtonContainer>
              */}
          </Section>
        </ContentContainerWithoutPadding>
      )}
    </NotificationContext.Consumer>
  )
}

const PlansSection = () => {
  const { t, i18n } = useTranslation('common')
  return (
    <NotificationContext.Consumer>
      {context => (
        <ContentContainerWithoutPadding>
          <Section>
            <H2>{t('license.plans_title')}</H2>
            <Paragraph>{t('license.plans_description')}</Paragraph>
            <PlansContainer>
              <LicensePlan
                title="Family & Friends"
                price="0"
                features={[
                  'Feature #1',
                  '500 Leads/month',
                  '10 Content Assets',
                  'Feature #4',
                  '2 API Keys',
                  '5 Users',
                ]}
                active
                onClickUpgrade={() => {
                  context.methods.openModal(() => (
                    <UpgradeLicenseModal
                      closeModal={context.methods.closeModal}
                    />
                  ))
                }}
              />
              <LicensePlan
                title="Silver"
                price="25"
                features={[
                  'All Features from Family & Friends',
                  '1500 Leads/month',
                ]}
                onClickUpgrade={() => {
                  context.methods.openModal(() => (
                    <UpgradeLicenseModal
                      closeModal={context.methods.closeModal}
                    />
                  ))
                }}
              />
              <LicensePlan
                title="Gold"
                price="40"
                features={['All Features from Silver']}
                onClickUpgrade={() => {
                  context.methods.openModal(() => (
                    <UpgradeLicenseModal
                      closeModal={context.methods.closeModal}
                    />
                  ))
                }}
              />
            </PlansContainer>
          </Section>
        </ContentContainerWithoutPadding>
      )}
    </NotificationContext.Consumer>
  )
}

const CancelLicense = () => {
  const { t, i18n } = useTranslation('common')
  return (
    <NotificationContext.Consumer>
      {context => (
        <ContentContainerWithoutPadding>
          <Section>
            <H2>{t('license.cancel_plan_title')}</H2>
            <Paragraph>{t('license.cancel_plan_description')}</Paragraph>
            <CancelButton
              onClick={() =>
                context.methods.openModal(() => (
                  <CancelLicenseModal
                    closeModal={context.methods.closeModal}
                    onCancelLicense={withHandlers(
                      () => new Promise(resolve => setTimeout(resolve, 10)),
                      () => {
                        context.methods.closeModal()
                        context.methods.toggleTopNotification(
                          'success',
                          t('license.cancel_plan_success')
                        )
                      },
                      () => {
                        context.methods.closeModal()
                        context.methods.toggleTopNotification(
                          'error',
                          t('license.cancel_plan_error')
                        )
                      }
                    )}
                  />
                ))
              }
            >
              {t('license.cancel_plan_button')}
            </CancelButton>
          </Section>
        </ContentContainerWithoutPadding>
      )}
    </NotificationContext.Consumer>
  )
}

const LicenseScreen = () => {
  const { t, i18n } = useTranslation('common')

  return (
    <LoggedInScreenContainer>
      <HeaderContainer>
        <H1>{t('license.title')}</H1>
        <HeaderButtonContainer>
          <NavLink to="/settings">
            <Button kind="secondary">{t('default.back_to_overview')}</Button>
          </NavLink>
        </HeaderButtonContainer>
      </HeaderContainer>
      <SubHeaderContainer>
        <Paragraph>{t('api.sub_header_description')}</Paragraph>
      </SubHeaderContainer>
      <StatusSection />
      {/*
      <PlansSection />
      <CancelLicense />
      */}
    </LoggedInScreenContainer>
  )
}

export default LicenseScreen
