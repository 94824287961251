import React from 'react'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import H3 from 'presentational/H3'
import Logo from 'presentational/Logo'
import Paragraph from 'presentational/Paragraph'
import AuthLayout from 'presentational/AuthLayout'
import AuthLogoContainer from 'presentational/AuthLogoContainer'

import BackButton from 'functional/BackButton'
import RegistrationForm from 'functional/RegistrationForm'

import AccountContainer from 'containers/AccountContainer'

import { withHandlers } from 'util/form'

const RegistrationScreen = props => {
  const {
    history: { push },
  } = props

  const {
    t,
    i18n: { language },
  } = useTranslation('common')

  return (
    <AuthLayout>
      <BackButton />
      <AuthLogoContainer>
        <Logo width={'220px'} />
      </AuthLogoContainer>
      <H3 dark>{t('login_register.registration_header')}</H3>
      <Paragraph dark>{t('login_register.registration_description')}</Paragraph>
      <H1 dark>{t('login_register.registration_call')}</H1>
      <Subscribe to={[AccountContainer]}>
        {({ register }) => (
          <RegistrationForm
            onSubmit={withHandlers(
              values => register({ ...values, language }),
              () => push('/registration-success')
            )}
          />
        )}
      </Subscribe>
    </AuthLayout>
  )
}

/** @component */
export default RegistrationScreen
