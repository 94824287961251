import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'
import DangerButton from 'presentational/DangerButton'

import { withHandlers } from 'util/form'
import queries from 'util/mediaQueries'
import LeadsContainer from 'containers/LeadsContainer'

import { NotificationContext } from 'functional/NotificationProvider'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  button {
    max-width: 35%;
  }
`
const ContactDeleteModal = props => {
  const { t, i18n } = useTranslation('common')

  return (
    <Subscribe to={[LeadsContainer]}>
      {({ state: { deleteLeadLoading, deleteLeadError }, deleteLead }) => {
        return (
          <React.Fragment>
            <H3>
              {t('contacts.delete_contact_modal_header', {
                email: props.email,
              })}
            </H3>
            <Paragraph>
              {t('contacts.delete_contact_modal_description')}
            </Paragraph>
            <NotificationContext.Consumer>
              {context => (
                <ButtonWrapper>
                  <DangerButton
                    onClick={withHandlers(
                      () => deleteLead(props.id),
                      () => {
                        context.methods.toggleTopNotification(
                          'success',
                          t('contacts.delete_contact_success')
                        )
                        context.methods.closeModal()
                      },
                      () =>
                        context.methods.toggleTopNotification(
                          'error',
                          t('contacts.delete_contact_failed')
                        )
                    )}
                  >
                    {t('default.delete')}
                  </DangerButton>
                  <Button
                    kind="secondary"
                    onClick={e => context.methods.closeModal()}
                  >
                    {t('default.cancel')}
                  </Button>
                </ButtonWrapper>
              )}
            </NotificationContext.Consumer>
          </React.Fragment>
        )
      }}
    </Subscribe>
  )
}

ContactDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string,
}

ContactDeleteModal.defaultProps = {
  email: '',
}

export default ContactDeleteModal
