import { Container } from 'unstated'
import saveAs from 'file-saver'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'
import { currentTimeFormated } from 'util/date'

class ContactsContainer extends Container {
  state = {
    data: null,
    metaData: null,
    details: null,

    error: null,
    loading: false,

    createLoading: false,
    createError: null,

    getOneLoading: false,
    getOneError: null,

    updateLoading: false,
    updateError: null,

    exportLoading: false,
    exportError: null,

    deleteContactLoading: false,
    deleteContactError: null,

    deleteContactsLoading: false,
    deleteContactsError: null,
  }

  getContact = async id => {
    try {
      await this.setState({
        details: null,
        getOneLoading: true,
        getOneError: null,
      })

      const res = await apiClient.get(`contacts/${cleanId(id)}`)

      await this.setState({ getOneLoading: false, details: res.data })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getOneLoading: false,
        getOneError: handleFailure(e, true),
      })

      throw e
    }
  }

  getContacts = async (params, pageChanged) => {
    try {
      await this.setState({ loading: true, error: null })

      let page

      if (pageChanged) {
        if (!this.state.metaData || !this.state.metaData['hydra:view']) {
          await this.setState({ loading: false })
          return
        }

        const { 'hydra:next': nextPage } = this.state.metaData['hydra:view']

        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ loading: false })
          return
        }
      }

      const res = await apiClient.get(
        `contacts?${qs.stringify({ ...params, page }, { encode: false })}`
      )
      const data = res.data['hydra:member']

      await this.setState({
        loading: false,
        data: pageChanged ? [...this.state.data, ...data] : data,
        metaData: res.data,
      })

      return data
    } catch (e) {
      console.warn('in getContacts', e)

      this.setState({ loading: false, error: handleFailure(e, true) })

      throw e
    }
  }

  getContactsCanLoadMore = () => {
    if (!this.state.metaData || !this.state.metaData['hydra:view']) return false

    return !!this.state.metaData['hydra:view']['hydra:next']
  }

  createContact = async data => {
    try {
      await this.setState({ createLoading: true, createError: null })

      await apiClient.post('contacts', { ...data })

      await this.setState({ createLoading: false })
    } catch (e) {
      console.warn('in createContact', e)

      this.setState({
        createLoading: false,
        createError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateContact = async ({ '@id': id, ...data }) => {
    try {
      await this.setState({ updateLoading: false, updateError: null })

      await apiClient.put(`contacts/${cleanId(id)}`, { ...data })

      await this.setState({ updateLoading: false })
    } catch (e) {
      console.warn(e)

      this.setState({
        updateLoading: false,
        updateError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteContact = async id => {
    try {
      await this.setState({
        deleteContactLoading: true,
        deleteContactError: null,
      })

      await apiClient.delete(`contacts/${cleanId(id)}`)
      await this.getContacts()

      await this.setState({ deleteContactLoading: false })
    } catch (e) {
      console.warn('in deleteContactContact', e)

      this.setState({
        deleteContactLoading: false,
        deleteContactError: handleFailure(e, true),
      })

      throw e
    }
  }

  exportContacts = async () => {
    try {
      await this.setState({ exportLoading: true, exportError: null })

      const res = await apiClient.get('contacts/export_csv')

      const csv = await atob(res.data.file)

      await saveAs(
        new Blob([csv], { type: 'text/csv;charset=utf-8' }),
        `VERTUSLeads${currentTimeFormated()}.csv`
      )

      await this.setState({ exportLoading: false })
    } catch (e) {
      console.warn('in exportContacts', e)

      await this.setState({
        exportLoading: false,
        exportError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteContacts = async ids => {
    try {
      await this.setState({
        deleteContactsLoading: true,
        deleteContactsError: null,
      })

      const query = qs.stringify(
        { ids: ids.map(id => cleanId(id)) },
        { encode: false }
      )

      await apiClient.delete(`contacts/bulk_delete?${query}`)

      await this.setState({ deleteContactsLoading: false })
    } catch (e) {
      console.warn('in deleteContactsContact', e)

      this.setState({
        deleteContactsLoading: false,
        deleteContactsError: handleFailure(e, true),
      })

      throw e
    }
  }
}

export default ContactsContainer
