import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 20px;
`

const ButtonLeft = styled(Button)`
  margin-right: 5px;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
`

const Codearea = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
`

const WebsiteShowScriptTagModal = ({ closeModal, scriptCode }) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const textAreaRef = useRef(null)
  const { t, i18n } = useTranslation('common')

  const copyToClipboard = event => {
    textAreaRef.current.select()
    document.execCommand('copy')
    event.target.focus()
    setCopySuccess(true)
  }

  return (
    <React.Fragment>
      <H3>{t('websites.show_script_modal_header')}</H3>
      <Paragraph>{t('websites.show_script_modal_description')}</Paragraph>
      <Codearea innerRef={textAreaRef} onChange={() => {}} value={scriptCode} />
      <ButtonWrapper>
        <ButtonLeft
          onClick={copyToClipboard}
          kind={copySuccess ? 'secondary' : 'primary'}
        >
          {copySuccess ? t('default.copied') : t('default.copy')}
        </ButtonLeft>
        <ButtonRight kind="secondary" onClick={closeModal}>
          {t('default.close')}
        </ButtonRight>
      </ButtonWrapper>
    </React.Fragment>
  )
}

WebsiteShowScriptTagModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  scriptCode: PropTypes.string.isRequired,
}

export default WebsiteShowScriptTagModal
