import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Checkmark from 'react-icons/lib/md/check'
import ErrorOutline from 'react-icons/lib/md/error-outline'

import H3 from 'presentational/H3'
import Logo from 'presentational/Logo'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import AuthLayout from 'presentational/AuthLayout'

import AccountContainer from 'containers/AccountContainer'
import { handleFailure, getErrorMessage } from 'util/form'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
`

const IconContainer = styled.div`
  flex: 1;
  padding-right: 25px;
`

const ErrorIcon = styled(ErrorOutline)`
  color: #e07144;
`

const ColoredH3 = styled(H3)`
  color: #fff;
`

/**
 * A screen that comes after successful registration.
 * @author Stjepan Golemac
 */
class ConfirmAccountScreen extends React.Component {
  state = {
    loading: true,
    error: null,
  }

  componentDidMount() {
    this.confirmAccount(true)
  }

  confirmAccount = (initial = false) => {
    const {
      match: { params },
      confirmAccount,
    } = this.props

    !initial && this.setState({ loading: true })

    confirmAccount(params)
      .then(() => this.setState({ loading: false }))
      .catch(e =>
        this.setState({ loading: false, error: handleFailure(e, true) })
      )
  }

  renderMessage() {
    const { loading, error } = this.state

    if (loading) {
      return <Loader size="large" />
    }

    return (
      <React.Fragment>
        <IconContainer>
          {error ? (
            <ErrorIcon size={64} />
          ) : (
            <Checkmark size={64} color={'#d36841'} />
          )}
        </IconContainer>
        <ColoredH3>
          {getErrorMessage(error) ||
            this.props.t('login_register.confirm_account_success')}
        </ColoredH3>
      </React.Fragment>
    )
  }

  renderButton() {
    const { confirmAccount } = this
    const { loading, error } = this.state

    if (loading) {
      return null
    }

    if (error) {
      return (
        <Button size="large" onClick={() => confirmAccount()}>
          {this.props.t('login_register.try_again')}
        </Button>
      )
    }

    return (
      <Link to="/login">
        <Button size="large">
          {this.props.t('login_register.go_to_login')}
        </Button>
      </Link>
    )
  }

  render() {
    return (
      <AuthLayout>
        <Logo width={'220px'} />
        <FlexContainer>{this.renderMessage()}</FlexContainer>
        {this.renderButton()}
      </AuthLayout>
    )
  }
}

ConfirmAccountScreen.propTypes = {
  confirmAccount: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
  }),
}

const ConfirmAccountWrapper = props => (
  <Subscribe to={[AccountContainer]}>
    {({ confirmAccount }) => (
      <ConfirmAccountScreen {...props} confirmAccount={confirmAccount} />
    )}
  </Subscribe>
)

/** @component */
export default withTranslation('common')(ConfirmAccountWrapper)
