import React from 'react'
import PropTypes from 'prop-types'

import SearchInput from 'presentational/SearchInput'

class Search extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchValue: props.defaultValue,
    }

    this.filterList = this.filterList.bind(this)
  }

  filterList(searchValue) {
    const { onChange } = this.props
    onChange(searchValue)
  }

  render() {
    return (
      <React.Fragment>
        <SearchInput
          innerRef={input => (this.search = input)}
          filterList={this.filterList}
          defaultValue={this.state.searchValue}
        />
      </React.Fragment>
    )
  }
}

Search.propTypes = {
  columns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Search
