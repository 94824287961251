import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import DangerButton from 'presentational/DangerButton'
import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  button {
    max-width: 35%;
  }
`

/**
 * @description Modal to cancel a license.
 * @author Jan Mägdefrau
 * @version 1.0.0
 * @param closeModal Called when user closes modal without submit.
 * @param onCancelLicense Called when user submits license cancel.
 */
const CancelLicenseModal = ({ closeModal, onCancelLicense }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <React.Fragment>
      <H3>{t('license.cancel_modal_title')}</H3>
      <Paragraph>{t('license.cancel_modal_description')}</Paragraph>
      <ButtonWrapper>
        <DangerButton onClick={onCancelLicense}>
          {t('license.cancel_modal_cancel_button')}
        </DangerButton>
        <Button kind="secondary" onClick={() => closeModal()}>
          {t('license.cancel_modal_close_button')}
        </Button>
      </ButtonWrapper>
    </React.Fragment>
  )
}

CancelLicenseModal.propTypes = {
  closeModal: PropTypes.func,
  onCancelLicense: PropTypes.func,
}

CancelLicenseModal.defaultProps = {
  closeModal: () => {},
  onCancelLicense: () => {},
}

/** @component */
export default CancelLicenseModal
