import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Checkmark from 'react-icons/lib/md/check'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import OnboardingWizardSummaryEntry from 'presentational/OnboardingWizardSummaryEntry'
import OnboardingWizardSummaryColorEntry from 'presentational/OnboardingWizardSummaryColorEntry'

const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Section = styled.div`
  margin: 30px 20px;
`
const Icon = styled(Checkmark)``

const ColoredH3 = styled(H3)`
  color: #fff;
`

const NoWrapH3 = styled(H3)`
  white-space: nowrap;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  width: 320px;
`

const ButtonWithSpace = styled(Button)`
  margin: 0 5px;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
`

const IconContainer = styled.div`
  padding-right: 25px;
`

/**
 * @description Component shows a summary of the onboarding wizard.
 * @author Jan Mägdefrau
 */
const OnboardingWizardSummary = props => {
  const { t } = useTranslation('common')

  return (
    <div>
      <FlexContainer>
        <IconContainer>
          <Icon size={64} color={'#d36841'} />
        </IconContainer>
        <ColoredH3>{t('onboarding_wizard.data_saved')}</ColoredH3>
      </FlexContainer>
      <SectionWrapper>
        <Section>
          <H3 dark>{t('onboarding_wizard.profile_information')}</H3>
          <OnboardingWizardSummaryEntry
            name={t('default.title')}
            value={
              props.userInformationData.sex === 'male'
                ? t('default.mr')
                : t('default.ms')
            }
          />
          <OnboardingWizardSummaryEntry
            name={t('default.firstname')}
            value={props.userInformationData.firstname}
          />
          <OnboardingWizardSummaryEntry
            name={t('default.lastname')}
            value={props.userInformationData.lastname}
          />
          <OnboardingWizardSummaryEntry
            name={t('default.company')}
            value={props.userInformationData.company}
          />
          <OnboardingWizardSummaryEntry
            name={t('default.phone')}
            value={props.userInformationData.phoneNumber}
          />
        </Section>
        <Section>
          <NoWrapH3 dark>{t('onboarding_wizard.website_information')}</NoWrapH3>
          <OnboardingWizardSummaryEntry
            name={t('default.url')}
            value={props.websiteInformationData.title}
          />
          <OnboardingWizardSummaryColorEntry
            name={t('default.primary_color')}
            color={props.websiteInformationData.primaryColor}
          />
          <OnboardingWizardSummaryColorEntry
            name={t('default.background_color')}
            color={props.websiteInformationData.backgroundColor}
          />
          <OnboardingWizardSummaryColorEntry
            name={t('default.text_color')}
            color={props.websiteInformationData.textColor}
          />
        </Section>
      </SectionWrapper>
      <SectionWrapper>
        <ButtonWrapper>
          <ButtonWithSpace
            kind="secondary"
            onClick={props.onBack}
            size="normal"
          >
            {t('default.back')}
          </ButtonWithSpace>
          <ButtonWithSpace
            kind="bold"
            onClick={() => props.onFinish()}
            size="normal"
          >
            {t('onboarding_wizard.next')}
          </ButtonWithSpace>
        </ButtonWrapper>
      </SectionWrapper>
    </div>
  )
}

OnboardingWizardSummary.propTypes = {
  onFinish: PropTypes.func,
  userInformationData: PropTypes.shape({
    sex: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    company: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  websiteInformationData: PropTypes.shape({
    website: PropTypes.string,
    logo: PropTypes.string,
    colors: PropTypes.array,
  }),
}

OnboardingWizardSummary.defaultProps = {
  onFinish: () => {},
  userInformationData: {
    sex: '',
    firstname: '',
    lastname: '',
    company: '',
    phoneNumber: '',
  },
  websiteInformationData: {
    website: '',
    logo: '',
    colors: [],
  },
}

/** @component */
export default OnboardingWizardSummary
