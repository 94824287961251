import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Search from 'react-icons/lib/md/search'

import Button from 'presentational/Button'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  border-bottom: ${props => (props.border ? '1px solid #9A9A9A' : 'none')};
  padding-bottom: ${props => (props.border ? '5px' : '0')};
`

const Input = styled.input`
  color: ${props => props.theme.colors.text.normal};
  font-size: 14px;
  line-height: 40px;

  width: ${props => (props.size === 'big' ? '300px' : 'auto')};

  background-color: transparent;
  border: none;
  outline: none;

  padding-left: 16px;
  padding-right: ${props => (props.size === 'big' ? '16px' : '0')};

  /* Chrome, Firefox, Safari */
  ::placeholder {
    color: ${props => props.theme.colors.text.label};
    opacity: 1; /* Firefox */
  }

  /* IE */
  :-ms-input-placeholder {
    color: ${props => props.theme.colors.text.label};
  }

  /* Edge */
  ::-ms-input-placeholder {
    color: ${props => props.theme.colors.text.label};
  }
`
class SearchInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchValue: props.defaultValue,
    }
  }

  handleChange(e) {
    const searchValue = e.target.value
    this.setState({ searchValue }, () =>
      this.props.filterList(searchValue.toLowerCase())
    )
  }

  render() {
    return (
      <Container border={this.props.border}>
        <Search size={20} />
        <Input
          value={this.state.searchValue}
          onChange={e => this.handleChange(e)}
          placeholder={this.props.t('default.search_placeholder')}
          size={this.props.size}
        />
        {this.props.searchButton && (
          <Button size={'small'} onClick={this.props.onSubmitSearch}>
            {this.props.t('default.search')}
          </Button>
        )}
      </Container>
    )
  }
}

SearchInput.propTypes = {
  filterList: PropTypes.func,
  defaultValue: PropTypes.string,
  border: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'normal']),
  searchButton: PropTypes.bool,
  onSubmitSearch: PropTypes.func,
}

SearchInput.defaultValue = {
  defaultValue: '',
  border: false,
  size: 'normal',
  searchButton: false,
  onSubmitSearch: () => {},
}

export default withTranslation('common')(SearchInput)
