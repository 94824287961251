import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { useTranslation, Trans } from 'react-i18next'

import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import TextInput from 'presentational/TextInput'
import CheckboxInput from 'presentational/CheckboxInput'
import AuthFormLoader from 'presentational/AuthFormLoader'
import PasswordStrengthOutput from 'presentational/PasswordStrengthOutput'

import i18n from '../../../translation/i18n'

import {
  isEmail,
  makeMinLen,
  isChecked,
  combineValidators,
  makeIsEqual,
  makePasswordStrengthField,
  makeIsGreater,
} from 'util'

const BottomMargin = styled.div`
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }
`

const TopMargin = styled.div`
  margin-bottom: 20px;
`

const SmallBottomMargin = styled.div`
  margin-bottom: 5px;
`

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  color: ${props => props.theme.colors.text.dark};
`

const min8Len = makeMinLen(8)
const arePasswordsEqual = makeIsEqual(
  'plainPassword',
  'plainPasswordRepeated',
  i18n.getFixedT(null, 'common')('form.passwords_equal')
)
const repeatedPasswordValidators = combineValidators(min8Len, arePasswordsEqual)
const passwordStrengthField = makePasswordStrengthField(
  'plainPassword',
  'passwordStrength'
)
const strengthAcceptable = makeIsGreater(1)

const RegistrationForm = props => {
  const { t } = useTranslation('common')

  return (
    <Form
      onSubmit={props.onSubmit}
      decorators={[passwordStrengthField]}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <BottomMargin>
            <Field
              dark
              name="email"
              component={TextInput}
              format={(value = '') => value.trim()}
              formatOnBlur
              type="text"
              label={t('default.email')}
              validate={isEmail}
              noInline
            />
          </BottomMargin>
          <BottomMargin>
            <SmallBottomMargin>
              <Field
                dark
                name="plainPassword"
                component={TextInput}
                type="password"
                label={t('default.password')}
                placeholder={t('login_register.unique_password')}
                validate={min8Len}
                noInline
              />
            </SmallBottomMargin>
            <Field
              dark
              name="plainPasswordRepeated"
              component={TextInput}
              type="password"
              placeholder={t('login_register.confirm_password')}
              validate={repeatedPasswordValidators}
              noInline
            />
          </BottomMargin>
          <BottomMargin>
            <Field
              name="passwordStrength"
              component={PasswordStrengthOutput}
              validate={strengthAcceptable}
            />
          </BottomMargin>
          <BottomMargin>
            <Field
              dark
              name="acceptGDPR"
              component={CheckboxInput}
              type="checkbox"
              validate={isChecked}
              noInline
              label={
                <Paragraph dark inline>
                  <Trans t={t} i18nKey="login_register.accept_gdpr_description">
                    <Link
                      href={t('login_register.terms_of_use_link')}
                      target="_blank"
                    ></Link>
                    <Link
                      href={t('login_register.privacy_link')}
                      target="_blank"
                    ></Link>
                  </Trans>
                </Paragraph>
              }
            />
          </BottomMargin>
          {submitting && <AuthFormLoader />}
          <BottomMargin>
            <Button submit disabled={submitting} size="large">
              {t('login_register.create_account')}
            </Button>
          </BottomMargin>
        </form>
      )}
    />
  )
}

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default RegistrationForm
