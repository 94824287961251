import React from 'react'
import { withTranslation } from 'react-i18next'

const cE = React.createElement

const containerStyle = {
  padding: '25px 30px',
  position: 'relative',
  boxSizing: 'content-box',
}

const textStyle = {
  fontSize: '13px',
  color: 'red',
}

const buttonStyle = {
  border: 'none',
  backgroundColor: 'white',
  width: '100%',
  cursor: 'pointer',
  maxWidth: '300px',
  height: '35px',
  fontSize: '14px',
  color: 'red',
}

const initialWrapperStyle = { display: 'flex', alignItems: 'center' }

const inputWrapperStyle = {
  margin: '15px 0',
}

const inputLabelStyle = {
  margin: '0',
  fontSize: '15px',
}

const inputStyle = {
  height: '30px',
  lineHeight: '30px',
  boxSizing: 'border-box',
  width: '100%',
  marginTop: '5px',
  border: 'none',
  maxWidth: '300px',
  paddingLeft: '10px',
}

const inputErrorStyle = {
  margin: '5px 0 0 0',
  fontSize: '13px',
}

const closeStyle = {
  display: 'block',
  margin: '10px 0',
  cursor: 'pointer',
  textAlign: 'center',
  color: 'white',
  right: '15px',
  top: '0',
  position: 'absolute',
  fontSize: '20px',
}

const logoWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const privacyLinkBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '15px',
}

class AssetImage extends React.Component {
  render() {
    const imageStyle = {
      marginRight: '20px',
      maxWidth: this.props.width,
    }

    if (!this.props.assetImageSrc) {
      imageStyle.width = '150px'
      imageStyle.height = '150px'
      imageStyle.backgroundColor = '#d9dddc'
      imageStyle.display = 'flex'
      imageStyle.justifyContent = 'center'
      imageStyle.alignItems = 'center'
    }

    if (this.props.position === 'right') {
      imageStyle.marginLeft = '15px'
      imageStyle.marginRight = '5px'
    }

    if (this.props.position === 'top') {
      imageStyle.marginRight = '0px'
      imageStyle.marginBottom = '15px'
    }

    if (this.props.position === 'bottom') {
      imageStyle.marginRight = '0px'
      imageStyle.marginTop = '15px'
    }

    if (this.props.position === 'between') {
      imageStyle.marginBottom = '15px'
      imageStyle.marginTop = '15px'
      imageStyle.marginRight = '0px'
    }

    return this.props.assetImageSrc ? (
      <img
        src={this.props.assetImageSrc}
        // height={this.props.height}
        style={imageStyle}
      />
    ) : (
      <div style={imageStyle}>Test image</div>
    )
  }
}

class Title extends React.Component {
  render() {
    return (
      <p style={{ marginTop: '0px', ...this.props.style }}>
        {this.props.children}
      </p>
    )
  }
}

class Text extends React.Component {
  render() {
    return (
      <p style={{ ...textStyle, ...this.props.style }}>{this.props.children}</p>
    )
  }
}

class Button extends React.Component {
  render() {
    return (
      <button
        onClick={this.props.onClick}
        disabled={this.props.blocked}
        style={{
          ...buttonStyle,
          fontFamily: this.props.fontFamily + ', sans-serif',
          color: this.props.buttonTextColor,
          backgroundColor: this.props.buttonColor,
          borderRadius: this.props.buttonRadius,
          cursor: this.props.blocked ? 'not-allowed' : 'pointer',
        }}
      >
        {this.props.children}
      </button>
    )
  }
}

class Input extends React.Component {
  render() {
    return (
      <div style={inputWrapperStyle}>
        <p
          style={{
            ...inputLabelStyle,
            color: this.props.textColor,
          }}
        >
          {this.props.label}
        </p>
        <input
          type="text"
          onChange={event => this.props.onChange(event.target.value)}
          style={
            this.props.error
              ? {
                  ...inputStyle,
                  border: '2px solid' + this.props.errorColor,
                  backgroundColor: this.props.inputBackgroundColor,
                }
              : {
                  ...inputStyle,
                  backgroundColor: this.props.inputBackgroundColor,
                }
          }
          value={this.props.value}
          required={this.props.required}
        />
        {this.props.error && (
          <p style={{ ...inputErrorStyle, color: this.props.errorColor }}>
            {this.props.label} is required
          </p>
        )}
      </div>
    )
  }
}

class Select extends React.Component {
  render() {
    return (
      <div style={inputWrapperStyle}>
        <p
          style={{
            ...inputLabelStyle,
            color: this.props.textColor,
          }}
        >
          {this.props.label}
        </p>
        <select
          type="text"
          onChange={event => this.props.onChange(event.target.value)}
          style={
            this.props.error
              ? {
                  ...inputStyle,
                  border: '2px solid red',
                  backgroundColor: this.props.inputBackgroundColor,
                }
              : {
                  ...inputStyle,
                  backgroundColor: this.props.inputBackgroundColor,
                }
          }
          value={this.props.value}
        >
          <option key={Math.random()} value="" disabled hidden>
            {this.props.label}
          </option>
          {this.props.options.map(option => (
            <option key={Math.random()} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

class ContentAsset extends React.Component {
  constructor() {
    super()

    this.contentAssetRef = React.createRef()
    this.state = {
      site: 'initial',
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      companyError: false,
      phoneError: false,
      individualError: false,
      close: false,
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      gender: '',
      phone: '',
      individual: '',
      checkBoxClicked: false,
    }
  }

  getInitialSite(wrapperStyle) {
    return (
      <div style={wrapperStyle}>
        {this.props.assetImageSrc !== '' &&
          this.props.imagePosition !== 'between' && (
            <AssetImage
              assetImageSrc={false}
              width={this.props.width}
              position={this.props.imagePosition}
            />
          )}
        <div>
          <Title
            style={{
              ...this.props.titleStyle,
              color: this.props.textColor,
              wordBreak: 'break-word',
            }}
          >
            {this.props.title}
          </Title>
          {this.props.assetImageSrc !== '' &&
            this.props.imagePosition === 'between' && (
              <AssetImage
                assetImageSrc={false}
                width={this.props.width}
                position={this.props.imagePosition}
              />
            )}
          <Button
            onClick={() => this.setState({ site: 'download' })}
            fontFamily={this.props.fontFamily}
            buttonColor={this.props.buttonColor}
            buttonTextColor={this.props.buttonTextColor}
            buttonRadius={this.props.buttonRadius}
          >
            {this.props.downloadButtonText
              ? this.props.downloadButtonText
              : 'Download'}
          </Button>
        </div>
        {/**this.props.displayLogo && (
          <div style={logoWrapperStyle}>
            <a
              style={{ color: this.props.privacyLinkColor }}
              href={this.props.linkPrivacy}
            >
              {this.props.linkPrivacyText}
            </a>

            <img src={this.props.logoSrc} height={this.props.logoHeight} />
          </div>
        )**/}
      </div>
    )
  }

  getDownloadSite() {
    const { t } = this.props

    const activeFields = {
      gender:
        this.props.activeGenderField === 'true' || this.props.activeGenderField,
      firstName:
        this.props.activeFirstNameField === 'true' ||
        this.props.activeFirstNameField,
      lastName:
        this.props.activeLastNameField === 'true' ||
        this.props.activeLastNameField,
      company:
        this.props.activeCompanyField === 'true' ||
        this.props.activeCompanyField,
      phone:
        this.props.activePhoneField === 'true' || this.props.activePhoneField,
      individual:
        this.props.activeIndividualField === 'true' ||
        this.props.activeIndividualField,
    }

    return (
      <React.Fragment>
        <Title
          style={{
            ...this.props.titleStyle,
            color: this.props.textColor,
            wordBreak: 'break-word',
          }}
        >
          {this.props.title}
        </Title>
        <Text
          style={{
            ...this.props.textStyle,
            color: this.props.textColor,
            wordBreak: 'break-word',
          }}
        >
          {this.props.text}
        </Text>

        {activeFields.gender && (
          <Select
            labelColor={this.props.labelColor}
            textColor={this.props.textColor}
            errorColor={this.props.buttonColor}
            onChange={value => this.setState({ gender: value })}
            value={this.state.gender}
            inputBackgroundColor={this.props.inputBackgroundColor}
            label={this.props.genderField}
            options={[
              {
                label: t('overlay_designer.gender-option_mr'),
                value: 'mr',
              },
              {
                label: t('overlay_designer.gender-option_mrs'),
                value: 'mrs',
              },
              {
                label: t('overlay_designer.gender-option_d'),
                value: 'd',
              },
            ]}
          />
        )}

        {activeFields.firstName && (
          <Input
            labelColor={this.props.labelColor}
            textColor={this.props.textColor}
            errorColor={this.props.buttonColor}
            onChange={value => this.setState({ firstName: value })}
            value={this.state.firstName}
            inputBackgroundColor={this.props.inputBackgroundColor}
            label={this.props.firstNameField}
            active={this.props['active-emailField']}
            error={this.state.firstNameError}
          />
        )}

        {activeFields.lastName && (
          <Input
            labelColor={this.props.labelColor}
            textColor={this.props.textColor}
            errorColor={this.props.buttonColor}
            onChange={value => this.setState({ lastName: value })}
            value={this.state.lastName}
            inputBackgroundColor={this.props.inputBackgroundColor}
            label={this.props.lastNameField}
            active={this.props['active-emailField']}
            error={this.state.lastNameError}
          />
        )}

        {activeFields.company && (
          <Input
            labelColor={this.props.labelColor}
            textColor={this.props.textColor}
            errorColor={this.props.buttonColor}
            onChange={value => this.setState({ company: value })}
            value={this.state.company}
            inputBackgroundColor={this.props.inputBackgroundColor}
            label={this.props.companyField}
            active={this.props['active-emailField']}
            error={this.state.companyError}
          />
        )}

        {activeFields.phone && (
          <Input
            labelColor={this.props.labelColor}
            textColor={this.props.textColor}
            errorColor={this.props.buttonColor}
            onChange={value => this.setState({ phone: value })}
            value={this.state.phone}
            inputBackgroundColor={this.props.inputBackgroundColor}
            label={this.props.phoneField}
            active={this.props['active-emailField']}
            error={this.state.phoneError}
          />
        )}

        {activeFields.individual && (
          <Input
            labelColor={this.props.labelColor}
            textColor={this.props.textColor}
            errorColor={this.props.buttonColor}
            onChange={value => this.setState({ individual: value })}
            value={this.state.individual}
            inputBackgroundColor={this.props.inputBackgroundColor}
            label={this.props.individualField}
            active={this.props['active-emailField']}
            error={this.state.individualError}
          />
        )}

        <Input
          labelColor={this.props.labelColor}
          textColor={this.props.textColor}
          errorColor={this.props.buttonColor}
          onChange={value => this.setState({ email: value })}
          error={this.state.emailError}
          value={this.state.email}
          inputBackgroundColor={this.props.inputBackgroundColor}
          label={this.props.emailField}
        />

        <div
          style={{
            ...privacyLinkBoxStyle,
            justifyContent:
              this.props.position === 'bottomBar' ||
              this.props.position === 'topBar'
                ? 'center'
                : '',
          }}
        >
          <input
            checked={this.state.checkBoxClicked}
            type="checkbox"
            style={{ margin: '0 5px 0 0' }}
            onChange={() =>
              this.setState({ checkBoxClicked: !this.state.checkBoxClicked })
            }
          ></input>
          <p
            style={{
              color: this.props.privacyLinkColor,
              fontSize: '13px',
              margin: '0 5px 0 0',
              wordBreak: 'break-word',
            }}
          >
            {`${this.props.privacyText}  `}
            <a
              style={{
                color: this.props.privacyLinkColor,
                fontSize: '13px',
                wordBreak: 'break-word',
                textDecoration: 'underline',
              }}
              href={this.props.linkPrivacy}
              target="_blank"
            >
              {this.props.linkPrivacyText}
            </a>
          </p>
        </div>
        <Button
          // onClick={() => this.finalize()}
          type="submit"
          fontFamily={this.props.fontFamily}
          buttonColor={this.props.buttonColor}
          buttonTextColor={this.props.buttonTextColor}
          buttonRadius={this.props.buttonRadius}
          blocked={!this.state.checkBoxClicked}
        >
          {this.props.submitButtonText}
        </Button>
      </React.Fragment>
    )
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  finalize(event) {
    const required = {
      email: true,
      firstName:
        this.props.requiredFirstNameField === 'true' ||
        this.props.requiredFirstNameField,
      lastName:
        this.props.requiredLastNameField === 'true' ||
        this.props.requiredLastNameField,
      company:
        this.props.requiredCompanyField === 'true' ||
        this.props.requiredCompanyField,
      phone:
        this.props.requiredPhoneField === 'true' ||
        this.props.requiredPhoneField,
      individual:
        this.props.requiredIndividualField === 'true' ||
        this.props.requiredIndividualField,
    }

    const errors = {
      emailError: !this.validateEmail(this.state.email),
      firstNameError: required.firstName && !this.state.firstName,
      lastNameError: required.lastName && !this.state.lastName,
      companyError: required.company && !this.state.company,
      phoneError: required.phone && !this.state.phone,
      individualError: required.individual && !this.state.individual,
    }

    if (Object.values(errors).filter(error => error).length === 0) {
      this.setState({ site: 'final' })
    } else {
      this.setState({ ...errors })
    }

    event.preventDefault()
  }

  getFinalSite(wrapperStyle) {
    return (
      <div style={wrapperStyle}>
        {this.props.assetImageSrc && this.props.imagePosition !== 'between' && (
          <AssetImage
            assetImageSrc={this.props.assetImageSrc}
            width={this.props.width}
            position={this.props.imagePosition}
          />
        )}
        <div>
          <Title
            style={{
              ...this.props.titleStyle,
              color: this.props.textColor,
              wordBreak: 'break-word',
            }}
          >
            {this.props.finalText}
          </Title>

          {/* <Text
            style={{
              color: this.props.textColor,
              fontFamily: this.props.fontFamily + ', sans-serif',
              ...this.props.textStyle,
            }}
          >
            {this.props.finalText}
          </Text> */}
          {this.props.assetImageSrc &&
            this.props.imagePosition === 'between' && (
              <AssetImage
                assetImageSrc={this.props.assetImageSrc}
                width={this.props.width}
                position={this.props.imagePosition}
              />
            )}
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.site !== prevState.site && this.contentAssetRef.current) ||
      JSON.stringify(this.props) !== JSON.stringify(prevProps)
    ) {
      console.log(this.contentAssetRef.current.offsetHeight)
      this.props.setFullHeight(this.contentAssetRef.current.offsetHeight)
    }
  }

  render() {
    if (this.state.close) {
      return null
    }

    let displayedSite

    const wrapperStyle = { ...initialWrapperStyle }

    if (this.props.imagePosition === 'right') {
      wrapperStyle.flexDirection = 'row-reverse'
      wrapperStyle.justifyContent = 'space-between'
    }

    if (this.props.imagePosition === 'top') {
      wrapperStyle.flexDirection = 'column'
    }

    if (this.props.imagePosition === 'bottom') {
      wrapperStyle.flexDirection = 'column-reverse'
    }

    if (this.props.imagePosition === 'between') {
      wrapperStyle.textAlign = 'center'
      wrapperStyle.justifyContent = 'center'
    }

    if (
      this.props.position === 'bottomBar' ||
      this.props.position === 'topBar'
    ) {
      wrapperStyle.width = '100%'
    }

    switch (this.state.site) {
      case 'download':
        displayedSite = this.getDownloadSite()
        break
      case 'final':
        displayedSite = this.getFinalSite(wrapperStyle)
        break
      default:
        displayedSite = this.getInitialSite(wrapperStyle)
    }

    let width = this.props.width

    if (
      this.props.position === 'bottomBar' ||
      this.props.position === 'topBar'
    ) {
      width = '100%'
    } else if (
      (this.props.imagePosition === 'right' ||
        this.props.imagePosition === 'init') &&
      displayedSite.props.style
    ) {
      // width = 'auto'
    }

    // if (this.contentAssetRef.current) {
    //   setTimeout(() => {
    //     this.props.setFullHeight(this.contentAssetRef.current.offsetHeight)
    //   })
    // }

    return (
      <form
        ref={this.contentAssetRef}
        onSubmit={event => {
          this.finalize(event)
        }}
        style={{
          ...containerStyle,
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : 'white',
          width,
          fontFamily: this.props.fontFamily + ', sans-serif',
          border: this.props.border ? this.props.border : '',
          borderRadius: this.props.borderRadius
            ? this.props.borderRadius
            : this.props.borderRadius,
          textAlign:
            this.props.position === 'bottomBar' ||
            this.props.position === 'topBar'
              ? 'center'
              : '',
        }}
      >
        {displayedSite}
        <a
          onClick={() =>
            this.props.preview
              ? this.setState({ site: 'initial' })
              : this.setState({ close: true })
          }
          style={{ ...closeStyle, color: this.props.textColor }}
        >
          ⨉
        </a>
      </form>
    )
  }
}

export default withTranslation('common')(ContentAsset)

/* const domContainer = document.querySelector('#content-asset-overlay-1')
ReactDOM.render(
  <ContentAsset
    backgroundColor="green"
    title="Super tolles neues E-Book!"
    textColor="white"
    text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
    width="400px"
    fontFamily="Roboto"
    buttonColor="blue"
    buttonTextColor="gray"
    buttonRadius="5px"
    assetImageSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Book_icon_%28closed%29_-_Blue_and_gold.svg/800px-Book_icon_%28closed%29_-_Blue_and_gold.svg.png"
    assetImageHeight="200px"
    labelColor="gray"
    border="5px solid red"
    borderRadius="15px"
    finalText="Danke, in kürze erhälst du eine E-Mail von uns."
  />,
  domContainer
)
const domContainer2 = document.querySelector('#content-asset-overlay-2')
ReactDOM.render(
  <ContentAsset
    backgroundColor="#E64A19"
    title="Mehr Traffic auf ihre Seite!"
    textColor="white"
    text="Laden sie sich jetzt dieses tolle E-Book runter und lernen sie viele neue Sachen."
    fontFamily="Roboto"
    width="300px"
    buttonColor="white"
    buttonTextColor="#E64A19"
    buttonRadius="50px"
    assetImageSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Book_icon_%28closed%29_-_Blue_and_gold.svg/800px-Book_icon_%28closed%29_-_Blue_and_gold.svg.png"
    assetImageHeight="150px"
    errorColor="white"
    downloadButtonText="Herunterladen"
    finalText="Danke, in kürze erhalten Sie eine E-Mail von uns."
  />,
  domContainer2
)
 */
