import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import H1 from 'presentational/H1'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'

import { NotificationContext } from 'functional/NotificationProvider'
import ContactAddToBlacklistModal from 'functional/ContactAddToBlacklistModal'
import BlacklistTable from 'functional/BlacklistTable'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'

const ContactBlackListScreen = () => {
  const { t, i18n } = useTranslation('common')

  return (
    <LoggedInScreenContainer>
      <React.Fragment>
        <HeaderContainer>
          <H1>{t('contacts.blacklisted_header')}</H1>
          <NotificationContext.Consumer>
            {context => (
              <HeaderButtonContainer>
                <Button
                  bold
                  onClick={() => {
                    context.methods.openModal(() => (
                      <ContactAddToBlacklistModal
                        onClose={context.methods.closeModal}
                      />
                    ))
                  }}
                >
                  + {t('contacts.blacklisted_add_button')}
                </Button>
              </HeaderButtonContainer>
            )}
          </NotificationContext.Consumer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('contacts.blacklisted_description')}</Paragraph>
        </SubHeaderContainer>
        <ContentContainer>
          <BlacklistTable />
        </ContentContainer>
      </React.Fragment>
    </LoggedInScreenContainer>
  )
}

export default withRouter(ContactBlackListScreen)
