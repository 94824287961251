import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import DangerButton from 'presentational/DangerButton'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'

import { withHandlers } from 'util/form'
import WebsiteContainer from 'containers/WebsiteContainer'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  button {
    max-width: 35%;
  }
`

const ConfirmWebsiteDeleteModal = ({
  deleteWebsite,
  closeModal,
  toggleTopNotification,
  onDelete,
  id,
  title,
}) => {
  const { t, i18n } = useTranslation('common')
  return (
    <React.Fragment>
      <H3>{t('websites.deletion_modal_header', { website: title })}</H3>
      <Paragraph>{t('websites.deletion_modal_description')}</Paragraph>
      <ButtonWrapper>
        <Subscribe to={[WebsiteContainer]}>
          {({ state: { deleteLoading } }) => (
            <React.Fragment>
              <DangerButton
                onClick={withHandlers(
                  () => deleteWebsite(id),
                  () => {
                    onDelete()
                    toggleTopNotification(
                      'success',
                      t('websites.deletion_success')
                    )
                    closeModal()
                  },
                  () => {
                    toggleTopNotification(
                      'error',
                      t('websites.deletion_failed')
                    )
                    closeModal()
                  }
                )}
              >
                {t('default.delete')}
              </DangerButton>
              {deleteLoading && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </React.Fragment>
          )}
        </Subscribe>
        <Button kind="secondary" onClick={() => closeModal()}>
          {t('default.cancel')}
        </Button>
      </ButtonWrapper>
    </React.Fragment>
  )
}

ConfirmWebsiteDeleteModal.propTypes = {
  closeModal: PropTypes.func,
  deleteWebsite: PropTypes.func,
  toggleTopNotification: PropTypes.func,
  onDelete: PropTypes.func,
  id: PropTypes.string,
}

ConfirmWebsiteDeleteModal.defaultProps = {
  closeModal: () => {},
  deleteWebsite: () => {},
  toggleTopNotification: () => {},
  onDelete: () => {},
  id: '',
}

export default ConfirmWebsiteDeleteModal
