import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import TextInput from 'presentational/TextInput'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import PrimaryButton from 'presentational/PrimaryButton'

import { NotificationContext } from 'functional/NotificationProvider'

import BlacklistContainer from 'containers/BlacklistContainer'
import { withHandlers, getErrorMessage } from 'util/form'
import { isEmail } from 'util/validations'

import queries from 'util/mediaQueries'

const ButtonContainer = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-top: 9px;

  ${queries.desktop`
    width: 100%;
  `};
`

/**
 * @description Modal to add an Email address to the blacklist.
 * @param onClose Function that is called to close the modal.
 * @param defaultEmail Email address that will be displayed in the input on mount.
 */
const ContactAddToBlacklistModal = ({ onClose, defaultEmail }) => {
  const { t, i18n } = useTranslation('common')

  const data = { emailPlain: defaultEmail }

  return (
    <NotificationContext.Consumer>
      {context => (
        <Subscribe to={[BlacklistContainer]}>
          {({ addOne }) => (
            <Form
              initialValues={data}
              onSubmit={withHandlers(
                addOne,
                () => {
                  context.methods.toggleTopNotification(
                    'success',
                    t('contacts.blacklisted_success_notification')
                  )
                  onClose()
                },
                () => {
                  context.methods.toggleTopNotification(
                    'error',
                    t('contacts.blacklisted_error_notification')
                  )
                }
              )}
              render={({ submitting, submitError, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <H3>{t('contacts.blacklisted_add_modal_header')}</H3>
                  <Paragraph>
                    {t('contacts.blacklisted_add_modal_description')}
                  </Paragraph>
                  <Field
                    name="emailPlain"
                    label={t('default.email')}
                    noInline
                    validate={isEmail}
                    component={TextInput}
                    format={(value = '') => value.trim()}
                    formatOnBlur
                    initialValues={defaultEmail ? defaultEmail : ''}
                  />
                  {submitError && (
                    <FormError>{getErrorMessage(submitError)}</FormError>
                  )}
                  {submitting && <Loader />}
                  <ButtonContainer>
                    <PrimaryButton type="submit" disabled={submitting}>
                      {t('default.add')}
                    </PrimaryButton>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button onClick={onClose} kind="secondary" type="nobg">
                      {t('default.cancel')}
                    </Button>
                  </ButtonContainer>
                </form>
              )}
            />
          )}
        </Subscribe>
      )}
    </NotificationContext.Consumer>
  )
}

ContactAddToBlacklistModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultEmail: PropTypes.string,
}

ContactAddToBlacklistModal.defaultProps = {
  defaultEmail: '',
}

export default ContactAddToBlacklistModal
