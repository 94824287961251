import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'

import queries from 'util/mediaQueries'

import { withTranslation } from 'react-i18next'

const ButtonContainer = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-top: 9px;

  ${queries.desktop`
    width: 100%;
  `};
`

const UpgradeLicenseModal = ({ closeModal, t }) => {
  return (
    <React.Fragment>
      <H3>{t('license.upgrade_title')}</H3>
      <ButtonContainer>
        <Button onClick={closeModal} kind="secondary">
          {t('default.cancel')}
        </Button>
      </ButtonContainer>
    </React.Fragment>
  )
}

UpgradeLicenseModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default withTranslation('common')(UpgradeLicenseModal)
