import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Badge from '../Badge'

const TabsContainer = styled.div`
  height: 100%;
`
const Tabs = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
`

const Tab = styled.li`
  display: inline;
  text-align: center;
  cursor: pointer;
`
const Item = styled.div`
   {
    display: inline-block;
    width: ${props => {
      if (props.kind === 'normal') {
        return '150px'
      }
      if (props.kind === 'small') {
        return '80px'
      }
      if (props.kind === 'big') {
        return '250px'
      }
      if (props.kind === 'mediumsmall') {
        return '70px'
      }
    }};
    font-size: ${props => {
      if (props.kind === 'normal') {
        return '16px'
      }
      if (props.kind === 'small') {
        return '12px'
      }
      if (props.kind === 'big') {
        return '16px'
      }
      if (props.kind === 'mediumsmall') {
        return '14px'
      }
    }};
    text-transform: ${props => props.uppercase && 'uppercase'};
    padding: 0.75rem 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    text-decoration: none;
    color: #333;
  }
`

const Underline = styled.hr`
   {
    height: 0.35rem;
    width: ${props => {
      if (props.kind === 'normal') {
        return '150px'
      }
      if (props.kind === 'small') {
        return '80px'
      }
      if (props.kind === 'big') {
        return '250px'
      }
      if (props.kind === 'mediumsmall') {
        return '70px'
      }
    }};
    margin: 0;
    margin-top: -0.35rem;
    background: ${props => props.theme.colors.primary};
    border: none;
    transition: 0.3s ease-in-out;
    margin-left: ${props => {
      if (props.kind === 'normal') {
        return props.index * 150 + 'px'
      }
      if (props.kind === 'small') {
        return props.index * 80 + 'px'
      }
      if (props.kind === 'big') {
        return props.index * 250 + 'px'
      }
      if (props.kind === 'mediumsmall') {
        return props.index * 70 + 'px'
      }
    }};
  }
`

const UnderlineBackground = styled.hr`
  height: 0.35rem;
  width: 100%;
  background: #f1efed;
  margin: 0;
  border: none;
`

/**
 * A Tabs component.
 * @version 1.0.0
 * @author Adel Memariani
 */

const HorizontalMenu = props => {
  return (
    <TabsContainer>
      <Tabs>
        {props.tabs.map((item, index) => (
          <Tab
            index={index}
            kind={props.kind}
            key={index}
            onClick={() => props.onChange(index)}
          >
            <Item
              key={index}
              kind={props.kind}
              uppercase={props.uppercase}
              active={props.activeTab === index}
            >
              {item.name}
              {item.count !== null && item.count !== undefined && (
                <Badge count={item.count} />
              )}
            </Item>
          </Tab>
        ))}
        {props.underlineBackground && <UnderlineBackground />}
        <Underline {...props} index={props.activeTab} />
      </Tabs>
    </TabsContainer>
  )
}

HorizontalMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  kind: PropTypes.string,
  uppercase: PropTypes.bool,
  filterData: PropTypes.func,
  activeTab: PropTypes.number,
  onChange: PropTypes.func,
}

HorizontalMenu.defaultProps = {
  tabs: [],
  kind: 'normal',
  uppercase: false,
  onChange: () => {},
  activeTab: 0,
}

export default HorizontalMenu
