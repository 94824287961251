import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import Tag from 'presentational/Tag'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'

import ListInputModal from 'functional/ListInputModal'

import LabelsContainer from 'containers/LabelsContainer'

const WrapButton = styled(Button)`
  width: auto;
`

const TagContainer = styled.div`
  display: flex;
`

class LabelListInputModal extends React.Component {
  componentDidMount() {
    this.props.getAllLabels()
  }

  render() {
    return (
      <Subscribe to={[LabelsContainer]}>
        {({ state: { data, getAllLoading }, getAllLabels }) => {
          if (getAllLoading) {
            return <Loader />
          }

          if (!data) return null

          const mappedData = data.map(
            ({ '@id': id, name, color, ...restData }) => ({
              label: name,
              color: color,
              value: id,
              meta: restData,
            })
          )

          return (
            <ListInputModal
              {...this.props}
              title={this.props.t('labels.label_input')}
              data={mappedData}
              comparer={(selected, item) =>
                !!selected.find(entry => entry.value === item.value)
              }
              transformRow={(entry, addItem) => [
                <TagContainer>
                  <Tag
                    tag={{ color: entry.color, name: entry.label }}
                    noMargin
                  />
                </TagContainer>,
                <WrapButton
                  kind="normal"
                  size="small"
                  onClick={() => addItem(entry)}
                >
                  {this.props.t('default.add')}
                </WrapButton>,
              ]}
              headers={['Label']}
            />
          )
        }}
      </Subscribe>
    )
  }
}

LabelListInputModal.propTypes = {
  close: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string),
  getAllLabels: PropTypes.func.isRequired,
}

const LabelListInputModalWrapper = props => (
  <Subscribe to={[LabelsContainer]}>
    {({ getAllLabels }) => (
      <LabelListInputModal {...props} getAllLabels={getAllLabels} />
    )}
  </Subscribe>
)

export default withTranslation('common')(LabelListInputModalWrapper)
