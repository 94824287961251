import React from 'react'

const cookieBannerStyle = {
  fontFamily: 'Roboto',
  backgroundColor: '#03a9f4',
  color: '#b3e5fc',
  width: 'auto',
  padding: '15px 20px',
  boxSizing: 'border-box',
}

const cookieTitleStyle = {
  fontSize: '15px',
  margin: '5px 0',
}

const cookieTextStyle = {
  color: '#b3e5fc',
  fontSize: '13px',
  margin: '0 0 10px 0',
}

const cookieButtonWrapperStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}

const cookieButtonStyle = {
  margin: '0 10px',
  border: 'none',
  backgroundColor: '#448aff',
  padding: '5px 15px',
  height: '35px',
  fontSize: '14px',
  cursor: 'pointer',
}

class CookieBanner extends React.Component {
  render() {
    const {
      title,
      text,
      allowButtonText,
      denyButtonText,
      position,
    } = this.props
    const finalCookieTextStyle = { ...cookieTextStyle }
    const finalCookieTitleStyle = { ...cookieTitleStyle }
    const finalCookieBannerStyle = { ...cookieBannerStyle }
    const finalCookieButtonStyle = { ...cookieButtonStyle }
    const finalCookieButtonWrapperStyle = { ...cookieButtonWrapperStyle }

    finalCookieTitleStyle.color = this.props.titleColor
    finalCookieTitleStyle.fontSize = this.props.titleFontSize
    finalCookieTextStyle.color = this.props.textColor
    finalCookieTextStyle.fontSize = this.props.textFontSize

    finalCookieBannerStyle.width = this.props.width

    if (position === 'bottomBar' || position === 'topBar') {
      finalCookieBannerStyle.width = '100%'
      finalCookieBannerStyle.display = 'flex'
      finalCookieBannerStyle.alignItems = 'center'
      finalCookieBannerStyle.justifyContent = 'space-between'

      finalCookieButtonWrapperStyle.width = ''
    }

    finalCookieBannerStyle.fontFamily = this.props.fontFamily
    finalCookieBannerStyle.backgroundColor = this.props.backgroundColor
    finalCookieBannerStyle.borderRadius = this.props.borderRadius
    finalCookieBannerStyle.border = `${this.props.borderWidth}px solid ${this.props.borderColor}`

    finalCookieButtonStyle.backgroundColor = this.props.buttonColor
    finalCookieButtonStyle.color = this.props.buttonTextColor
    finalCookieButtonStyle.borderRadius = this.props.buttonBorderRadius

    return (
      <div style={finalCookieBannerStyle}>
        <div>
          <p style={finalCookieTitleStyle}>{title ? title : 'Title'}</p>
          <p style={finalCookieTextStyle}>{text ? text : 'Text'}</p>
        </div>
        <div style={finalCookieButtonWrapperStyle}>
          <button style={finalCookieButtonStyle}>
            {denyButtonText ? denyButtonText : 'Deny'}
          </button>
          <button style={finalCookieButtonStyle}>
            {allowButtonText ? allowButtonText : 'Allow'}
          </button>
        </div>
      </div>
    )
  }
}

export default CookieBanner
