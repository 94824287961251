import React, { Fragment } from 'react'
import styled from 'styled-components'

import CloseIcon from 'react-icons/lib/md/close'

const TagContainer = styled.div`
  padding: 4px;
  margin-bottom: ${props => (props.noMargin ? '3px' : '20px')};
  margin-right: 0;

  background: ${props => (props.color ? props.color : '#f1efed')};
  color: #fff;

  border-radius: ${props => (props.deleteButton ? '0px' : '2px')};
  display: inline-block;

  font-size: 12px;

  cursor: ${props => (props.onClick ? 'pointer' : 'unset')};
`

const Triangle = styled.div`
  margin-left: -1px;
  margin-right: 6px;

  display: inline;

  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 10px solid ${props => props.color};
  border-bottom: 11px solid transparent;
`

const Container = styled.div`
  display: inline-flex;
  pointer-events: click-through;
`

const CloseContainer = styled.div`
  margin-bottom: 3px;
  padding: 0 2px;
  background-color: #554c47;
  color: white;
  border: none;
  cursor: pointer;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`

const StyledCloseIcon = styled(CloseIcon)`
  margin-top: 3px;
`

const Tag = ({
  tag,
  noMargin = false,
  onClick,
  deleteButton = false,
  onDelete,
}) => (
  <Container onClick={onClick}>
    {deleteButton && (
      <CloseContainer onClick={onDelete}>
        <StyledCloseIcon />
      </CloseContainer>
    )}
    <TagContainer
      color={tag.color}
      noMargin={noMargin}
      deleteButton={deleteButton}
      onClick={onClick}
    >
      {tag.name}
    </TagContainer>
    <Triangle color={tag.color}></Triangle>
  </Container>
)

export default Tag
