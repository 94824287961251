import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import Loader from 'presentational/Loader'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import { NotificationContext } from 'functional/NotificationProvider'
import WebsiteForm from 'functional/WebsiteForm'

import WebsiteContainer from 'containers/WebsiteContainer'
import FileContainer from 'containers/FileContainer'

const CenteredLoaderContainer = styled(LoaderContainer)`
  height: 100vh;
`

import { withHandlers } from 'util/form'

class EditWebsiteScreen extends Component {
  componentDidMount() {
    this.props.getWebsite(this.props.match.params.id)
  }

  render() {
    const {
      t,
      history: { push },
    } = this.props

    return (
      <LoggedInScreenContainer>
        <Subscribe to={[WebsiteContainer, FileContainer]}>
          {(
            {
              state: { websiteData, getWebsiteLoading },
              updateWebsite,
              deleteWebsite,
            },
            { getFile }
          ) => {
            if (getWebsiteLoading || websiteData === null) {
              return (
                <CenteredLoaderContainer>
                  <Loader size="large" />
                </CenteredLoaderContainer>
              )
            }

            return (
              <NotificationContext.Consumer>
                {context => (
                  <WebsiteForm
                    onSubmit={withHandlers(
                      updateWebsite,
                      () => {
                        context.methods.toggleTopNotification(
                          'success',
                          t('websites.edit_save_success')
                        )
                        this.props.history.push('/websites')
                      },
                      () => {
                        context.methods.toggleTopNotification(
                          'error',
                          t('websites.edit_save_failed')
                        )
                      }
                    )}
                    getFile={getFile}
                    edit
                    initialValues={websiteData}
                    deleteWebsite={deleteWebsite}
                    push={push}
                  />
                )}
              </NotificationContext.Consumer>
            )
          }}
        </Subscribe>
      </LoggedInScreenContainer>
    )
  }
}

EditWebsiteScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

EditWebsiteScreen.defaultProps = {
  history: {
    push: () => {},
  },
}

const EditWebsiteScreenWrapper = props => (
  <Subscribe to={[WebsiteContainer]}>
    {({ getWebsite }) => (
      <EditWebsiteScreen {...props} getWebsite={getWebsite} />
    )}
  </Subscribe>
)

export default withTranslation('common')(EditWebsiteScreenWrapper)
