import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Field, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import FormError from 'presentational/FormError'
import TextInput from 'presentational/TextInput'
import CheckboxInput from 'presentational/CheckboxInput'
import RadioInput from '../../presentational/RadioInput'
import Paragraph from 'presentational/Paragraph'
import ListInput from 'presentational/ListInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import FormInlineWrapper from 'presentational/FormInlineWrapper'
import FileButtonInput from 'presentational/FileButtonInput'
import FileInput from 'functional/FileInput'

import { NotificationContext } from 'functional/NotificationProvider'

import {
  HeaderContainer,
  HeaderButtonContainer,
  SubHeaderContainer,
} from 'presentational/ScreenLayoutContainers'

import LabelListInputModal from 'functional/LabelListInputModal'

import { isEmpty, isURL } from 'util/validations'
import { cleanId } from 'util/api'

const FlexFormFieldWrapper = styled(FormFieldWrapper)`
  display: flex;
  max-width: 100%;
`

const WrapButton = styled(Button)`
  width: auto;
  margin-right: 5px;
`

const LoaderRightMargin = styled(Loader)`
  margin-right: 10px;
`

const ParagraphMarginBottom = styled(Paragraph)`
  margin-bottom: 32px;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const FileButtonsWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const DownloadFileButton = styled(Button)`
  width: auto;
  margin-left: 15px;
`

const FileNameParagraph = styled(Paragraph)`
  margin-top: 20px;
`

const initialTransform = values => {
  if (!values) return undefined

  return {
    ...values,
    labelIds: values.labels.map(({ label: { '@id': id, name, color } }) => ({
      value: id,
      label: name,
      color: color,
    })),
  }
}

const submitTransform = (onSubmit, values, ...rest) => {
  if (values.labelIds === undefined) {
    values.labelIds = []
  }

  const transformed = {
    ...values,
    labelIds: values.labelIds.map(({ value }) => {
      return parseInt(cleanId(value))
    }),
  }
  transformed.labelIds = JSON.stringify(transformed.labelIds)

  if (values.file !== null && values.file !== undefined) {
    transformed.file = values.file.base64
    transformed.originalName = values.file.name
  }

  return onSubmit(transformed, ...rest)
}

class ImageSection extends React.Component {
  constructor() {
    super()

    this.state = {
      image: null,
      imageFile: null,
      imageName: null,
      deleted: false,
    }
  }

  componentDidMount() {
    if (
      this.props.initialImage === '' ||
      this.props.initialImage === null ||
      this.props.initialImage === undefined
    ) {
      return
    }
    this.props
      .getFile(this.props.initialImage.name)
      .then(res => this.setState({ image: res }))
  }

  render() {
    const { initialImage, t, onChange } = this.props
    const { image, imageFile, imageName, deleted } = this.state

    let finalImageFile = null
    let finalImageFileName = ''

    if (image !== null && imageFile === null && !deleted) {
      finalImageFile = `data:${image.mimetype};base64,${image.base64}`
      finalImageFileName = initialImage.name
    }

    if (imageFile !== null && !deleted) {
      finalImageFile = imageFile
      finalImageFileName = imageName
    }

    return (
      <NotificationContext.Consumer>
        {context => (
          <Section>
            <H2>{this.props.t('content_assets.form_image_title')}</H2>
            <Paragraph>
              {this.props.t('content_assets.form_image_sub_title')}
            </Paragraph>
            <FileInput
              file={finalImageFile}
              crop={{ active: true, ratio: 1 / 1 }}
              fileName={finalImageFileName}
              onUpload={(imageName, imageFile) => {
                this.setState({
                  imageFile: imageFile,
                  imageName: imageName,
                  deleted: false,
                })
                onChange(imageName)
              }}
              onDelete={e => {
                this.setState({
                  imageFile: null,
                  imageName: null,
                  deleted: true,
                })
                onChange('LOGO_DELETE')
              }}
              onUploadFailed={() =>
                context.methods.toggleTopNotification(
                  'error',
                  t('websites.edit_image_upload_failed')
                )
              }
            ></FileInput>
          </Section>
        )}
      </NotificationContext.Consumer>
    )
  }
}

const ContentAssetForm = ({
  onSubmit,
  initialValues,
  edit,
  onDownload,
  getFile,
}) => {
  const { t, i18n } = useTranslation('common')
  const [contentAssetType, setContentAssetType] = useState(
    initialValues ? initialValues.contentAssetType : 'file'
  )
  const [imageFilename, setImageFilename] = useState('')

  return (
    <Form
      onSubmit={data =>
        submitTransform(onSubmit, {
          ...data,
          imageFilename,
          contentAssetType,
        })
      }
      initialValues={initialTransform(initialValues)}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <HeaderContainer>
            <H1>
              {edit
                ? t('content_assets.edit_header')
                : t('content_assets.add_header')}
            </H1>
            <HeaderButtonContainer>
              <NavLink to="/assets">
                <Button kind="secondary">
                  {t('default.back_to_overview')}
                </Button>
              </NavLink>
            </HeaderButtonContainer>
          </HeaderContainer>
          <SubHeaderContainer>
            <ParagraphMarginBottom>
              {edit
                ? t('content_assets.edit_description')
                : t('content_assets.add_description')}
            </ParagraphMarginBottom>
          </SubHeaderContainer>
          <SectionContainer>
            <Section>
              <FormFieldWrapper>
                <Field
                  name="name"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  label={t('content_assets.form_name_label')}
                  validate={isEmpty}
                />
              </FormFieldWrapper>
              <H2>{t('content_assets.form_title_header')}</H2>
              <Paragraph>
                {t('content_assets.form_title_description')}
              </Paragraph>
              <FormFieldWrapper>
                <Field
                  name="title"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  label={t('content_assets.form_title_label')}
                  validate={isEmpty}
                />
              </FormFieldWrapper>
              <Paragraph>
                {t('content_assets.form_teaser_description')}
              </Paragraph>
              <FormFieldWrapper>
                <Field
                  name="teaser"
                  label={t('content_assets.form_teaser_label')}
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  validate={isEmpty}
                />
              </FormFieldWrapper>
              <Paragraph>
                {t('content_assets.form_email_subject_description')}
              </Paragraph>
              <FormFieldWrapper>
                <Field
                  name="subject"
                  label={t('content_assets.form_email_subject_label')}
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  validate={isEmpty}
                />
              </FormFieldWrapper>
            </Section>
            <ImageSection
              name="contentAssetImage"
              initialImage={initialValues && initialValues.image}
              getFile={getFile}
              onChange={fileName => {
                setImageFilename(fileName)
              }}
              t={t}
            />
            <Section>
              <H2>{t('content_assets.form_label_header')}</H2>
              <Paragraph>
                {t('content_assets.form_label_description')}
              </Paragraph>
              <Field
                name="labelIds"
                component={ListInput}
                label={t('content_assets.form_label_label')}
                modal={LabelListInputModal}
              />
            </Section>
            <Section>
              <div>
                <H2>{t('content_assets.form_file_header')}</H2>
                <Paragraph>
                  {t('content_assets.form_file_description')}
                </Paragraph>
                <FlexFormFieldWrapper>
                  <Field
                    input={{
                      name: 'contentAssetType',
                      onChange: event => {
                        setContentAssetType(event.target.value)
                      },
                      value: 'file',
                      checked: contentAssetType === 'file',
                    }}
                    label={t('content_assets.form_file_selection_file_label')}
                    name="contentAssetType"
                    component={RadioInput}
                  />
                  <Field
                    input={{
                      name: 'contentAssetType',
                      onChange: event => {
                        setContentAssetType(event.target.value)
                      },
                      value: 'url',
                      checked: contentAssetType === 'url',
                    }}
                    label={t('content_assets.form_file_selection_url_label')}
                    name="contentAssetType"
                    component={RadioInput}
                  />
                </FlexFormFieldWrapper>
                {initialValues &&
                  initialValues.file &&
                  contentAssetType === 'file' &&
                  initialValues.file.originalName && (
                    <FileNameParagraph>
                      {t('content_assets.form_active_file')}{' '}
                      {initialValues.file.originalName}
                    </FileNameParagraph>
                  )}
                {contentAssetType === 'file' && (
                  <FileButtonsWrapper>
                    <FormFieldWrapper>
                      <NotificationContext.Consumer>
                        {context => (
                          <Field
                            name="file"
                            component={FileButtonInput}
                            maxSize={5242880}
                            onSizeError={() => {
                              context.methods.toggleTopNotification(
                                'error',
                                t('content_assets.file_upload_failed')
                              )
                            }}
                            validate={edit ? null : isEmpty}
                          />
                        )}
                      </NotificationContext.Consumer>
                    </FormFieldWrapper>
                    {edit &&
                      (initialValues.contentAssetType === 'file' ||
                        initialValues.mimetype !== 'application/x-empty') && (
                        <FormFieldWrapper>
                          <DownloadFileButton onClick={onDownload}>
                            {t('content_assets.form_download_file')}
                          </DownloadFileButton>
                        </FormFieldWrapper>
                      )}
                  </FileButtonsWrapper>
                )}
                {contentAssetType === 'url' && (
                  <FormFieldWrapper>
                    <Field
                      name="url"
                      component={TextInput}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                      label={t('content_assets.form_file_url_label')}
                      validate={isURL}
                    />
                  </FormFieldWrapper>
                )}
              </div>
            </Section>
            <Section>
              <H2>{t('content_assets.form_status_header')}</H2>
              <Paragraph>
                {t('content_assets.form_status_description')}
              </Paragraph>
              <Field
                name="active"
                component={CheckboxInput}
                label={t('content_assets.form_status_label')}
                type="checkbox"
                noInline
              />
            </Section>
            <Section>
              <FormInlineWrapper>
                {submitError && <FormError>{submitError}</FormError>}
              </FormInlineWrapper>
              {submitting && <LoaderRightMargin />}
              <FormSpy
                subscription={{ values: true }}
                render={({ values }) => (
                  <WrapButton type="submit" disabled={submitting}>
                    {edit
                      ? t('content_assets.update_asset')
                      : t('content_assets.create_asset')}
                  </WrapButton>
                )}
              />
            </Section>
          </SectionContainer>
        </form>
      )}
    />
  )
}

ContentAssetForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  edit: PropTypes.bool,
}

ContentAssetForm.defaultProps = {
  onSubmit: () => {},
  edit: false,
}

export default ContentAssetForm
