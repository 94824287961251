import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const BadgeContainer = styled.a`
   {
    position: relative;

    :after {
      content: ${props => "'" + props.count + "'"};

      display: inline-block;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 600;

      text-align: center;
      line-height: 27px;

      color: white;

      width: ${props =>
        props.count.toString().length > 2
          ? props.count.toString().length * 14 + 'px'
          : '28px'};
      height: 28px;

      border-radius: 500px;
      background: ${props => props.theme.colors.primary};
    }
  }
`

/**
 * A Badge component.
 * @version 1.0.0
 * @author Adel Memariani
 */

const Badge = props => {
  return <BadgeContainer count={props.count} />
}

Badge.propTypes = {
  count: PropTypes.number,
}

Badge.defaultProps = {
  count: [],
}

export default Badge
