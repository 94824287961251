import React from 'react'
import styled from 'styled-components'

import GridIcon from 'react-icons/lib/md/border-all'
import ListIcon from 'react-icons/lib/md/view-list'

const IconButton = styled.button`
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 27px;
  transition: 0.2s;
  margin-left: 5px;

  color: ${props => (props.active ? '#554c47' : '#9b9b9b')};
  border-radius: 10%;

  :hover {
    border-radius: 50%;
    background-color: #e8e8e8;
  }
`

const GridTableSwitch = ({ onSwitch, active }) => (
  <div>
    <IconButton onClick={() => onSwitch('grid')} active={active === 'grid'}>
      <GridIcon />
    </IconButton>
    <IconButton onClick={() => onSwitch('list')} active={active === 'list'}>
      <ListIcon />
    </IconButton>
  </div>
)

export default GridTableSwitch
