import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  line-height: 24px;
`

const EntryName = styled.p`
  color: #f1efed;
  font-size: 1em;
  margin-right: 15px;
  margin-bottom: 0;
  margin-top: 0;
`

const EntryValue = styled.p`
  color: white;
  margin: 0;
`
/**
 * @description Component displays a name and value in the onboaridng summary.
 * @author Jan Mägdefrau
 */
const OnboardignWizardSummaryEntry = ({ name, value }) => (
  <FlexBox>
    <EntryName>{name}:</EntryName>
    <EntryValue>{value}</EntryValue>
  </FlexBox>
)

OnboardignWizardSummaryEntry.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
}

OnboardignWizardSummaryEntry.defaultProps = {
  name: '',
  value: '',
}

/** @component */
export default OnboardignWizardSummaryEntry
