import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NumberSelctorButton = styled.button`
  height: 30px;
  line-height: 30px;
  border: 1px solid #dee1e2;
  color: black;
  background-color: white;
  cursor: pointer;
  border-top-left-radius: ${props => (props.left ? '4px' : '0px')};
  border-bottom-left-radius: ${props => (props.left ? '4px' : '0px')};
  border-top-right-radius: ${props => (props.right ? '4px' : '0px')};
  border-bottom-right-radius: ${props => (props.right ? '4px' : '0px')};
  margin: 0;
  width: 25px;
  padding: 0 6px;
  transition: 0.2s;

  :hover {
    background-color: #dee1e2;
  }
`

const NumberSelctorInput = styled.input`
  height: 30px;
  margin: 0;
  line-height: 30px;
  border-top: 1px solid #dee1e2;
  border-bottom: 1px solid #dee1e2;
  border-left: none;
  border-right: none;
  width: ${props => (props.hasDataType ? '30px' : '50px')};
  text-align: ${props => (props.hasDataType ? 'right' : 'center')};
  background-color: #f9f9f9;
  font-size: 13px;
`

const NumberSelctorContainer = styled.div`
  display: flex;
`

const NumberSelectorDataType = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 13px;
  border-top: 1px solid #dee1e2;
  border-bottom: 1px solid #dee1e2;
  background-color: #f9f9f9;
  height: 30px;
  line-height: 26px;
  padding-right: 5px;
  padding-left: 2px;
`

class NumberSelctor extends Component {
  constructor(props) {
    super(props)

    this.timer = undefined
    this.timeBetweenIncrease = 200
  }

  changeValue(value) {
    const { min, max, onChange } = this.props

    if (isNaN(value)) return
    if (min !== -1 && value < min) return
    if (max !== -1 && value > max) return

    onChange(value)
  }

  repeat(value) {
    this.changeValue(this.props.value + value)
    this.timer = setTimeout(() => this.repeat(value), this.timeBetweenIncrease)
    this.timeBetweenIncrease /= 2
  }

  onMouseDown(value) {
    this.repeat(value)
    this.timeBetweenIncrease = 200
  }

  onMouseUp() {
    clearTimeout(this.timer)
  }

  render() {
    const { step, dataType, value } = this.props

    return (
      <NumberSelctorContainer>
        <NumberSelctorButton
          onMouseUp={() => this.onMouseUp()}
          onMouseDown={() => this.onMouseDown(-step)}
          left
        >
          -
        </NumberSelctorButton>
        <NumberSelctorInput
          value={value}
          onChange={event => {
            if (isNaN(event.target.value)) return
            this.changeValue(Number(event.target.value))
          }}
          hasDataType={dataType !== undefined && dataType !== ''}
        />
        {dataType !== '' && (
          <NumberSelectorDataType>{dataType}</NumberSelectorDataType>
        )}
        <NumberSelctorButton
          onMouseUp={() => this.onMouseUp()}
          onMouseDown={() => this.onMouseDown(+step)}
          right
        >
          +
        </NumberSelctorButton>
      </NumberSelctorContainer>
    )
  }
}

NumberSelctor.propTypes = {
  step: PropTypes.number,
  onChange: PropTypes.func,
  dataType: PropTypes.string,
  value: PropTypes.number,
}

NumberSelctor.defaultProps = {
  step: 1,
  onChange: () => {},
  dataType: '',
  defaultValue: 0,
}

export default NumberSelctor
