import React from 'react'

import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import { isEmpty, isURLWithProtocol } from 'util/validations'

import { withTranslation } from 'react-i18next'

const WebsiteFormSection = ({ required, onChange, value, dark, noInline, t }) => (
  <FormFieldWrapper>
    <Field
      name="title"
      component={TextInput}
      format={(value = '') => value.trim()}
      formatOnBlur
      type="text"
      label={t('default.url')}
      dark={dark}
      noInline={noInline}
      format={(value = '') => value.trim()}
      formatOnBlur
      validate={required && isEmpty && isURLWithProtocol}
    />
  </FormFieldWrapper>
)

WebsiteFormSection.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  dark: PropTypes.bool,
  noInline: PropTypes.bool,
}

WebsiteFormSection.defaultProps = {
  required: false,
  onChange: () => {},
  value: '',
  dark: false,
  noInline: false,
}

/** @component */
export default withTranslation('common')(WebsiteFormSection)
