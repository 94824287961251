import React, { Component } from 'react'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { cleanId } from 'util/api'

import H1 from 'presentational/H1'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import GridTableSwitch from 'functional/GridTableSwitch'
import WebsiteListItem from 'presentational/WebsiteListItem'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  FooterContainer,
  HeaderButtonContainer,
  LoaderContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import Table from 'functional/Table'
import Search from 'functional/Search'
import { NotificationContext } from 'functional/NotificationProvider'
import WebsiteShowScriptTagModal from 'functional/WebsiteShowScriptTagModal'
import ConfirmWebsiteDeleteModal from 'functional/ConfirmWebsiteDeleteModal'

import WebsiteContainer from 'containers/WebsiteContainer'

import { formatDate } from 'util/date'

const WebsiteListHeaderContainer = styled(HeaderContainer)`
  background: #f1efed;
`
const StyledContentContainer = styled(ContentContainer)`
  margin-top: 100px;
`

const WebsiteListFooterContainer = styled(FooterContainer)`
  background: #f1efed;
  justify-content: center;
  align-items: center;
`

const CenteredLoaderContainer = styled(LoaderContainer)`
  margin-top: 150px;
`

const LoadMoreButton = styled(Button)`
  width: auto;
  margin-left: 1em;
  align-self: center;
`

const WebsiteListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  background: #f1efed;

  padding: 0 32px 32px;
`
const SwitchContainer = styled.div`
  float: right;
  margin-top: 32px;
  margin-right: 32px;
`

class WebsiteListScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      loading: false,
      view: 'grid',
      loadMore: false,
    }

    this.filterThrottle = null
  }

  componentDidMount() {
    this.onQueryChange()
  }

  componentDidUpdate(_, prevState) {
    const { state } = this

    if (prevState.filter !== state.filter) {
      if (this.filterThrottle) clearTimeout(this.filterThrottle)

      this.filterThrottle = setTimeout(() => this.onQueryChange(), 500)
    }
  }

  getDataWithQueries(pageChanged = false) {
    const { filter } = this.state
    const filterKey = 'title'
    const queries = []

    if (filter) queries[filterKey] = filter

    return this.props.getWebsites(queries, pageChanged)
  }

  onQueryChange = async (pageChanged = false) => {
    this.setState({ loading: true, pageChanged })

    await this.getDataWithQueries(pageChanged)

    this.setState({ loading: false })
  }

  switchView(view) {
    if (view === this.state.view) return

    this.setState({ view })

    if (view === 'grid') {
      this.onQueryChange()
    }
  }

  onFilter(value) {
    const { loading } = this.state

    if (loading) return

    this.setState({ filter: value, loadMore: false })
  }

  renderGridItems = items => {
    const {
      t,
      deleteWebsite,
      history: { push },
    } = this.props
    return items.map((item, key) => (
      <NotificationContext.Consumer key={key}>
        {context => (
          <WebsiteListItem
            item={item}
            context={[
              {
                name: t('default.edit'),
                method: id => push(`websites/edit/${id}`),
              },
              {
                name: t('default.designer'),
                method: id => push(`websites/designer/${id}`),
              },
              {
                name: t('websites.show_script_tag'),
                method: id =>
                  context.methods.openModal(() => (
                    <WebsiteShowScriptTagModal
                      closeModal={context.methods.closeModal}
                      scriptCode={`<script id="vertus_script" async src="${window.location.origin}/vertus.js?websiteId=${id}" type="text/javascript"></script>`}
                    />
                  )),
              },
              {
                name: t('default.delete'),
                method: () =>
                  context.methods.openModal(() => (
                    <ConfirmWebsiteDeleteModal
                      id={cleanId(item['@id'])}
                      deleteWebsite={deleteWebsite}
                      toggleTopNotification={
                        context.methods.toggleTopNotification
                      }
                      closeModal={context.methods.closeModal}
                      title={item.title}
                    />
                  )),
              },
            ]}
          />
        )}
      </NotificationContext.Consumer>
    ))
  }

  renderLoadMore(total, actual) {
    const { t } = this.props
    if (actual < total)
      return (
        <React.Fragment>
          <LoadMoreButton
            onClick={() => {
              this.onQueryChange(true)

              this.setState({ loadMore: true })
            }}
          >
            {t('default.load_more')}
          </LoadMoreButton>
          {this.state.loading && <Loader size="large" />}
        </React.Fragment>
      )
  }

  parseWebsiteTitle = title => {
    if (title.startsWith('http://')) {
      return title.substring(7)
    }
    if (title.startsWith('https://')) {
      return title.substring(8)
    }

    return title
  }

  renderGridView = () => (
    <Subscribe to={[WebsiteContainer]}>
      {({
        state: {
          websitesData,
          websitesLoading,
          websitesError,
          websitesMetaData,
        },
      }) => {
        if (websitesError) {
          return <FormError>{websitesError}</FormError>
        }

        return (
          <React.Fragment>
            <WebsiteListHeaderContainer>
              <Search
                columns={[]}
                onChange={value => this.onFilter(value)}
                defaultValue={this.state.filter}
              />
            </WebsiteListHeaderContainer>
            {websitesLoading && !this.state.pageChanged && (
              <CenteredLoaderContainer>
                <Loader size="large" />
              </CenteredLoaderContainer>
            )}
            {websitesData !== null &&
              (!websitesLoading || this.state.loadMore) && (
                <React.Fragment>
                  <WebsiteListContainer>
                    {this.renderGridItems(websitesData)}
                  </WebsiteListContainer>
                  <WebsiteListFooterContainer>
                    {this.renderLoadMore(
                      websitesMetaData['hydra:totalItems'],
                      websitesData.length
                    )}
                  </WebsiteListFooterContainer>
                </React.Fragment>
              )}
          </React.Fragment>
        )
      }}
    </Subscribe>
  )

  renderListView = () => {
    const {
      t,
      deleteWebsite,
      history: { push },
    } = this.props
    return (
      <Subscribe to={[WebsiteContainer]}>
        {({
          state: { websitesData },
          getWebsites,
          getWebsitesCanLoadMore,
          deleteWebsites,
        }) => (
          <StyledContentContainer>
            <NotificationContext.Consumer>
              {context => (
                <Table
                  getData={getWebsites}
                  data={websitesData}
                  idKey="@id"
                  showId={false}
                  mapRow={({
                    title,
                    createdAt,
                    updatedAt,
                    convertedContactsCount,
                    createdAnonymousContactsCount,
                    displayedAssetsCount,
                  }) => {
                    return [
                      this.parseWebsiteTitle(title),
                      displayedAssetsCount,
                      createdAnonymousContactsCount,
                      convertedContactsCount,
                      !createdAt ? '' : formatDate(createdAt),
                      !updatedAt ? '' : formatDate(updatedAt),
                    ]
                  }}
                  defaultFilter={this.state.filter}
                  headers={[
                    {
                      label: t('content_assets.table_header_title'),
                      value: 'order[title]',
                      sortable: true,
                    },
                    {
                      label: t('websites.displayed_assets'),
                      value: 'order[displayedAssetsCount]',
                      sortable: true,
                    },
                    {
                      label: t('websites.anonymous_users'),
                      value: 'order[createdAnonymousContactsCount]',
                      sortable: true,
                    },
                    {
                      label: t('websites.converted_users'),
                      value: 'order[convertedContactsCount]',
                      sortable: true,
                    },
                    {
                      label: t('default.created_at'),
                      value: 'order[createdAt]',
                      sortable: true,
                    },
                    {
                      label: t('default.updated_at'),
                      value: 'order[updatedAt]',
                      sortable: true,
                    },
                  ]}
                  sortabledId
                  filter="title"
                  singleOps={[
                    {
                      label: t('default.edit'),
                      handler: id => push(`websites/edit/${cleanId(id)}`),
                    },
                    {
                      label: t('default.designer'),
                      handler: id => push(`websites/designer/${cleanId(id)}`),
                    },
                    {
                      label: t('websites.show_script_tag'),
                      handler: id =>
                        context.methods.openModal(() => (
                          <WebsiteShowScriptTagModal
                            closeModal={context.methods.closeModal}
                            scriptCode={`<script id="vertus_script" async src="${window.location.origin}/vertus.js?websiteId=${id}" type="text/javascript"></script>`}
                          />
                        )),
                    },
                    {
                      label: t('default.delete'),
                      handler: (id, item) =>
                        context.methods.openModal(() => (
                          <ConfirmWebsiteDeleteModal
                            id={cleanId(id)}
                            deleteWebsite={deleteWebsite}
                            toggleTopNotification={
                              context.methods.toggleTopNotification
                            }
                            closeModal={context.methods.closeModal}
                            title={item.title}
                          />
                        )),
                    },
                  ]}
                  bulkOps={[
                    {
                      label: t('default.delete_selected'),
                      handler: deleteWebsites,
                      confirmModal: true,
                    },
                  ]}
                  canLoadMore={getWebsitesCanLoadMore()}
                  onFilter={value => this.setState({ filter: value })}
                  contextMenuLeftOriented
                />
              )}
            </NotificationContext.Consumer>
          </StyledContentContainer>
        )}
      </Subscribe>
    )
  }

  render() {
    const { t } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('websites.title')}</H1>
          <HeaderButtonContainer>
            <Button
              bold
              onClick={e => this.props.history.push('/websites/new')}
            >
              + {t('websites.create_website_button')}
            </Button>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('websites.sub_header_description')}</Paragraph>
        </SubHeaderContainer>
        <SwitchContainer>
          <GridTableSwitch
            onSwitch={view => this.switchView(view)}
            active={this.state.view}
          />
        </SwitchContainer>
        {this.state.view === 'grid'
          ? this.renderGridView()
          : this.renderListView()}
      </LoggedInScreenContainer>
    )
  }
}

const WebsiteListScreenWrapper = props => (
  <Subscribe to={[WebsiteContainer]}>
    {({ getWebsites, deleteWebsite }) => (
      <WebsiteListScreen
        {...props}
        getWebsites={getWebsites}
        deleteWebsite={deleteWebsite}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(WebsiteListScreenWrapper)
