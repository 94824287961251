import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'

import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import ContentAssetForm from 'functional/ContentAssetForm'
import ContentAssetContainer from 'containers/ContentAssetContainer'

import { withHandlers } from 'util/form'

const NewContentAssetScreen = ({ history: { push } }) => {
  return (
    <LoggedInScreenContainer>
      <Subscribe to={[ContentAssetContainer]}>
        {({ createContentAsset }) => (
          <ContentAssetForm
            onSubmit={withHandlers(createContentAsset, () => push('/assets'))}
          />
        )}
      </Subscribe>
    </LoggedInScreenContainer>
  )
}

NewContentAssetScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

export default NewContentAssetScreen
