import { Container } from 'unstated'
import qs from 'qs'
import saveAs from 'file-saver'

import { apiClient, cleanId, base64ToBlob } from 'util/api'
import { handleFailure } from 'util/form'

export default class ContentAssetContainer extends Container {
  state = {
    data: null,
    metaData: null,
    details: null,

    loading: false,
    error: null,

    createLoading: false,
    createError: null,

    getLoading: false,
    getError: null,

    updateLoading: false,
    updateError: false,

    cloneLoading: false,
    cloneError: false,

    deleteLoading: false,
    deleteError: null,

    deleteContentAssetsLoading: false,
    deleteContentAssetsError: null,

    getAnalyticsData: null,
    getAnalyticsLoading: false,
    getAnalyticsError: null,
  }

  getContentAssets = async (params, pageChanged) => {
    try {
      await this.setState({ loading: true, error: null })

      let page

      if (pageChanged && this.state.metaData) {
        const { 'hydra:next': nextPage } = this.state.metaData['hydra:view']
        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ loading: false })
          return
        }
      }

      const res = await apiClient.get(
        `content_assets?${qs.stringify({ ...params, page }, { encode: false })}`
      )
      const data = res.data['hydra:member']

      await this.setState({
        loading: false,
        data: pageChanged ? [...this.state.data, ...data] : data,
        metaData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        loading: false,
        error: handleFailure(e, true),
      })

      throw e
    }
  }

  createContentAsset = async data => {
    try {
      await this.setState({
        createLoading: true,
        createError: null,
      })

      await apiClient.post('content_assets', {
        ...data,
        displaysCount: 0,
        convertedContactsCount: 0,
      })

      await this.setState({ createLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        createLoading: false,
        createError: handleFailure(e, true),
      })

      throw e
    }
  }

  getContentAsset = async id => {
    try {
      await this.setState({
        details: null,
        getLoading: true,
        getError: null,
      })

      const res = await apiClient.get(`content_assets/${id}`)

      await this.setState({
        getLoading: false,
        details: { ...res.data },
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getLoading: false,
        getError: handleFailure(e, true),
      })

      throw e
    }
  }

  getContentAssetFile = async id => {
    try {
      const res = await apiClient.get(`content_assets/${id}/file`)

      saveAs(
        base64ToBlob(res.data.base64, res.data.mimetype),
        res.data.fileName
      )
    } catch (e) {
      console.warn(e)

      throw e
    }
  }

  updateContentAsset = async data => {
    try {
      await this.setState({
        updateLoading: true,
        updateError: null,
      })

      const { '@id': id, ...restData } = data
      await apiClient.put(`content_assets/${cleanId(id)}`, restData)

      await this.setState({ updateLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        updateLoading: false,
        updateError: handleFailure(e, true),
      })

      throw e
    }
  }

  cloneContentAsset = async id => {
    try {
      await this.setState({
        cloneLoading: true,
        cloneError: null,
      })

      await apiClient.post(`content_assets/${id}/copy_offer`)

      this.getContentAssets()

      await this.setState({ cloneLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        cloneLoading: false,
        cloneError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteContentAsset = async id => {
    try {
      await this.setState({
        deleteLoading: true,
        deleteError: null,
      })

      await apiClient.delete(`content_assets/${id}`)

      this.getContentAssets()

      await this.setState({ deleteLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        deleteLoading: false,
        deleteError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteContentAssets = async ids => {
    try {
      await this.setState({
        deleteContentAssetsLoading: true,
        deleteContentAssetsError: null,
      })

      const query = qs.stringify(
        { ids: ids.map(id => cleanId(id)) },
        { encode: false }
      )

      await apiClient.delete(`content_assets/bulk_delete?${query}`)

      await this.setState({ deleteContentAssetsLoading: false })
    } catch (e) {
      console.warn('in deleteContentAssets', e)

      this.setState({
        deleteContentAssetsLoading: false,
        deleteContentAssetsError: handleFailure(e, true),
      })

      throw e
    }
  }

  getContentAssetsCanLoadMore = () => {
    if (!this.state.metaData || !this.state.metaData['hydra:view']) return false

    return !!this.state.metaData['hydra:view']['hydra:next']
  }

  getContentAssetAnalytics = async (
    timespan = 'today',
    website = undefined,
    subSiteId = undefined
  ) => {
    try {
      await this.setState({
        getAnalyticsLoading: true,
        getAnalyticsError: null,
      })

      const params = { timespan }

      if (website !== undefined) {
        params.website = website
      }

      if (subSiteId != undefined) {
        params.subSite = subSiteId
      }

      const res = await apiClient.get(
        `analytics/top_assets?${qs.stringify(params, {
          encode: false,
        })}`
      )

      await this.setState({
        getAnalyticsLoading: false,
        getAnalyticsData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getAnalyticsLoading: false,
        getAnalyticsError: handleFailure(e, true),
      })

      throw e
    }
  }
}
