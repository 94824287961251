import React from 'react'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import Table from 'functional/Table'

import BlacklistContainer from 'containers/BlacklistContainer'
import { formatDate } from 'util/date'

/**
 * @version 1.0.0
 * @description Table that requests all blacklisted emails from the API and displays it.
 */
const BlacklistTable = () => {
  const { t, i18n } = useTranslation('common')
  return (
    <Subscribe to={[BlacklistContainer]}>
      {({
        state: { data },
        getBlacklist,
        getBlacklistCanLoadMore,
        deleteOne,
      }) => {
        return (
          <Table
            getData={getBlacklist}
            data={data}
            idKey="@id"
            mapRow={({ emailPlain, createdAt }) => [
              emailPlain,
              formatDate(createdAt),
            ]}
            filter="emailPlain"
            headers={[
              {
                label: t('default.email'),
                value: 'order[emailPlain]',
                sortable: true,
              },
              {
                label: t('default.created_at'),
                value: 'order[createdAt]',
                sortable: true,
              },
            ]}
            singleOps={[{ label: t('default.delete'), handler: deleteOne }]}
            canLoadMore={getBlacklistCanLoadMore()}
            contextMenuBinIcon
          />
        )
      }}
    </Subscribe>
  )
}

/** @component */
export default BlacklistTable
