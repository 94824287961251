import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Checkmark from 'react-icons/lib/md/check'

import AuthLogoContainer from 'presentational/AuthLogoContainer'
import AuthLayout from 'presentational/AuthLayout'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'
import H3 from 'presentational/H3'
import Logo from 'presentational/Logo'

const Icon = styled(Checkmark)``

const ColoredH3 = styled(H3)`
  color: #fff;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
`

const IconContainer = styled.div`
  flex: 1;
  padding-right: 25px;
`

/**
 * A screen that comes after successful registration.
 * @author Stjepan Golemac
 */
const RegistrationSuccessScreen = () => {
  const { t } = useTranslation('common')

  return (
    <AuthLayout>
      <AuthLogoContainer>
        <Logo width={'220px'} />
      </AuthLogoContainer>
      <Paragraph>{t('default.slogan')}</Paragraph>
      <FlexContainer>
        <IconContainer>
          <Icon size={64} color={'#d36841'} />
        </IconContainer>
        <ColoredH3>{t('login_register.create_account_success')}</ColoredH3>
      </FlexContainer>
      <Link to="/">
        <Button size="large">{t('login_register.go_to_homepage')}</Button>
      </Link>
    </AuthLayout>
  )
}

/** @component */
export default RegistrationSuccessScreen
