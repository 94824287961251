import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

export default class LabelsContainer extends Container {
  state = {
    data: null,
    metaData: null,
    contactLabels: null,
    details: null,

    createLoading: false,
    createError: null,

    getLoading: false,
    getError: null,

    updateLoading: false,
    updateError: null,

    getAllLoading: false,
    getAllError: null,

    getContactLabelsLoading: false,
    getContactLabelsError: null,

    deleteOneLoading: false,
    deleteOneError: null,

    bulkDeleteLoading: false,
    bulkDeleteError: null,

    addContactLoading: false,
    addContactError: null,

    removeContactLabelLoading: false,
    removeContactLabelError: null,
  }

  getContactLabels = async id => {
    try {
      await this.setState({
        getContactLabelsLoading: true,
        getContactLabelsError: null,
      })

      const res = await apiClient.get(`contacts/${cleanId(id)}/labels`)

      await this.setState({
        getContactLabelsLoading: false,
        getContactLabelsError: null,
        contactLabels: res.data,
      })
    } catch (e) {
      console.warn(e)

      throw e
    }
  }

  getLabel = async id => {
    try {
      await this.setState({ details: null, getLoading: true, getError: null })

      const res = await apiClient.get(`labels/${cleanId(id)}`)

      await this.setState({ getLoading: false, details: res.data })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getLoading: false,
        getError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateLabel = async data => {
    try {
      await this.setState({ updateLoading: true, updateError: null })

      const { '@id': id, ...restData } = data
      await apiClient.put(`labels/${cleanId(id)}`, restData)

      await this.setState({ updateLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        updateLoading: false,
        updateError: handleFailure(e, true),
      })

      throw e
    }
  }

  addLabelToContact = async data => {
    try {
      await this.setState({
        addLabelToContactLoading: true,
        addLabelToContactError: null,
      })

      await apiClient.post(`contact_labels`, {
        contact: `/v1/contacts/${data.id}`,
        label: data.label['@id'],
      })

      this.getContactLabels(data.id)

      await this.setState({
        addLabelToContactLoading: false,
        addLabelToContactError: null,
      })
    } catch (e) {
      console.warn(e)

      throw e
    }
  }

  removeLabelFromContact = async id => {
    try {
      await this.setState({
        removeContactLabelLoading: true,
        removeContactLabelError: null,
      })

      await apiClient.delete(`contact_labels/${id}`)

      this.getContactLabels(data.id)

      await this.setState({
        removeContactLabelLoading: false,
        removeContactLabelError: null,
      })
    } catch (e) {
      console.warn(e)

      throw e
    }
  }

  createLabel = async data => {
    try {
      await this.setState({ createLoading: true, createError: null })

      if (!data.color) data.color = '#000000'

      await apiClient.post('labels', data)

      await this.setState({ createLoading: false, createError: null })
    } catch (e) {
      console.warn(e)

      await this.setState({
        createLoading: false,
        createError: handleFailure(e, true),
      })

      throw e
    }
  }

  getAllLabels = async (params, pageChanged) => {
    try {
      await this.setState({ getAllLoading: true, getAllError: null })

      let page

      if (pageChanged) {
        if (!this.state.metaData || !this.state.metaData['hydra:view']) {
          await this.setState({ getAllLoading: false })
          return
        }

        const { 'hydra:next': nextPage } = this.state.metaData['hydra:view']

        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ getAllLoading: false })
          return
        }
      }

      const res = await apiClient.get(`labels?pagination=false`)

      const data = res.data['hydra:member']

      await this.setState({
        getAllLoading: false,
        data: pageChanged ? [...this.state.data, ...data] : data,
        metaData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getAllLoading: false,
        getAllError: handleFailure(e, true),
      })

      throw e
    }
  }

  getAllLabelsCanLoadMore = () => {
    if (!this.state.metaData || !this.state.metaData['hydra:view']) return false

    return !!this.state.metaData['hydra:view']['hydra:next']
  }

  deleteLabels = async ids => {
    try {
      this.setState({ bulkDeleteLoading: true, bulkDeleteError: null })

      var string = ids.map(id => cleanId(id)).join('&ids[]=')

      await apiClient.delete(`/labels/bulk_delete?ids[]=${string}`)

      this.getAllLabels()

      this.setState({ bulkDeleteLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        bulkDeleteLoading: false,
        bulkDeleteError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteLabel = async id => {
    try {
      if (!id) throw 'label id must be passed'

      this.setState({ deleteOneLoading: true, deleteOneError: null })

      await apiClient.delete(`labels/${cleanId(id)}`)

      this.setState({ deleteOneLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        deleteOneLoading: false,
        deleteOneError: handleFailure(e, true),
      })

      throw e
    }
  }
}
