import { Container } from 'unstated'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

class TemplatesContainer extends Container {
  state = {
    templatesLoading: false,
    templatesError: null,
    templateEmailData: null,
    templateBannerData: null,
    templateOverlayData: null,
    templatesMetaData: null,

    templateLoading: false,
    templateData: null,
    templateError: null,

    updateTemplateLoading: false,
    updateTemplateError: null,
  }

  getTemplatesByWebsite = async websiteId => {
    try {
      await this.setState({
        templatesLoading: true,
        templatesError: null,
      })

      const res = await apiClient.get(`websites/${websiteId}/templates`)
      const data = res.data['hydra:member']

      await this.setState({
        templatesLoading: false,
        templatesMetaData: res.data,
        templateEmailData: data.find(template => template.type === 'email'),
        templateOverlayData: data.find(template => template.type === 'overlay'),
        templateBannerData: data.find(template => template.type === 'banner'),
      })
    } catch (e) {
      console.warn('in getTemplatesByWebsite', e)

      await this.setState({
        templatesLoading: false,
        templatesError: true,
      })

      throw e
    }
  }

  getTemplate = async id => {
    try {
      await this.setState({ templateLoading: true, templateError: null })

      const res = await apiClient.get(`templates/${id}`)

      await this.setState({ templateLoading: false, templateData: res.data })
    } catch (e) {
      console.warn('in getTemplate', e)

      await this.setState({
        templateLoading: false,
        templateError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateTemplate = async (data, id) => {
    try {
      await this.setState({
        updateTemplateLoading: true,
        updateTemplateError: null,
      })

      await apiClient.put(`templates/${id}`, { ...data })

      this.getTemplate(id)

      await this.setState({ updateTemplateLoading: false })
    } catch (e) {
      console.warn('in updateTemplateData', e)

      await this.setState({
        updateTemplateLoading: false,
        updateTemplateError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteTemplate = async id => {
    try {
      await this.setState({
        deleteTemplateLoading: true,
        deleteTemplateError: null,
      })

      await apiClient.delete(`templates/${id}`)

      this.getTemplates()

      await this.setState({ deleteTemplateLoading: false })
    } catch (e) {
      console.warn('in deleteTemplateData', e)

      await this.setState({
        deleteTemplateLoading: false,
        deleteTemplateError: handleFailure(e, true),
      })

      throw e
    }
  }
}

export default TemplatesContainer
