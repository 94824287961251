import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import ArrowDownIcon from 'react-icons/lib/md/keyboard-arrow-down'
import ArrowUpIcon from 'react-icons/lib/md/keyboard-arrow-up'

const ItemTitle = styled.p`
  font-weight: medium;
  font-size: 22px;
  color: #554c47;
  margin: 8px 10px;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  :hover {
    color: #a0b6cb;
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  :hover ${ItemTitle} {
    color: #a0b6cb;
  }
`

const StyledArrowUpIcon = styled(ArrowUpIcon)`
  color: #f1efed;
  font-size: 22px;
  margin-right: 10px;

  ${ItemContainer}:hover & {
    color: #a0b6cb;
  }
`

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  color: #f1efed;
  font-size: 22px;
  margin-right: 10px;

  ${ItemContainer}:hover & {
    color: #a0b6cb;
  }
`

const Submenu = styled.div`
  margin-left: 10px;
  border-left: 3px solid #a0b6cb;
  border-radius: 1px;
  padding: 0;
  max-height: ${props => (props.shown ? '500px' : '0px')};
  overflow: ${props => (props.shown ? 'visible' : 'hidden')};
  transition: max-height 300ms ease;
`

const SubmenuItem = styled.p`
  padding: 5px 10px;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  color: ${props => (props.active ? '#a0b6cb' : 'black')};

  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`

const CollapsibleNavigationList = ({
  items,
  onChangeActiveIndex,
  defaultActiveSubMenu,
  defaultActiceItem,
}) => {
  const [openMenus, setOpenMenus] = useState([defaultActiveSubMenu])
  const [activeIndex, setActiveIndex] = useState(
    `${defaultActiveSubMenu}.${defaultActiceItem}`
  )

  const changeActiveIndex = (subMenuIndex, itemIndex) => {
    setActiveIndex(`${subMenuIndex}.${itemIndex}`)

    onChangeActiveIndex(subMenuIndex, itemIndex)
  }

  const changeMenuState = index => {
    const newOpenMenus = [...openMenus]
    if (openMenus.includes(index)) {
      newOpenMenus.splice(newOpenMenus.indexOf(index), 1)
    } else {
      newOpenMenus.push(index)
    }

    setOpenMenus(newOpenMenus)
  }

  const buildSubmenuItem = (title, itemIndex, subMenuIndex) => (
    <SubmenuItem
      key={itemIndex}
      active={`${subMenuIndex}.${itemIndex}` === activeIndex}
      onClick={() => changeActiveIndex(subMenuIndex, itemIndex)}
    >
      {title}
    </SubmenuItem>
  )

  const buildItem = (item, subMenuIndex) => {
    const isOpen = openMenus.includes(subMenuIndex)

    return (
      <div key={subMenuIndex}>
        <ItemContainer onClick={() => changeMenuState(subMenuIndex)}>
          <ItemTitle>{item.title}</ItemTitle>
          {isOpen ? <StyledArrowDownIcon /> : <StyledArrowUpIcon />}
        </ItemContainer>
        <Submenu shown={isOpen}>
          {item.items.map((subItem, itemIndex) =>
            buildSubmenuItem(subItem.title, itemIndex, subMenuIndex)
          )}
        </Submenu>
      </div>
    )
  }

  const buildList = () => items.map((item, key) => buildItem(item, key))

  return <div>{buildList()}</div>
}

CollapsibleNavigationList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
        })
      ),
    })
  ),
  onChangeActiveIndex: PropTypes.func,
}

CollapsibleNavigationList.defaultProps = {
  items: [],
  onChangeActiveIndex: () => {},
}

export default CollapsibleNavigationList
