import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import SidebarLayout from 'presentational/SidebarLayout'
import Sidebar from 'presentational/Sidebar'
import EditProfileScreen from 'functional/EditProfileScreen'
import EditContactScreen from 'functional/EditContactScreen'
import ContactListScreen from 'functional/ContactListScreen'
import ContactBlackListScreen from 'functional/ContactBlackListScreen'
import LabelListScreen from 'functional/LabelListScreen'
import DesignerScreen from 'functional/DesignerScreen'
import DashboardScreen from 'functional/DashboardScreen'
import ContentAssetScreen from 'functional/ContentAssetScreen'
import WebsiteListScreen from 'functional/WebsiteListScreen'
import NewWebsiteScreen from 'functional/NewWebsiteScreen'
import EditWebsiteScreen from 'functional/EditWebsiteScreen'
import NewContentAssetScreen from 'functional/NewContentAssetScreen'
import EditContentAssetScreen from 'functional/EditContentAssetScreen'
import HelpScreen from 'functional/HelpScreen'
import HelpFAQScreen from 'functional/HelpFAQScreen'
import HelpContactScreen from 'functional/HelpContactScreen'
import HelpDocumentationScreen from 'functional/HelpDocumentationScreen'
import SettingsScreen from 'functional/SettingsScreen'
import LicenseScreen from 'functional/LicenseScreen'
import APIScreen from 'functional/APIScreen'

/*
import AddNewContactScreen from 'functional/AddNewContactScreen'
import ContactOverviewScreen from 'functional/ContactOverviewScreen'
*/

import NotificationProvider from 'functional/NotificationProvider'
import OfflineIndicatorProvider from 'functional/OfflineIndicatorProvider'

import { getTokenSync } from 'util'

import * as userItems from './user-navigation-targets.json'

const isLoggedIn = () => getTokenSync()

const LoggedInUserSubroutes = ({ history, match: { url } }) => {
  if (!isLoggedIn()) history.push('/login')

  //inactivity logout
  if (isLoggedIn()) {
    const inactivityTimeout = 20 * 60 * 1000 //in milisecounds

    function activityWatcher() {
      let lastActivityTime = new Date().getTime()

      setInterval(function() {
        //if the user has been inactive or idle for longer
        //then the seconds specified in inactivityTimeout
        if (new Date().getTime() - lastActivityTime > inactivityTimeout) {
          console.log(
            'User has been inactive for more than ' +
              inactivityTimeout +
              ' seconds'
          )

          history.push('/logout')
        }
      }, 1000)

      //The function that will be called whenever a user is active
      function activity() {
        lastActivityTime = new Date().getTime()
      }

      //An array of DOM events that should be interpreted as
      //user activity.
      var activityEvents = [
        'mousedown',
        'mousemove',
        'keydown',
        'scroll',
        'touchstart',
      ]

      //add these events to the document.
      //register the activity function as the listener parameter.
      activityEvents.forEach(function(eventName) {
        document.addEventListener(eventName, activity, true)
      })
    }

    activityWatcher()
  }

  if (history.location.pathname === '/') history.push('/dashboard')
  return (
    <SidebarLayout sidebarSlot={() => <Sidebar targets={userItems} />}>
      <OfflineIndicatorProvider>
        <NotificationProvider isLoggedIn>
          <Route exact path={`${url}settings`} component={SettingsScreen} />
          <Route
            exact
            path={`${url}settings/profile`}
            component={EditProfileScreen}
          />
          <Route
            exact
            path={`${url}settings/license`}
            component={LicenseScreen}
          />
          <Route exact path={`${url}settings/api`} component={APIScreen} />
          <Route exact path={`${url}contacts`} component={ContactListScreen} />
          <Route
            exact
            path={`${url}contacts/edit/:id`}
            component={EditContactScreen}
          />
          <Route
            exact
            path={`${url}contacts/blacklisted`}
            component={ContactBlackListScreen}
          />
          <Route
            exact
            path={`${url}settings/tags`}
            component={LabelListScreen}
          />
          <Route exact path={`${url}websites`} component={WebsiteListScreen} />
          <Route
            exact
            path={`${url}websites/new`}
            component={NewWebsiteScreen}
          />
          <Route
            exact
            path={`${url}websites/edit/:id`}
            component={EditWebsiteScreen}
          />
          <Route
            exact
            path={`${url}websites/designer/:id`}
            component={DesignerScreen}
          />
          <Route exact path={`${url}dashboard`} component={DashboardScreen} />
          <Route exact path={`${url}assets`} component={ContentAssetScreen} />
          <Route
            exact
            path={`${url}assets/new`}
            component={NewContentAssetScreen}
          />
          <Route
            exact
            path={`${url}assets/edit/:id`}
            component={EditContentAssetScreen}
          />
          <Route exact path={`${url}support/help`} component={HelpScreen} />
          <Route
            exact
            path={`${url}support/help/faq`}
            component={HelpFAQScreen}
          />
          <Route
            exact
            path={`${url}support/help/contact`}
            component={HelpContactScreen}
          />
          <Route
            exact
            path={`${url}support/help/documentation`}
            component={HelpDocumentationScreen}
          />
        </NotificationProvider>
      </OfflineIndicatorProvider>
    </SidebarLayout>
  )
}

LoggedInUserSubroutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default LoggedInUserSubroutes
