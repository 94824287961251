import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import HorizontalMenu from 'presentational/HorizontalMenu'

const StyledH3 = styled(H3)`
  margin-top: 0;
`

const WidgetCard = styled.div`
  background-color: white;
  margin-right: 20px;
  padding: 40px 30px;
  margin-bottom: 20px;
  flex: 1;
  flex-basis: 400px;
`

const InformationWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: baseline;
`

const MainNumber = styled.p`
  font-size: 50px;
  margin: 0;
  color: #554c47;
`

const MainDescription = styled.p`
  font-size: 1.5em;
  color: #554c47;
  margin: 0 10px;
`

const DashboardKPIWidget = ({
  title,
  description,
  mainNumber,
  mainNumberDescription,
  secondString,
  minuteString,
  time,
  onChangeTab,
  activeTab,
}) => {
  const { t, i18n } = useTranslation('common')

  let minutes
  let seconds

  if (time !== 0 && time !== undefined) {
    seconds = Math.floor((time / 1000) % 60)
    minutes = Math.floor((time / (1000 * 60)) % 60)
  }

  if (mainNumber === undefined && seconds === undefined) {
    seconds = 0
  }

  return (
    <WidgetCard>
      <StyledH3>{title}</StyledH3>
      <Paragraph>{description}</Paragraph>
      <div>
        <HorizontalMenu
          kind="mediumsmall"
          onChange={changedTab => {
            const type = ['today', 'week', 'month', 'total']
            onChangeTab(type[changedTab], changedTab)
          }}
          activeTab={activeTab}
          underlineBackground
          tabs={[
            { name: t('dashboard.today') },
            { name: t('dashboard.week') },
            { name: t('dashboard.month') },
            { name: t('dashboard.total') },
          ]}
        />
      </div>
      <InformationWrapper>
        {mainNumber !== undefined && (
          <React.Fragment>
            <MainNumber>{mainNumber}</MainNumber>
            <MainDescription>{mainNumberDescription}</MainDescription>
          </React.Fragment>
        )}
        {minutes !== undefined && minutes !== 0 && (
          <React.Fragment>
            <MainNumber>{minutes}</MainNumber>
            <MainDescription>{minuteString}</MainDescription>
          </React.Fragment>
        )}
        {seconds !== undefined && (
          <React.Fragment>
            <MainNumber>{seconds}</MainNumber>
            <MainDescription>{secondString}</MainDescription>
          </React.Fragment>
        )}
      </InformationWrapper>
    </WidgetCard>
  )
}

DashboardKPIWidget.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  mainNumber: PropTypes.number,
  mainNumberDescription: PropTypes.string,
  time: PropTypes.number,
}

DashboardKPIWidget.defaultProps = {
  title: '',
  description: '',
  mainNumberDescription: '',
  secondaryNumber: [],
}

export default DashboardKPIWidget
