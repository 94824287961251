import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
  height: 300px;
  width: 300px;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  // transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  // :hover {
  //   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  // }
`

const Title = styled.p`
  font-size: 30px;
  color: black;
  margin: 12px 0;
  text-align: center;
`

const Description = styled.p`
  text-align: center;
  max-height: 85px;
  color: #554c47;
  font-size: 14px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

const iconStyle = {
  fontSize: '60px',
}

const CardButton = ({ title, description, icon, onClick }) => {
  const iconWithStyle = React.cloneElement(icon, { style: iconStyle })

  return (
    <CardContainer onClick={onClick}>
      <IconContainer>{iconWithStyle}</IconContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </CardContainer>
  )
}

export default CardButton
