import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
} from 'presentational/ScreenLayoutContainers'

const WrapButton = styled(Button)`
  width: auto;
  margin-right: 5px;
`
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const SettingsScreen = () => {
  const { t, i18n } = useTranslation('common')

  return (
    <LoggedInScreenContainer>
      <HeaderContainer>
        <H1>{t('settings.title')}</H1>
      </HeaderContainer>
      <SubHeaderContainer>
        <Paragraph>{t('settings.sub_header_description')}</Paragraph>
      </SubHeaderContainer>
      <SectionContainer>
        <Section>
          <H2>{t('settings.tags_title')}</H2>
          <Paragraph>{t('settings.tags_description')}</Paragraph>
          <NavLink to="/settings/tags">
            <WrapButton>{t('settings.tags_button')}</WrapButton>
          </NavLink>
        </Section>
        <Section>
          <H2>{t('settings.api_title')}</H2>
          <Paragraph>{t('settings.api_description')}</Paragraph>
          <NavLink to="/settings/api">
            <WrapButton>{t('settings.api_button')}</WrapButton>
          </NavLink>
        </Section>
        <Section>
          <H2>{t('settings.license_title')}</H2>
          <Paragraph>{t('settings.license_description')}</Paragraph>
          <NavLink to="/settings/license">
            <WrapButton>{t('settings.license_button')}</WrapButton>
          </NavLink>
        </Section>
      </SectionContainer>
    </LoggedInScreenContainer>
  )
}

export default SettingsScreen
