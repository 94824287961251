import React, { useState, useEffect, Fragment } from 'react'
import { FORM_ERROR } from 'final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { Form, Field, FormSpy } from 'react-final-form'
import { withTranslation, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import TimeIcon from 'react-icons/lib/md/access-time'
import CheckIcon from 'react-icons/lib/md/check-circle'
import ErrorIcon from 'react-icons/lib/md/error'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import FormError from 'presentational/FormError'
import Paragraph from 'presentational/Paragraph'
import TextInput from 'presentational/TextInput'
import CheckboxInput from 'presentational/CheckboxInput'
import DangerButton from 'presentational/DangerButton'
import SubSitesList from 'presentational/SubSitesList'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import FormInlineWrapper from 'presentational/FormInlineWrapper'

import FileInput from 'functional/FileInput'
import ColorPickerSection from 'functional/ColorPickerSection'
import { NotificationContext } from 'functional/NotificationProvider'
import ResetTrackingDataModal from 'functional/ResetTrackingDataModal'
import ConfirmWebsiteDeleteModal from 'functional/ConfirmWebsiteDeleteModal'

import {
  HeaderContainer,
  SubHeaderContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'

import { cleanId } from 'util/api'

import { withHandlers } from 'util/form'
import { isEmpty, isURLWithProtocol, isEmail } from 'util/validations'

import { formatDate } from 'util/date'

import WebsiteContainer, { WebsiteStore } from 'containers/WebsiteContainer'
import AppSettingsContainer from 'containers/AppSettingsContainer'

const LoaderRightMargin = styled(Loader)`
  margin-right: 10px;
`

const ParagraphMarginBottom = styled(Paragraph)`
  margin-bottom: 32px;
`

const WrapButton = styled(Button)`
  width: auto;
  margin-right: 5px;
`

const WrapDangerButton = styled(DangerButton)`
  width: auto;
  margin-right: 5px;
  height: 38px;
  line-height: 0px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const FlexFormFieldWrapper = styled(FormFieldWrapper)`
  display: flex;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const GeneratedTimestamp = styled(Paragraph)`
  margin-top: 20px;
`

const StyledTimeIcon = styled(TimeIcon)`
  margin-right: 5px;
`

const AutoWithButton = styled(Button)`
  width: auto;
  margin-right: 10px;
`

const IndividualSubsiteButton = styled(Button)`
  max-width: 150px;
`

const AlertParagraph = styled(Paragraph)`
  color: red;
  font-size: 15px;
`

// const CrawlingContainer = styled.div`
//   display: flex;
// `

const ParsingContainer = styled.div`
  display: flex;
`

const Code = styled.p`
  border: none;
  padding: 5px;
  text-align: center;
  width: 350px;
  background-color: #fafafa;
  color: #554c47;
  font-family: monospace;
  font-style: italic;
`

const InvalidSPFIcon = styled(ErrorIcon)`
  margin-right: 5px;
  height: 20px;
  width: 20px;
`

const ValidSPFIcon = styled(CheckIcon)`
  margin-right: 5px;
  height: 20px;
  width: 20px;
`

const InvalidSPF = styled.p`
  color: #d36841;
  display: flex;
  align-items: center;
  font-size: 14px;
`

const ValidSPF = styled.p`
  color: #4a90e2;
  display: flex;
  align-items: center;
  font-size: 14px;
`

const SPFSection = ({
  t,
  id,
  checkSPF,
  mailAddress,
  initalSPFValid,
  lastSPFCheckdAt,
  mailServerDomain,
  getMailServerDomain,
  required,
}) => {
  const [initialMailAddress] = useState(mailAddress)
  const [isSPFValid, setIsSPFValid] = useState(initalSPFValid)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getMailServerDomain()
  }, [])

  const handleCheckClick = () => {
    setIsLoading(true)

    const requestData = { mailAddress }

    if (id !== undefined) {
      requestData['websiteId'] = id
    }

    checkSPF(requestData)
      .then(data => {
        if (data.data !== undefined && data.data.valid !== undefined) {
          setIsSPFValid(data.data.valid)
        } else {
          setIsSPFValid(false)
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsSPFValid(false)
        setIsLoading(false)
      })
  }

  const isParseAllowed = () => {
    if (isEmpty(mailAddress) || isEmail(mailAddress) || isLoading) {
      return false
    }

    if (initialMailAddress !== mailAddress) return true

    // if (lastSPFCheckdAt !== null) {
    //   const minHoursBetweenCheck = 3

    //   const crawlDistance =
    //     new Date().getTime() - new Date(lastSPFCheckdAt).getTime()

    //   if (crawlDistance < minHoursBetweenCheck * 60 * 60 * 1000) {
    //     return false
    //   }
    // }
    return true
  }

  const getValidator = isRequired =>
    isRequired ? isEmpty && isEmail : () => {}

  return (
    <Section>
      <H2>{t('websites.edit_mail_header')}</H2>
      <Paragraph>{t('websites.edit_mail_description')}</Paragraph>
      <FormFieldWrapper>
        <Field
          name="mailAddress"
          component={TextInput}
          formatOnBlur
          type="email"
          label={t('websites.sender_address')}
          validate={getValidator(required)}
          key={required ? 1 : 0}
        />
      </FormFieldWrapper>
      <Paragraph>{t('websites.edit_mail_spf_description')}</Paragraph>
      <Code>v=spf1 include:{mailServerDomain} ~all</Code>
      {isSPFValid ? (
        <ValidSPF>
          <ValidSPFIcon /> {t('websites.edit_mail_spf_valid')}
        </ValidSPF>
      ) : (
        <InvalidSPF>
          <InvalidSPFIcon /> {t('websites.edit_mail_spf_invalid')}
        </InvalidSPF>
      )}
      {lastSPFCheckdAt !== undefined && lastSPFCheckdAt !== null && (
        <GeneratedTimestamp>
          <StyledTimeIcon size="20px" />
          {`${t('websites.last_checked')}: ${formatDate(
            lastSPFCheckdAt
          )} ${new Date(lastSPFCheckdAt).toLocaleTimeString()}`}
        </GeneratedTimestamp>
      )}
      {isLoading && <LoaderRightMargin />}
      <AutoWithButton onClick={handleCheckClick} disabled={!isParseAllowed()}>
        {t('websites.edit_mail_spf_button')}
      </AutoWithButton>
    </Section>
  )
}

const ParseSiteSection = ({
  t,
  id,
  initialLastParsedTime,
  initialCurrentlyParsing = false,
  initialParsingFailed = false,
  updateSubSites,
  onStartParsing,
  context,
  parseSitemapStatus,
  parseSitemap,
  statusCheckerRunning = false,
}) => {
  id = cleanId(id)

  const [parsingFailed, setParsingFailed] = useState(initialParsingFailed)

  const [currentlyParsing, setCurrentlyParsing] = useState(
    initialCurrentlyParsing
  )

  const [lastParsedTime, setLastParsedTime] = useState(initialLastParsedTime)
  const [foundSubsitesCount, setFoundSubsitesCount] = useState(null)
  const [taggedSubsitesCount, setTaggedSubsitesCount] = useState(0)

  let parsingStatus = initialCurrentlyParsing
  let initialParseAllowed = true

  const [parseAllowed, setParseAllowed] = useState(initialParseAllowed)

  let statusChecker

  const startStatusChecker = () => {
    statusChecker = setInterval(() => {
      const parseSitemapStatusRequest = parseSitemapStatus(id)

      if (parseSitemapStatusRequest != null) {
        parseSitemapStatusRequest.then(res => {
          if (res === undefined || res === null) {
            return
          }

          const { data } = res

          //if parsing process stopped
          if (!data.parsing) {
            clearInterval(statusChecker)
            setParsingFailed(data.failed)
            updateSubSites(data.subSites)
            setCurrentlyParsing(false)
            setTaggedSubsitesCount(0)
            setFoundSubsitesCount(null)
            setLastParsedTime(new Date())
            setParseAllowed(true)
            return
          }

          //if parsing process is still running
          setTaggedSubsitesCount(data.taggedSubsites)
          setFoundSubsitesCount(data.foundSubsites)
          setParseAllowed(false)
        })
      }
    }, 1000)
    return statusChecker
  }

  useEffect(() => {
    statusChecker = parsingStatus ? startStatusChecker() : 0

    return () => clearInterval(statusChecker)
  }, [])

  return (
    <div>
      {/* <H2>{t('websites.edit_parsing_header')}</H2>
      <Paragraph>{t('websites.edit_parsing_description')}</Paragraph> */}
      {currentlyParsing ? (
        <div>
          <ParsingContainer>
            <LoaderRightMargin />
            <Paragraph>{t('websites.edit_parsing_running')}</Paragraph>
          </ParsingContainer>
          {foundSubsitesCount !== null && (
            <div>
              <Paragraph>
                {t('websites.edit_parsing_found_sites_count', {
                  found: foundSubsitesCount,
                  tagged: taggedSubsitesCount,
                })}
              </Paragraph>
            </div>
          )}
        </div>
      ) : (
        <GeneratedTimestamp>
          <StyledTimeIcon size="20px" />
          {lastParsedTime !== undefined &&
            lastParsedTime !== null &&
            t('websites.edit_parsing_timestamp', {
              date:
                formatDate(lastParsedTime) +
                ' ' +
                new Date(lastParsedTime).toLocaleTimeString(),
            })}
          {(lastParsedTime === undefined || lastParsedTime === null) &&
            t('websites.edit_parsing_never_parsed')}
        </GeneratedTimestamp>
      )}
      {parsingFailed && (
        <AlertParagraph>{t('websites.parsing_error')}</AlertParagraph>
      )}
      <AutoWithButton
        disabled={!parseAllowed}
        onClick={withHandlers(
          () => {
            parseSitemap(id)
            onStartParsing()
            setCurrentlyParsing(true)
            startStatusChecker(id, parseSitemapStatus, context),
              (parsingStatus = true)
            setParseAllowed(false)
            context.methods.toggleTopNotification(
              'success',
              t('websites.edit_parsing_running')
            )
          },
          () => {},
          e => {
            console.log(e)
            let message = t('websites.edit_parsing_start_failed_notification')
            if (e[FORM_ERROR] === 'still_inside_parse_timeout') {
              message = t(
                'websites.edit_parsing_start_failed_parse_timeout_notification'
              )
            } else if (e[FORM_ERROR] === 'no_js_snippet') {
              message = t(
                'websites.edit_parsing_start_failed_no_ownership_notification'
              )
            }
            context.methods.toggleTopNotification('error', message)
          }
        )}
      >
        {t('websites.edit_start_parsing_button')}
      </AutoWithButton>
    </div>
  )
}

// const CrawlSiteSection = ({
//   t,
//   id,
//   initialLastCrawledTime,
//   initialCurrentlyCrawling = false,
//   initialCrawlingFailed = false,
//   updateSubSites,
//   onStartCrawling,
//   context,
//   crawlWebsiteStatus,
//   crawlWebsite,
//   statusCheckerRunning = false,
// }) => {
//   id = cleanId(id)

//   const [crawlingFailed, setCrawlingFailed] = useState(initialCrawlingFailed)

//   const [currentlyCrawling, setCurrentlyCrawling] = useState(
//     initialCurrentlyCrawling
//   )

//   const [lastCrawledTime, setLastCrawledTime] = useState(initialLastCrawledTime)
//   const [foundSubsitesCount, setFoundSubsitesCount] = useState(null)
//   const [taggedSubsitesCount, setTaggedSubsitesCount] = useState(0)

//   let crawlingStatus = initialCurrentlyCrawling
//   let initialCrawlAllowed = true

//   if (lastCrawledTime !== null) {
//     const minHoursBetweenCrawl = 3

//     const crawlDistance =
//       new Date().getTime() - new Date(lastCrawledTime).getTime()

//     if (crawlDistance < minHoursBetweenCrawl * 60 * 60 * 1000) {
//       initialCrawlAllowed = false
//     }
//   }

//   const [crawlAllowed, setCrawlAllowed] = useState(initialCrawlAllowed)

//   let statusChecker

//   const startStatusChecker = () => {
//     statusChecker = setInterval(() => {
//       const crawlWebsiteStatusRequest = crawlWebsiteStatus(id)

//       if (crawlWebsiteStatusRequest != null) {
//         crawlWebsiteStatusRequest.then(res => {
//           if (res === undefined || res === null) return

//           const { data } = res

//           // check if the crawling process is still running
//           if (!data.crawling) {
//             clearInterval(statusChecker)

//             // update the state
//             setCurrentlyCrawling(false)
//             setTaggedSubsitesCount(0)
//             setFoundSubsitesCount(null)
//             setLastCrawledTime(new Date())
//             setCrawlingFailed(data.failed)
//             updateSubSites(data.subSites)

//             // notify the user
//             context.methods.toggleTopNotification(
//               'success',
//               t('websites.edit_crawling_finish_notification')
//             )
//           } else {
//             // update the counts to the latest information
//             setTaggedSubsitesCount(data.taggedSubsites)
//             setFoundSubsitesCount(data.foundSubsites)
//           }
//         })
//       }
//     }, 5000)
//     return statusChecker
//   }

//   useEffect(() => {
//     statusChecker = crawlingStatus ? startStatusChecker() : 0

//     return () => clearInterval(statusChecker)
//   }, [])

//   return (
//     <Section>
//       <H2>{t('websites.edit_crawling_header')}</H2>
//       <Paragraph>{t('websites.edit_crawling_description')}</Paragraph>
//       {currentlyCrawling ? (
//         <div>
//           <CrawlingContainer>
//             <LoaderRightMargin />
//             <Paragraph>{t('websites.edit_crawling_running')}</Paragraph>
//           </CrawlingContainer>
//           {foundSubsitesCount !== null && (
//             <div>
//               <Paragraph>
//                 {t('websites.edit_crawling_found_sites_count', {
//                   found: foundSubsitesCount,
//                   tagged: taggedSubsitesCount,
//                 })}
//               </Paragraph>
//             </div>
//           )}
//         </div>
//       ) : (
//         <GeneratedTimestamp>
//           <StyledTimeIcon size="20px" />
//           {lastCrawledTime !== undefined &&
//             lastCrawledTime !== null &&
//             t('websites.edit_crawling_timestamp', {
//               date:
//                 formatDate(lastCrawledTime) +
//                 ' ' +
//                 new Date(lastCrawledTime).toLocaleTimeString(),
//             })}
//           {(lastCrawledTime === undefined || lastCrawledTime === null) &&
//             t('websites.edit_crawling_never_crawled')}
//         </GeneratedTimestamp>
//       )}
//       {!crawlAllowed && !currentlyCrawling && (
//         <AlertParagraph>{t('websites.edit_crawling_timelimit')}</AlertParagraph>
//       )}
//       {crawlingFailed && (
//         <AlertParagraph>
//           Das Crawling für die angegebene URL ist fehlgeschlagen, da die Header
//           der Zielseite nicht valide sind.
//         </AlertParagraph>
//       )}
//       <AutoWithButton
//         disabled={!crawlAllowed}
//         onClick={withHandlers(
//           () => crawlWebsite(id),
//           () => {
//             onStartCrawling()
//             setCurrentlyCrawling(true)
//             crawlingStatus = true
//             startStatusChecker(id, crawlWebsiteStatus, context)
//             setCrawlAllowed(false)
//             context.methods.toggleTopNotification(
//               'success',
//               t('websites.edit_crawling_start_notification')
//             )
//           },
//           e => {
//             console.log(e)
//             let message = t('websites.edit_crawling_start_failed_notification')
//             if (e[FORM_ERROR] === 'still_inside_crawl_timeout') {
//               message = t(
//                 'websites.edit_crawling_start_failed_crawl_timeout_notification'
//               )
//             } else if (e[FORM_ERROR] === 'no_js_snippet') {
//               message = t(
//                 'websites.edit_crawling_start_failed_no_ownership_notification'
//               )
//             }
//             context.methods.toggleTopNotification('error', message)
//           }
//         )}
//       >
//         {t('websites.edit_start_crawling_button')}
//       </AutoWithButton>
//     </Section>
//   )
// }

const initialTransform = values => {
  if (!values) return undefined

  return {
    ...values,
  }
}

const submitTransform = (onSubmit, values, ...rest) => {
  const subSites = []

  if (
    values !== undefined &&
    values.subSites !== undefined &&
    Array.isArray(values.subSites)
  ) {
    values.subSites.forEach(subSite => {
      const ids = []

      subSite.labels.forEach(label => {
        ids.push(cleanId(label.label['@id']))
      })

      subSites.push({ id: subSite.id, labelIds: ids })
    })
  }

  const transformed = {
    ...values,
    subSiteIds: subSites,
    templates: undefined,
  }
  transformed.subSiteIds = JSON.stringify(transformed.subSiteIds)

  return onSubmit(transformed, ...rest)
}

class LogoSection extends React.Component {
  constructor() {
    super()

    this.state = {
      logo: null,
      logoFile: null,
      logoName: null,
      deleted: false,
    }
  }

  componentDidMount() {
    if (
      this.props.initialLogo === '' ||
      this.props.initialLogo === null ||
      this.props.initialLogo === undefined
    ) {
      return
    }
    this.props
      .getFile(this.props.initialLogo.name)
      .then(res => this.setState({ logo: res }))
  }

  render() {
    const { initialLogo, t, onChange } = this.props
    const { logo, logoFile, logoName, deleted } = this.state

    let finalLogoFile = null
    let finalLogoFileName = ''

    if (logo !== null && logoFile === null && !deleted) {
      finalLogoFile = `data:${logo.mimetype};base64,${logo.base64}`
      finalLogoFileName = initialLogo.name
    }

    if (logoFile !== null && !deleted) {
      finalLogoFile = logoFile
      finalLogoFileName = logoName
    }

    return (
      <NotificationContext.Consumer>
        {context => (
          <Section>
            <H2>{t('websites.edit_logo_header')}</H2>
            <Paragraph>{t('websites.edit_logo_description')}</Paragraph>
            <FileInput
              file={finalLogoFile}
              crop={{ active: true, ratio: 1 / 1 }}
              fileName={finalLogoFileName}
              onUpload={(imageName, imageFile) => {
                this.setState({
                  logoFile: imageFile,
                  logoName: imageName,
                  deleted: false,
                })
                onChange(imageName)
              }}
              onDelete={e => {
                this.setState({ logoFile: null, logoName: null, deleted: true })
                onChange('LOGO_DELETE')
              }}
              onUploadFailed={() =>
                context.methods.toggleTopNotification(
                  'error',
                  t('websites.edit_logo_upload_failed')
                )
              }
            ></FileInput>
          </Section>
        )}
      </NotificationContext.Consumer>
    )
  }
}

class WebsiteForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      logoFilename: '',
      subSites: props.initialValues.subSites,
      currentlyParsing: props.initialValues.currentlyParsing,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.subSites !== this.props.initialValues.subSites ||
      nextState.currentlyParsing !== this.state.currentlyParsing
    ) {
      return true
    }
    return false
  }

  saveSubsiteHandler = e => {
    console.log(WebsiteStore)
  }

  handleClick = () => {
    console.log('blah')
  }

  render() {
    const {
      onSubmit,
      edit,
      initialValues,
      deleteWebsite,
      push,
      t,
      getFile,
    } = this.props

    return (
      <Form
        onSubmit={data =>
          submitTransform(onSubmit, {
            ...data,
            siteMapXml: data.siteMapXml || '',
            logoFilename: this.state.logoFilename,
            subSites: this.state.subSites,
          })
        }
        initialValues={initialTransform(initialValues)}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            <HeaderContainer>
              <H1>
                {edit ? t('websites.edit_header') : t('websites.create_header')}
              </H1>
              <HeaderButtonContainer>
                <NavLink to="/websites">
                  <Button kind="secondary">
                    {t('default.back_to_overview')}
                  </Button>
                </NavLink>
              </HeaderButtonContainer>
            </HeaderContainer>
            <SubHeaderContainer>
              <ParagraphMarginBottom>
                {edit
                  ? t('websites.edit_description')
                  : t('websites.create_description')}
              </ParagraphMarginBottom>
            </SubHeaderContainer>
            <SectionContainer>
              <Section>
                <H2>{t('websites.edit_title_header')}</H2>
                <Paragraph>{t('websites.edit_title_description')}</Paragraph>
                <FormFieldWrapper>
                  <Field
                    name="title"
                    component={TextInput}
                    format={(value = '') => value.trim()}
                    formatOnBlur
                    label={t('websites.edit_title_label')}
                    validate={isEmpty}
                  />
                </FormFieldWrapper>
              </Section>
              <Section>
                <H2>{t('websites.edit_colors_header')}</H2>
                <Paragraph>{t('websites.edit_colors_description')}</Paragraph>
                <FlexFormFieldWrapper>
                  <Field name="primaryColor" validate={isEmpty}>
                    {({ input, meta }) => {
                      return (
                        <ColorPickerSection
                          color={input.value}
                          title={t('default.primary_color')}
                          onSelectColor={color => input.onChange(color.hex)}
                          meta={meta}
                          controlled
                        />
                      )
                    }}
                  </Field>
                  <Field name="backgroundColor" validate={isEmpty}>
                    {({ input, meta }) => {
                      return (
                        <ColorPickerSection
                          color={input.value}
                          title={t('default.background_color')}
                          onSelectColor={color => input.onChange(color.hex)}
                          meta={meta}
                          controlled
                        />
                      )
                    }}
                  </Field>
                  <Field name="textColor" validate={isEmpty}>
                    {({ input, meta }) => {
                      return (
                        <ColorPickerSection
                          color={input.value}
                          title={t('default.text_color')}
                          onSelectColor={color => input.onChange(color.hex)}
                          meta={meta}
                          controlled
                        />
                      )
                    }}
                  </Field>
                </FlexFormFieldWrapper>
              </Section>
              <LogoSection
                initialLogo={initialValues.logo}
                getFile={getFile}
                onChange={fileName => this.setState({ logoFilename: fileName })}
                t={t}
              />
              <AddCustomerSubsite
                id={initialValues['@id']}
                t={t}
                updateSubSites={subSites => {
                  this.setState({
                    subSites: subSites,
                    currentlyParsing: false,
                  })
                }}
              />
              <Section>
                <H2>{t('websites.edit_siteMapXml_header')}</H2>
                <Paragraph>
                  {t('websites.edit_siteMapXml_description')}
                </Paragraph>
                <FormFieldWrapper>
                  <Field
                    name="siteMapXml"
                    component={TextInput}
                    format={(value = '') => value.trim()}
                    formatOnBlur
                    label={t('websites.edit_siteMapXml_label')}
                  />
                </FormFieldWrapper>
                {edit && (
                  <NotificationContext.Consumer>
                    {context => (
                      <Subscribe to={[WebsiteContainer]}>
                        {({ state: {}, parseSitemap, parseSitemapStatus }) => (
                          <ParseSiteSection
                            t={t}
                            id={initialValues['@id']}
                            initialLastParsedTime={initialValues.lastParsedAt}
                            initialCurrentlyParsing={
                              initialValues.currentlyParsing
                            }
                            initialParsingFailed={initialValues.parsingFailed}
                            updateSubSites={subSites => {
                              this.setState({
                                subSites,
                                currentlyParsing: false,
                              })
                            }}
                            onStartParsing={() =>
                              this.setState({ currentlyParsing: true })
                            }
                            context={context}
                            parseSitemap={parseSitemap}
                            parseSitemapStatus={parseSitemapStatus}
                          />
                        )}
                      </Subscribe>
                    )}
                  </NotificationContext.Consumer>
                )}
              </Section>
              {/* {edit && (
                <NotificationContext.Consumer>
                  {context => (
                    <Subscribe to={[WebsiteContainer]}>
                      {({ state: {}, crawlWebsite, crawlWebsiteStatus }) => (
                        <CrawlSiteSection
                          t={t}
                          id={initialValues['@id']}
                          initialLastCrawledTime={initialValues.lastCrawledAt}
                          initialCurrentlyCrawling={
                            initialValues.currentlyCrawling
                          }
                          initialCrawlingFailed={initialValues.crawlingFailed}
                          updateSubSites={subSites => {
                            this.setState({
                              subSites,
                              currentlyCrawling: false,
                            })
                          }}
                          onStartCrawling={() =>
                            this.setState({ currentlyCrawling: true })
                          }
                          context={context}
                          crawlWebsite={crawlWebsite}
                          crawlWebsiteStatus={crawlWebsiteStatus}
                        />
                      )}
                    </Subscribe>
                  )}
                </NotificationContext.Consumer>
              )} */}
              {edit && (
                <Section>
                  <H2>{t('websites.edit_sub_sites_header')}</H2>
                  <Paragraph>
                    {t('websites.edit_sub_sites_description')}
                  </Paragraph>
                  {!this.state.currentlyParsing && (
                    <SubSitesList
                      initialSubsites={this.state.subSites}
                      onChangeSubSites={subSites => {
                        this.setState({ subSites })
                      }}
                    />
                  )}
                </Section>
              )}
              <Section>
                <H2>{t('websites.edit_learning_mode_header')}</H2>
                <Paragraph>
                  {t('websites.edit_learning_mode_description')}
                </Paragraph>
                <Field
                  name="learningMode"
                  component={CheckboxInput}
                  label={t('websites.edit_learning_mode_label')}
                  type="checkbox"
                  noInline
                />
              </Section>
              <Section>
                <H2>{t('websites.edit_cookie_opt_in_header')}</H2>
                <Paragraph>
                  {t('websites.edit_cookie_opt_in_description')}
                </Paragraph>
                <Field
                  name="cookieOptIn"
                  component={CheckboxInput}
                  label={t('websites.edit_cookie_opt_in_label')}
                  type="checkbox"
                  noInline
                />
              </Section>
              <Section>
                <H2>{t('websites.edit_double_opt_in_header')}</H2>
                <Field
                  name="doubleOptIn"
                  component={CheckboxInput}
                  label={t('websites.edit_double_opt_in_label')}
                  type="checkbox"
                  noInline
                />
              </Section>
              <Subscribe to={[WebsiteContainer, AppSettingsContainer]}>
                {(
                  { checkSPF },
                  { state: { mailServerDomain }, getMailServerDomain }
                ) => (
                  <SPFSection
                    t={t}
                    id={
                      values['@id'] !== undefined
                        ? cleanId(values['@id'])
                        : undefined
                    }
                    lastSPFCheckdAt={values['lastSPFCheckdAt']}
                    mailAddress={values.mailAddress}
                    checkSPF={checkSPF}
                    initalSPFValid={
                      values.validSPFEntry !== undefined
                        ? values.validSPFEntry
                        : false
                    }
                    mailServerDomain={mailServerDomain}
                    getMailServerDomain={getMailServerDomain}
                    required={values.doubleOptIn}
                  />
                )}
              </Subscribe>
              {edit && (
                <Section>
                  <H2>{t('websites.goto_designer')}</H2>
                  <Paragraph>
                    {t('websites.goto_designer_description')}
                  </Paragraph>
                  <NavLink
                    to={`/websites/designer/${cleanId(initialValues['@id'])}`}
                  >
                    <WrapButton>{t('default.designer')}</WrapButton>
                  </NavLink>
                </Section>
              )}
              {edit && (
                <Section>
                  <H2>{t('websites.reset_tracking_header')}</H2>
                  <Paragraph>
                    {t('websites.reset_tracking_description')}
                  </Paragraph>
                  <NotificationContext.Consumer>
                    {context => (
                      <WrapDangerButton
                        disabled={submitting}
                        onClick={() =>
                          context.methods.openModal(() => (
                            <ResetTrackingDataModal
                              onClose={context.methods.closeModal}
                              website={initialValues}
                            />
                          ))
                        }
                      >
                        {t('websites.reset_button')}
                      </WrapDangerButton>
                    )}
                  </NotificationContext.Consumer>
                </Section>
              )}
              <Section>
                <FormInlineWrapper>
                  {submitError && <FormError>{submitError}</FormError>}
                </FormInlineWrapper>
                {submitting && <LoaderRightMargin />}
                <NotificationContext.Consumer>
                  {context => (
                    <FormSpy
                      subscription={{ values: true }}
                      render={({ values }) => (
                        <ButtonContainer>
                          <WrapButton type="submit" disabled={submitting}>
                            {edit
                              ? t('websites.edit_save')
                              : t('websites.create_save')}
                          </WrapButton>
                          {edit && (
                            <WrapDangerButton
                              disabled={submitting}
                              onClick={() =>
                                context.methods.openModal(() => (
                                  <ConfirmWebsiteDeleteModal
                                    id={cleanId(initialValues['@id'])}
                                    toggleTopNotification={
                                      context.methods.toggleTopNotification
                                    }
                                    onDelete={() => push('/websites')}
                                    deleteWebsite={deleteWebsite}
                                    closeModal={context.methods.closeModal}
                                  />
                                ))
                              }
                            >
                              {t('default.delete')}
                            </WrapDangerButton>
                          )}
                        </ButtonContainer>
                      )}
                    />
                  )}
                </NotificationContext.Consumer>
              </Section>
            </SectionContainer>
          </form>
        )}
      />
    )
  }
}

const AddCustomerSubsite = props => {
  const { id, updateSubSites, t } = props
  const [link, setLink] = useState('')
  const [message, setMessage] = useState()

  const handleClick = async () => {
    let response = await WebsiteStore.subsiteWithLabel({
      link: link,
      id: cleanId(id),
    })

    setMessage(response.data.message)
    setTimeout(() => {
      setMessage(null)
    }, 4000)

    if (
      !response.data ||
      !response.data.subsites ||
      response.data.status !== true
    ) {
      return
    }

    let subSites = response.data.subsites
    updateSubSites(subSites)
    setLink('')
  }

  return (
    <Section>
      <H2>{t('websites.edit_individual_subsite_header')}</H2>
      <Paragraph>{t('websites.edit_individual_subsite_description')}</Paragraph>
      <Fragment>
        <FormFieldWrapper>
          <TextInput
            name="subsiteWithLabel"
            formatOnBlur
            label={t('websites.edit_individual_subsite_link_label')}
            onChange={e => setLink(e.currentTarget.value)}
            value={link}
          />
        </FormFieldWrapper>
        <InvalidSPF>{message}</InvalidSPF>
        <IndividualSubsiteButton onClick={() => handleClick()}>
          {t('websites.edit_individual_subsite_link_button')}
        </IndividualSubsiteButton>
      </Fragment>
    </Section>
  )
}

WebsiteForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  edit: PropTypes.bool,
}

WebsiteForm.defaultProps = {
  onSubmit: () => {},
  edit: false,
  initialValues: {},
}

export default withTranslation('common')(WebsiteForm)
