import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  HeaderButtonContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import ContactsTable from 'functional/ContactsTable'
import { NotificationContext } from 'functional/NotificationProvider'

import ContactsContainer from 'containers/ContactsContainer'

import { withHandlers } from 'util/form'

const ContactListScreen = ({ t }) => (
  <LoggedInScreenContainer>
    <HeaderContainer>
      <H1>{t('contacts.title')}</H1>
      <HeaderButtonContainer>
        <NotificationContext.Consumer>
          {context => (
            <Subscribe to={[ContactsContainer]}>
              {({ exportContacts }) => (
                <Button
                  kind="primary"
                  onClick={withHandlers(
                    exportContacts,
                    () => {},
                    () => {
                      context.methods.toggleTopNotification(
                        'error',
                        t('contacts.export_failed')
                      )
                    }
                  )}
                >
                  {t('contacts.export_button')}
                </Button>
              )}
            </Subscribe>
          )}
        </NotificationContext.Consumer>
      </HeaderButtonContainer>
    </HeaderContainer>
    <SubHeaderContainer>
      <Paragraph>{t('contacts.sub_header_description')}</Paragraph>
    </SubHeaderContainer>
    <ContentContainer>
      <ContactsTable />
    </ContentContainer>
  </LoggedInScreenContainer>
)

ContactListScreen.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation('common')(ContactListScreen)
