import React from 'react'
import styled from 'styled-components'
import { lighten, darken } from 'polished'

import Signal from 'react-icons/lib/fa/signal'
import Cross from 'react-icons/lib/fa/times-circle'
import Paragraph from 'presentational/Paragraph'

import { useTranslation } from 'react-i18next'

const OfflineIndicatorWrapper = styled.div`
  background-color: ${({ online, theme }) =>
    online
      ? lighten(0.2, theme.colors.primary)
      : lighten(0.2, theme.colors.error)};

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  color: ${({ online, theme }) =>
    online
      ? darken(0.2, theme.colors.primary)
      : darken(0.2, theme.colors.error)};

  font-weight: bold;

  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  height: 48px;
  width: fit-content;

  border-radius: 4px;

  position: absolute;
  top: 24px;
  right: 48px;

  transition: all 0.45s ease-in-out;
  opacity: 1;
  z-index: 9999;

  p {
    margin-left: 6px;
    margin-bottom: 0;
    color: inherit;
    font-weight: inherit;
  }
`

const OfflineIndicator = ({ online }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <OfflineIndicatorWrapper online={online}>
      {online ? <Signal /> : <Cross />}
      <Paragraph>
        {online ? t('default.online') : t('default.offline')}
      </Paragraph>
    </OfflineIndicatorWrapper>
  )
}

export default OfflineIndicator
