import React, { Component } from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import TextareaInput from 'presentational/TextareaInput'
import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import HeaderBackButton from 'presentational/HeaderBackButton'
import FormInlineWrapper from 'presentational/FormInlineWrapper'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import { NotificationContext } from 'functional/NotificationProvider'

import AccountContainer from 'containers/AccountContainer'

import { isEmpty } from 'util/validations'
import { withHandlers } from 'util/form'
import { isEmail } from 'util'

import {
  HeaderContainer,
  SubHeaderContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'
import HelpContainer from '../../../containers/HelpContainer'

const WrapButton = styled(Button)`
  width: auto;
  margin-right: 5px;
`

const LoaderRightMargin = styled(Loader)`
  margin-right: 10px;
`

const ContentContainer = styled.div`
  margin-top: 30px;
`

class HelpContactScreen extends Component {
  render() {
    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{this.props.t('help.contact_site_title')}</H1>
          <HeaderButtonContainer>
            <NavLink to="/support/help">
              <Button kind="secondary">
                {this.props.t('default.back_to_overview')}
              </Button>
            </NavLink>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{this.props.t('help.contact_site_description')}</Paragraph>
        </SubHeaderContainer>
        <ContentContainer>
          <NotificationContext.Consumer>
            {context => (
              <Subscribe to={[AccountContainer]}>
                {({ state }) => (
                  <Form
                    onSubmit={withHandlers(
                      this.props.sendContactMessage,
                      () => {
                        this.props.history.push('/support/help')
                        context.methods.toggleTopNotification(
                          'success',
                          this.props.t('help.contact_site_success')
                        )
                      },
                      () => {
                        context.methods.toggleTopNotification(
                          'error',
                          this.props.t('help.contact_site_fail')
                        )
                      }
                    )}
                    initialValues={{
                      email:
                        state.profileData !== null
                          ? state.profileData.email
                          : '',
                    }}
                    render={({ handleSubmit, submitting, submitError }) => (
                      <form onSubmit={handleSubmit}>
                        <Section>
                          <H2>{this.props.t('help.contact_site_message')}</H2>
                          <Paragraph>
                            {this.props.t(
                              'help.contact_site_message_description'
                            )}
                          </Paragraph>
                          <FormFieldWrapper>
                            <Field
                              name="email"
                              component={TextInput}
                              format={(value = '') => value.trim()}
                              formatOnBlur
                              label={this.props.t('help.contact_site_email')}
                              validate={isEmail}
                              initialData
                              noInline
                            />
                          </FormFieldWrapper>
                          <FormFieldWrapper>
                            <Field
                              name="message"
                              component={TextareaInput}
                              label={this.props.t('help.contact_site_message')}
                              validate={isEmpty}
                              noInline
                            />
                          </FormFieldWrapper>
                          <FormInlineWrapper>
                            {submitError && (
                              <FormError>{submitError}</FormError>
                            )}
                          </FormInlineWrapper>
                          <FormInlineWrapper>
                            {submitting && <LoaderRightMargin />}
                            <FormSpy
                              subscription={{ values: true }}
                              render={({ values }) => (
                                <WrapButton type="submit" disabled={submitting}>
                                  {this.props.t('help.contact_site_button')}
                                </WrapButton>
                              )}
                            />
                          </FormInlineWrapper>
                        </Section>
                      </form>
                    )}
                  />
                )}
              </Subscribe>
            )}
          </NotificationContext.Consumer>
        </ContentContainer>
      </LoggedInScreenContainer>
    )
  }
}

const HelpContactScreenWrapper = props => (
  <Subscribe to={[HelpContainer]}>
    {({ state: { sendLoading }, sendContactMessage }) => (
      <HelpContactScreen
        {...props}
        sendLoading={sendLoading}
        sendContactMessage={sendContactMessage}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(HelpContactScreenWrapper)
