import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import H1 from 'presentational/H1'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import FormError from 'presentational/FormError'
import HorizontalMenu from 'presentational/HorizontalMenu'
import {
  HeaderContainer,
  LoaderContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import EmailDesigner from 'functional/EmailDesigner'
import CookieBannerDesigner from 'functional/CookieBannerDesigner'
import { NotificationContext } from 'functional/NotificationProvider'
import ContentAssetOverlayDesigner from 'functional/ContentAssetOverlayDesigner'

import TemplatesContainer from 'containers/TemplatesContainer'
import FileContainer from 'containers/FileContainer'
import { withHandlers } from 'util/form'
import { cleanId, URL } from 'util/api'

const StyledFormError = styled(FormError)`
  text-align: center;
  padding-top: 30px;
`

const TabContainer = styled.div`
  margin-left: 48px;
`

const CenteredLoaderContainer = styled(LoaderContainer)`
  height: 100vh;
`

class DesignerScreen extends React.Component {
  constructor(props) {
    super(props)

    this.loadTemplate = this.loadTemplate.bind(this)

    this.state = {
      loaded: false,
      activeTab: 0,
    }
  }

  componentWillMount() {
    this.props.websiteId
      ? this.props.getTemplatesByWebsite(this.props.websiteId)
      : false
  }

  componentDidMount() {
    if (this.editor && window.unlayer !== undefined) {
    } else {
      console.log('ERROR: ', 'designer not loaded')
    }
  }

  async saveEmailData(json, html, emailData) {
    if (!emailData) return null

    await this.props.updateTemplate(
      {
        html: html,
        json: json,
        type: 'email',
        placeholder: [],
      },
      cleanId(emailData['@id'])
    )
  }

  async saveBannerData(bannerData, stylingData) {
    if (!stylingData) return null

    await this.props.updateTemplate(
      {
        styling: stylingData,
        type: 'banner',
        placeholder: [],
      },
      cleanId(bannerData['@id'])
    )
  }

  async saveOverlayData(overlayData, stylingData) {
    if (!stylingData) return null

    console.log({ overlayData, stylingData })

    await this.props.updateTemplate(
      {
        styling: stylingData,
        type: 'overlay',
        placeholder: [],
      },
      cleanId(overlayData['@id'])
    )
  }

  handleTabChange = index => {
    this.setState({ activeTab: index })
  }

  loadTemplate(json) {
    if (!this.editor || window.unlayer === undefined) {
      location.reload()
    }
    if (this.editor && json) this.editor.loadDesign(json)

    const { uploadFile } = this.props

    if (this.editor) {
      this.editor.registerCallback('image', function(files, done) {
        const file = files.attachments[0]

        const fileReader = new FileReader()

        fileReader.onload = loadEvent => {
          const binaryData = loadEvent.target.result

          const base64 = binaryData.split(',')[1]

          uploadFile(base64)
            .then(fileRes => {
              done({
                progress: 100,
                url: URL + '/v1/uploads/' + fileRes.name,
              })
            })
            .catch(() => {})
        }

        fileReader.readAsDataURL(file)
      })
    }
  }

  toKeyValueJSON(data) {
    if (!data) return null
    const json = []

    Object.keys(data).forEach(element => {
      if (element === 'image' && typeof data[element] === 'object') {
        json.push({
          key: element,
          value: data[element].imageName ? data[element].imageName : '',
        })
      } else {
        json.push({
          key: element,
          value: data[element] ? data[element].toString() : '',
        })
      }
    })

    return json
  }

  parseBannerStylingData(data) {
    const stylingObject =
      typeof data[0] === 'string'
        ? JSON.parse('[' + data.join(',') + ']')
        : data

    const validStyling = {}
    stylingObject.map(
      element =>
        (validStyling[element.key] =
          isNaN(element.value) || element.value === ''
            ? element.value
            : Number(element.value))
    )

    return validStyling
  }

  renderTab() {
    const { activeTab } = this.state

    const {
      match: {
        params: { id },
      },
      t,
    } = this.props

    switch (activeTab) {
      case 0:
        return (
          <NotificationContext.Consumer>
            {context => (
              <Subscribe to={[TemplatesContainer]}>
                {({ state: { templateBannerData, updateTemplateLoading } }) => {
                  return (
                    <React.Fragment>
                      <CookieBannerDesigner
                        onChangeData={data =>
                          this.setState({
                            cookieBannerData: data,
                          })
                        }
                        defaultData={
                          this.state.cookieBannerData ||
                          !templateBannerData ||
                          (templateBannerData.styling &&
                            !templateBannerData.styling.length)
                            ? this.state.cookieBannerData
                            : this.parseBannerStylingData(
                                templateBannerData.styling
                              )
                        }
                        saveLoading={updateTemplateLoading}
                        onSave={withHandlers(
                          () =>
                            this.saveBannerData(
                              templateBannerData,
                              this.toKeyValueJSON(this.state.cookieBannerData)
                            ),
                          () =>
                            context.methods.toggleTopNotification(
                              'success',
                              t('templates.banner_save_success')
                            ),
                          () =>
                            context.methods.toggleTopNotification(
                              'error',
                              t('templates.banner_save_failed')
                            )
                        )}
                      />
                    </React.Fragment>
                  )
                }}
              </Subscribe>
            )}
          </NotificationContext.Consumer>
        )
      case 1:
        return (
          <NotificationContext.Consumer>
            {context => (
              <Subscribe to={[TemplatesContainer, FileContainer]}>
                {(
                  { state: { templateOverlayData, updateTemplateLoading } },
                  { getFile }
                ) => (
                  <ContentAssetOverlayDesigner
                    onChangeData={data =>
                      this.setState({ contentAssetOverlayData: data })
                    }
                    defaultData={
                      this.state.contentAssetOverlayData ||
                      !templateOverlayData ||
                      (templateOverlayData.styling &&
                        !templateOverlayData.styling.length)
                        ? this.state.contentAssetOverlayData
                        : this.parseBannerStylingData(
                            templateOverlayData.styling
                          )
                    }
                    logoSrc={URL + '/v1/websites/' + id + '/logo'}
                    getFile={getFile}
                    saveLoading={updateTemplateLoading}
                    onSave={withHandlers(
                      () =>
                        this.saveOverlayData(
                          templateOverlayData,
                          this.toKeyValueJSON(
                            this.state.contentAssetOverlayData
                          )
                        ),
                      () =>
                        context.methods.toggleTopNotification(
                          'success',
                          t('templates.overlay_save_success')
                        ),
                      () =>
                        context.methods.toggleTopNotification(
                          'error',
                          t('templates.overlay_save_failed')
                        )
                    )}
                  />
                )}
              </Subscribe>
            )}
          </NotificationContext.Consumer>
        )
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <Subscribe to={[TemplatesContainer]}>
          {({
            state: {
              updateTemplateLoading,
              templatesLoading,
              templatesError,
              templateEmailData,
              templatesMetaData,
            },
          }) => {
            if (templatesLoading) {
              return (
                <CenteredLoaderContainer>
                  <Loader size="large" />
                </CenteredLoaderContainer>
              )
            }

            if (templatesError) {
              return (
                <StyledFormError>{t('templates.failed_load')}</StyledFormError>
              )
            }

            if (
              (templatesMetaData &&
                templatesMetaData['hydra:totalItems'] === 0) ||
              id === undefined
            ) {
              return (
                <StyledFormError>
                  {t('templates.no_templates_found')}
                </StyledFormError>
              )
            }
            if (templateEmailData && this.editor && !this.state.loaded) {
              this.loadTemplate(templateEmailData.json)
              this.state.loaded = true
            }

            if (!id) {
              templateEmailData = null
            }

            return (
              <React.Fragment>
                <HeaderContainer>
                  <H1>Designer</H1>
                  <HeaderButtonContainer>
                    <Button
                      kind="secondary"
                      bold
                      onClick={() => this.props.history.push('/websites')}
                    >
                      {t('default.back_to_overview')}
                    </Button>
                  </HeaderButtonContainer>
                </HeaderContainer>
                <TabContainer>
                  <HorizontalMenu
                    tabs={[
                      { name: t('cookie_designer.tab_title') },
                      { name: t('overlay_designer.tab_title') },
                      { name: t('email_designer.tab_title') },
                    ]}
                    kind="big"
                    activeTab={this.state.activeTab}
                    onChange={this.handleTabChange}
                  />
                </TabContainer>
                {this.renderTab()}
                {/* The Tab for EmailDesigner is not rendered inside the renderTab()-method
                 * because it would unmount the component on tab switch.
                 * This would be problematic because the Reference of the EmailDesigner can not
                 * be set on remounting and all changes in the editor would be gone.
                 * We simply hide the Component with 'display: none' now.
                 */}
                <NotificationContext.Consumer>
                  {context => (
                    <EmailDesigner
                      editorRef={ref => (this.editor = ref)}
                      hide={this.state.activeTab !== 2}
                      saveLoading={updateTemplateLoading}
                      emailData={templateEmailData}
                      resetToIntial={initialData => {
                        this.loadTemplate(
                          this.state.savedInitialData
                            ? this.state.savedInitialData
                            : initialData.json
                        )
                        this.state.loaded = true
                      }}
                      onSave={() => {
                        this.editor.exportHtml(({ design, html }) => {
                          this.setState({ savedInitialData: design })
                          this.saveEmailData(design, html, templateEmailData)
                            .then(() =>
                              context.methods.toggleTopNotification(
                                'success',
                                t('templates.email_save_success')
                              )
                            )
                            .catch(() =>
                              context.methods.toggleTopNotification(
                                'error',
                                t('templates.email_save_failed')
                              )
                            )
                        })
                      }}
                    />
                  )}
                </NotificationContext.Consumer>{' '}
              </React.Fragment>
            )
          }}
        </Subscribe>
      </LoggedInScreenContainer>
    )
  }
}

DesignerScreen.propTypes = {
  getTemplatesByWebsite: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  websiteId: PropTypes.string,
}

const TemplateEditorWrapper = props => (
  <Subscribe to={[TemplatesContainer, FileContainer]}>
    {({ getTemplatesByWebsite, updateTemplate }, { uploadFile }) => (
      <DesignerScreen
        {...props}
        getTemplatesByWebsite={getTemplatesByWebsite}
        updateTemplate={updateTemplate}
        websiteId={props.match.params.id}
        uploadFile={uploadFile}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(TemplateEditorWrapper)
