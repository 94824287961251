import styled from 'styled-components'
import PropTypes from 'prop-types'
import { lighten, darken } from 'polished'
import theme from 'theme/theme'

const paddings = {
  small: '6px 8px',
  normal: '10px 15px',
  large: '20px 30px',
}

const backgrounds = theme.colors.backgrounds.button
/**
 * The only true button.
 *
 * @version 1.1.0
 * @author Martin Mehl
 * @author Stjepan Golemac
 */
const LabelButton = styled.label.attrs({
  type: props => (props.submit ? 'submit' : 'button'),
})`
  width: ${props => (props.size === 'small' ? 'auto' : '100%')};
  padding: ${props => paddings[props.size] || paddings.normal};
  background: ${props => backgrounds[props.kind] || backgrounds.primary};
  font-size: 1em;
  font-weight: ${props => (props.kind === 'bold' ? 'bold' : 'normal')};
  color: ${props =>
    props.kind === 'nobg' || props.kind === 'outline'
      ? props.theme.colors.text.normal
      : props.theme.colors.text.dark};
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.kind === 'nobg' ? 'rgba(255,255,255,1)' : 'lightgray'};
  border-radius: 4px;
  cursor: pointer;
  transition: color ease 0.2s;

  :disabled {
    background: ${backgrounds.complement};
    border-color: ${backgrounds.complement};
    color: ${props => props.theme.colors.text.dark};
    cursor: not-allowed;

    :hover {
      background: ${backgrounds.complement};
    }
  }

  :hover {
    background: ${props =>
      darken(0.1, backgrounds[props.kind] || backgrounds.primary)};
  }

  z-index: 1;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  color: white;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :not([disabled])::after {
    content: '';
    z-index: -1;
    background-color: hsla(0, 0%, 100%, 0.2);
    position: absolute;
    top: -50%;
    bottom: -50%;
    width: 1.25em;
    transform: translate3d(-1200%, 0, 0) rotate(35deg);
  }

  :not([disabled]):hover::after {
    transition: transform 0.45s ease-in-out;
    transform: translate3d(900%, 0, 0) rotate(35deg);
  }
`

LabelButton.propTypes = {
  /** Size of the button */
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  /** Visual kind of the button */
  kind: PropTypes.oneOf([
    'normal',
    'primary',
    'outline',
    'nobg',
    'dark',
    'bold',
    'secondary',
  ]),
  submit: PropTypes.bool,
}

LabelButton.defaultProps = {
  size: 'normal',
  kind: 'normal',
}

/** @component */
export default LabelButton
