import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { shouldRenderError } from 'util'
import InputError from 'presentational/InputError'

import queries from 'util/mediaQueries'

const ContainerInlineStyles = css`
  display: flex;
  align-items: center;

  ${queries.desktop`
    display: block;
  `};
`

const Container = styled.div`
  position: relative;
  ${props => !props.noInline && ContainerInlineStyles};
`
const LabelInlineStyles = css`
  margin-right: ${props => props.theme.formInlineLabelMargin};
  margin-bottom: 0px;
  flex-basis: ${props => props.theme.formInlineOffset};
  text-align: right;

  ${queries.desktop`
    margin-bottom: 10px;
    text-align: left;
  `};
`

const InnerContainer = styled.div`
  position: relative;
  flex: 1;
`

const Label = styled.label`
  display: block;
  font-size: 1em;
  margin-bottom: 10px;
  ${props => !props.noInline && LabelInlineStyles};

  color: ${props => props.theme.colors.text.label};
`

const Input = styled.textarea`
  width: 420px;
  min-width: 420px;
  max-width: 420px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: ${props => (props.type === 'password' ? '52px' : '21px')};
  padding-left: 16px;
  height: 150px;
  // TODO: Change later to correct font
  font-family: 'Ubuntu';

  &[type='select'] {
    padding-left: 0px;
  }

  background-color: ${props =>
    props.dark ? props.theme.colors.backgrounds.dark : '#f1efed'};

  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;

  color: ${props =>
    props.dark ? props.theme.colors.text.dark : props.theme.colors.text.normal};

  border-radius: 2px;
  font-size: 1em;

  :disabled {
    background: ;
    border-bottom: red;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${props =>
      props.dark
        ? props.theme.colors.text.dark
        : props.theme.colors.text.normal};
    -webkit-box-shadow: 0 0 0px
      ${props =>
        props.dark
          ? props.theme.colors.backgrounds.dark
          : props.theme.colors.backgrounds.normal};
    transition: background-color 5000s ease-in-out 0s;
  }
`

class TextareaInput extends Component {
  renderLabel() {
    const { label, noInline } = this.props

    return label && <Label noInline={noInline}>{label}</Label>
  }

  renderInput() {
    const { props } = this

    return (
      <Input
        {...props}
        {...(props.input || {})}
        /** We don't want to override the type in props if not necesarry */
        style={{
          borderBottomColor: shouldRenderError(props.meta) && '#c4944d',
          borderBottomWidth: shouldRenderError(props.meta) && '2px',
        }}
      />
    )
  }

  renderError() {
    const { meta } = this.props

    if (shouldRenderError(meta)) {
      const { error, submitError } = meta

      return <InputError>{error || submitError}</InputError>
    }

    return null
  }

  render() {
    const { noInline } = this.props

    return (
      <Container noInline={noInline}>
        {this.renderLabel()}
        <InnerContainer>
          {this.renderInput()}
          {this.renderError()}
        </InnerContainer>
      </Container>
    )
  }
}

TextareaInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel']),
  noInline: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    data: PropTypes.object,
    dirty: PropTypes.bool,
    error: PropTypes.any,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitError: PropTypes.any,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
}

TextareaInput.defaultProps = {
  type: 'text',
  noInline: false,
}

export default TextareaInput
