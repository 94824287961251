import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import Loader from 'presentational/Loader'
import FormError from 'presentational/FormError'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import ContentAssetForm from 'functional/ContentAssetForm'

import FileContainer from 'containers/FileContainer'
import { NotificationContext } from 'functional/NotificationProvider'

import { withHandlers } from 'util/form'
import ContentAssetContainer from 'containers/ContentAssetContainer'
import { cleanId } from 'util/api'

const CenteredLoaderContainer = styled(LoaderContainer)`
  height: 100vh;
`

class EditContentAssetScreen extends Component {
  componentDidMount() {
    this.props.getContentAsset(this.props.match.params.id)
  }

  render() {
    const {
      history: { push },
      t,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <Subscribe to={[ContentAssetContainer, FileContainer]}>
          {({ state: { details, getLoading, getError } }, { getFile }) => {
            if (getLoading || details === null)
              return (
                <CenteredLoaderContainer>
                  <Loader size="large" />
                </CenteredLoaderContainer>
              )
            if (getError) {
              return <FormError>{getError}</FormError>
            }
            return (
              <ContentAssetForm
                getFile={getFile}
                edit
                onSubmit={withHandlers(this.props.updateContentAsset, () =>
                  push('/assets')
                )}
                onDownload={() => {
                  this.props.getContentAssetFile(cleanId(details['@id']))
                }}
                initialValues={details}
              />
            )
          }}
        </Subscribe>
      </LoggedInScreenContainer>
    )
  }
}

const EditContentAssetScreenWrapper = props => (
  <Subscribe to={[ContentAssetContainer]}>
    {({ getContentAsset, updateContentAsset, getContentAssetFile }) => (
      <EditContentAssetScreen
        {...props}
        getContentAsset={getContentAsset}
        updateContentAsset={updateContentAsset}
        getContentAssetFile={getContentAssetFile}
      />
    )}
  </Subscribe>
)

EditContentAssetScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

export default withTranslation('common')(EditContentAssetScreenWrapper)
