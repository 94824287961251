import React from 'react'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Table from 'functional/Table'
import Loader from 'presentational/Loader'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'
import { NotificationContext } from 'functional/NotificationProvider'
import Tag from 'presentational/Tag'
import EditLabelModal from 'functional/EditLabelModal'
import ConfirmLabelDeleteModal from 'functional/ConfirmLabelDeleteModal'

import LabelsContainer from 'containers/LabelsContainer'

const TagContainer = styled.div`
  display: flex;
`

export default () => {
  const { t, i18n } = useTranslation('common')

  return (
    <Subscribe to={[LabelsContainer]}>
      {({
        state: { data, getAllLoading },
        getAllLabels,
        getAllLabelsCanLoadMore,
        deleteLabels,
        deleteLabel,
      }) => {
        return (
          <NotificationContext.Consumer>
            {context => (
              <Table
                getData={getAllLabels}
                data={data}
                idKey="@id"
                mapRow={({
                  name,
                  color,
                  websiteCount,
                  contentAssetCount,
                  subSiteCount,
                }) => [
                  <TagContainer>
                    <Tag tag={{ name, color }} noMargin />
                  </TagContainer>,
                  subSiteCount,
                  contentAssetCount,
                ]}
                showId={false}
                headers={[
                  t('labels.label'),
                  t('labels.label_count_subsites'),
                  t('labels.label_count_content_assets'),
                ]}
                singleOps={[
                  {
                    label: t('default.edit'),
                    handler: id =>
                      context.methods.openModal(() => (
                        <EditLabelModal
                          id={id}
                          closeModal={context.methods.closeModal}
                        />
                      )),
                  },
                  {
                    label: t('default.delete'),
                    handler: (id, item) =>
                      context.methods.openModal(() => (
                        <ConfirmLabelDeleteModal
                          id={id}
                          toggleTopNotification={
                            context.methods.toggleTopNotification
                          }
                          closeModal={() => {
                            context.methods.closeModal()
                            getAllLabels()
                          }}
                        ></ConfirmLabelDeleteModal>
                      )),
                  },
                ]}
                bulkOps={[
                  {
                    label: t('default.delete_selected'),
                    handler: deleteLabels,
                    confirmModal: true,
                  },
                ]}
                loading={getAllLoading}
                noScroll
                verticalScroll
                canLoadMore={getAllLabelsCanLoadMore()}
              />
            )}
          </NotificationContext.Consumer>
        )
      }}
    </Subscribe>
  )
}
