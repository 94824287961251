import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import WebsiteForm from 'functional/WebsiteForm'
import { NotificationContext } from 'functional/NotificationProvider'

import WebsiteContainer from 'containers/WebsiteContainer'

import { withHandlers } from 'util/form'
import { Subscribe } from 'unstated'

const NewWebsiteScreen = ({ history: { push } }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <NotificationContext.Consumer>
      {context => (
        <LoggedInScreenContainer>
          <Subscribe to={[WebsiteContainer]}>
            {({ createWebsite }) => (
              <WebsiteForm
                onSubmit={withHandlers(
                  createWebsite,
                  () => {
                    context.methods.toggleTopNotification(
                      'success',
                      t('websites.create_success')
                    )
                    push('/websites')
                  },
                  () => {
                    context.methods.toggleTopNotification(
                      'error',
                      t('websites.create_failed')
                    )
                  }
                )}
                initialValues={{
                  backgroundColor: '#D36841',
                  primaryColor: '#5F544E',
                  textColor: '#ffffff',
                  labels: [],
                }}
              />
            )}
          </Subscribe>
        </LoggedInScreenContainer>
      )}
    </NotificationContext.Consumer>
  )
}

NewWebsiteScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

export default NewWebsiteScreen
