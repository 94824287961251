import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Tag from 'presentational/Tag'
import Paragraph from 'presentational/Paragraph'

import ExpandableList from 'functional/ExpandableList'
import LabelListInputModal from 'functional/LabelListInputModal'
import { NotificationContext } from 'functional/NotificationProvider'

const ListContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`

const TagsContainer = styled.div`
  margin-right: 30px;
`

const SubSitesList = ({ initialSubsites = [], onChangeSubSites }) => {
  const { t, i18n } = useTranslation('common')

  const getTags = (tags, subSiteIndex) => {
    return tags.map((tag, tagIndex) => (
      <Tag
        key={tag.label['@id']}
        tag={{ name: tag.label.name, color: tag.label.color }}
        deleteButton
        noMargin
        onDelete={() => removeTagFromSubsite(tagIndex, subSiteIndex)}
      />
    ))
  }

  const list = data =>
    data.map((subSite, index) => ({
      title: subSite.url,
      content: <TagsContainer>{getTags(subSite.labels, index)}</TagsContainer>,
    }))

  const makeLabelIds = subSiteId => {
    const subSite = initialSubsites[subSiteId]

    const labels = subSite.labels

    return labels.map(({ label: { '@id': id, name, color } }) => ({
      value: id,
      label: name,
      color: color,
    }))
  }

  const removeTagFromSubsite = (tagIndex, subSiteIndex) => {
    const newSubSites = [...initialSubsites]

    const labels = newSubSites[subSiteIndex].labels

    labels.splice(tagIndex, 1)

    onChangeSubSites(newSubSites)
  }

  const addTagsToSubsite = (key, tags) => {
    let subsiteWithTags = initialSubsites

    tags.forEach(tag => {
      const newTag = {
        '@id': tag.value,
        '@type': 'Label',
        color: tag.color,
        name: tag.label,
      }

      subsiteWithTags[key].labels.push({ label: newTag })
    })

    onChangeSubSites(subsiteWithTags)
  }

  return (
    <NotificationContext.Consumer>
      {({ methods: { openModal, closeModal } }) => (
        <ListContainer>
          {initialSubsites.length === 0 && (
            <Paragraph>{t('websites.edit_no_subsites_found')}</Paragraph>
          )}
          <ExpandableList
            listData={list(initialSubsites)}
            withoutDot
            small
            button={t('websites.edit_tags')}
            onButtonClick={key => {
              openModal(() => (
                <LabelListInputModal
                  value={makeLabelIds(key) || []}
                  close={() => closeModal()}
                  onConfirm={selectedItems =>
                    addTagsToSubsite(key, selectedItems)
                  }
                />
              ))
            }}
          />
        </ListContainer>
      )}
    </NotificationContext.Consumer>
  )
}

export default SubSitesList
