import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import EmailEditor from 'react-email-editor'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import { ContentContainer } from 'presentational/ScreenLayoutContainers'

const HideableContentContainer = styled(ContentContainer)`
  display: ${props => (props.hide ? 'none' : 'block')};

  padding-left: 40px;
  margin-top: 0;

  background: #f1efed;
`
const StyledButton = styled(Button)`
  width: auto;
  margin: 0 5px;
`

const ActionButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`

const LoaderWithMargin = styled(Loader)`
  margin-right: 5px;
`

const PlacerholderCard = styled.div`
  width: 100%;
  background-color: white;
  padding: 30px;
  margin-top: 20px;
`

const PlaceholderValue = styled.p`
  margin: 0;
  border: none;
  width: 150px;
  background-color: #fafafa;
  color: #554c47;
  font-family: monospace;
  font-style: italic;
  text-align: center;
  padding: 5px;
`

const PlaceholderDescription = styled.p`
  margin: 0;
  margin-left: 20px;
  padding: 5px;
  color: #554c47;
`

const Placeholder = styled.div`
  display: flex;
  padding-top: 10px;
`

/**
 * The prop hide will the display of the compontent to none but keeps it mounted.
 *
 * @description Component contains Email Editor to design OptIn Emails.
 * @author Jan Mägdefrau
 */
const EmailDesigner = ({
  editorRef,
  hide = false,
  onSave,
  saveLoading = false,
  emailData,
  resetToIntial,
}) => {
  const { t, i18n } = useTranslation('common')
  return (
    <HideableContentContainer hide={hide}>
      <EmailEditor
        projectId={1345}
        ref={editorRef}
        minHeight="600px"
        options={{ displayMode: 'web' }}
      />
      <ActionButtonContainer>
        {saveLoading && <LoaderWithMargin size="normal" />}
        <StyledButton size="small" onClick={() => onSave()}>
          {t('default.save')}
        </StyledButton>
        <StyledButton
          kind="dark"
          size="small"
          onClick={() => resetToIntial(emailData)}
        >
          {t('default.reset')}
        </StyledButton>
      </ActionButtonContainer>
      <PlacerholderCard>
        <H3>{t('email_designer.placeholder_title')}</H3>
        <Paragraph>{t('email_designer.placeholder_description')}</Paragraph>
        <Placeholder>
          <PlaceholderValue>{'{{title}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_title_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{teaser}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_teaser_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{url}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_url_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{gender}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_gender_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{firstName}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_first_name_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{lastName}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_last_name_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{company}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_company_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{phone}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_phone_text')}
          </PlaceholderDescription>
        </Placeholder>
        <Placeholder>
          <PlaceholderValue>{'{{individual}}'}</PlaceholderValue>
          <PlaceholderDescription>
            {t('email_designer.placeholder_individual_text')}
          </PlaceholderDescription>
        </Placeholder>
      </PlacerholderCard>
    </HideableContentContainer>
  )
}

EmailDesigner.propTypes = {
  editorRef: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool,
  emailData: PropTypes.object,
  resetToIntial: PropTypes.func.isRequired,
}

/** @component */
export default EmailDesigner
