import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import LeftIcon from 'react-icons/lib/md/keyboard-arrow-left'

const BackButtonContainer = styled.div`
  padding-top: 48px;
  padding-left: 48px;
  background-color: white;
`

const Button = styled.button`
  border: none;
  border-radius: 5px;
  background: none;
  color: #554c47;

  font-size: inherit;
  font-family: inherit;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-right: 15px;
  padding-top: 2px;
  padding-bottom: 2px;

  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`

const StyledBackIcon = styled(LeftIcon)`
  font-size: 27px;
  margin-top: 1px;
  margin-right: 3px;
  color: #554c47;
`

const HeaderBackButton = ({ onClick }) => {
  const { t } = useTranslation('common')

  return (
    <BackButtonContainer>
      <Button onClick={onClick}>
        <StyledBackIcon />
        {t('default.back')}
      </Button>
    </BackButtonContainer>
  )
}

HeaderBackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default HeaderBackButton
