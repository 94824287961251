import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import SearchInput from 'presentational/SearchInput'
import Button from 'presentational/Button'

const TableContainer = styled.div`
  max-height: 167px;
  overflow: auto;
`

const Table = styled.table`
  width: 100%;
  text-align: left;

  th {
    color: gray;
    font-weight: normal;
    text-transform: uppercase;
  }

  tr {
    height: 40px;
  }

  td:last-child {
    text-align: right;
    padding-right: 7px;
  }
`

const WrapButton = styled(Button)`
  width: auto;
  margin-right: 5px;
`

class ListInputModal extends React.Component {
  state = {
    selected: [],
    searchValue: '',
  }

  selectItem = item => {
    const { comparer, singleChoice } = this.props
    const { selected } = this.state

    if (singleChoice) {
      this.setState({ selected: [item] })
      return
    }

    !comparer(selected, item) &&
      this.setState({ selected: [...selected, item] })
  }

  onSearchChange = value => this.setState({ searchValue: value })

  renderItems() {
    const { data, transformRow, comparer, value, singleChoice } = this.props
    const { selected, searchValue } = this.state

    let rows = data

    rows = !singleChoice
      ? rows.filter(row => !value.find(entry => entry.value === row.value))
      : rows.filter(row => row.value !== value.value)

    if (!singleChoice) rows = rows.filter(row => !comparer(selected, row))

    rows = rows
      .filter(row => row.label.toLowerCase().includes(searchValue))
      .map((row, index) => (
        <tr key={index}>
          {transformRow(row, this.selectItem, singleChoice, selected).map(
            (cell, index) => (
              <td key={index}>{cell}</td>
            )
          )}
        </tr>
      ))

    return rows.length ? (
      rows
    ) : (
      <tr>
        <td>{this.props.t('labels.input_label_no_items')}</td>
        <td />
      </tr>
    )
  }

  onConfirm = () => {
    const { onConfirm, close } = this.props

    onConfirm(this.state.selected)
    close()
  }

  render() {
    const { close, value, data, transformRow, headers, t } = this.props
    const { searchValue } = this.state

    return (
      <div>
        <H3>{t('labels.input_label_modal_header')}</H3>
        <Paragraph>{t('labels.input_label_modal_description')}</Paragraph>
        <SearchInput
          defaultValue={''}
          value={this.state.searchValue}
          filterList={this.onSearchChange}
        />
        <hr />
        <TableContainer>
          <Table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>{this.renderItems()}</tbody>
          </Table>
        </TableContainer>
        <hr />
        <WrapButton onClick={this.onConfirm}>{t('default.confirm')}</WrapButton>
        <WrapButton kind="dark" onClick={close}>
          {t('default.cancel')}
        </WrapButton>
      </div>
    )
  }
}

ListInputModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  value: PropTypes.any.isRequired,
  transformRow: PropTypes.func.isRequired,
  comparer: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  singleChoice: PropTypes.bool,
}

export default withTranslation('common')(ListInputModal)
