import React from 'react'
import { Subscribe } from 'unstated'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Loader from 'presentational/Loader'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'

import AddNewContactForm from 'functional/AddNewContactForm'
import { NotificationContext } from 'functional/NotificationProvider'

import ContactsContainer from 'containers/ContactsContainer'
import LeadsContainer from 'containers/LeadsContainer'
import { withHandlers } from 'util/form'

class ContactPersonalDetailsScreen extends React.Component {
  componentDidMount() {
    const { getLead, id, edit } = this.props
    if (edit) getLead(id)
  }

  render() {
    const { edit, t } = this.props

    return (
      <NotificationContext.Consumer>
        {context => (
          <Subscribe to={[LeadsContainer]}>
            {({
              state: { details, getOneLoading },
              updateLead,
              deleteLead,
            }) => {
              if (getOneLoading)
                return (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                )
              return (
                <React.Fragment>
                  <AddNewContactForm
                    edit={edit || false}
                    onDelete={withHandlers(
                      deleteLead,
                      () => {
                        context.methods.toggleTopNotification(
                          'success',
                          t('contacts.delete_contact_success')
                        )
                      },
                      () =>
                        context.methods.toggleTopNotification(
                          'error',
                          t('contacts.delete_contact_failed')
                        )
                    )}
                    onSubmit={withHandlers(
                      updateLead,
                      () => {
                        context.methods.toggleTopNotification(
                          'success',
                          t('contacts.edit_contact_success')
                        )
                        this.props.history.push('/contacts')
                      },
                      () =>
                        context.methods.toggleTopNotification(
                          'error',
                          t('contacts.edit_contact_failed')
                        )
                    )}
                    data={edit ? details : {}}
                  />
                </React.Fragment>
              )
            }}
          </Subscribe>
        )}
      </NotificationContext.Consumer>
    )
  }
}

const ContactPersonalDetailsScreenWrapper = props => (
  <Subscribe to={[LeadsContainer]}>
    {({ getLead }) => (
      <ContactPersonalDetailsScreen {...props} getLead={getLead} />
    )}
  </Subscribe>
)

export default withRouter(
  withTranslation('common')(ContactPersonalDetailsScreenWrapper)
)
