import React from 'react'

import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import ContactPersonalDetailsScreen from 'functional/ContactPersonalDetailsScreen'

const EditContactScreen = ({
  match: {
    params: { id },
  },
}) => {
  return (
    <LoggedInScreenContainer>
      <ContactPersonalDetailsScreen edit id={id} />
    </LoggedInScreenContainer>
  )
}

export default EditContactScreen
