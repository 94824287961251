import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'
import PrimaryButton from 'presentational/PrimaryButton'
import TextInput from 'presentational/TextInput'
import FormError from 'presentational/FormError'

import LabelsContainer from 'containers/LabelsContainer'

import queries from 'util/mediaQueries'
import { withHandlers } from 'util/form'
import ColorPickerSection from 'functional/ColorPickerSection'
import { isEmpty } from 'util'

const ButtonContainer = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-top: 19px;

  ${queries.desktop`
    width: 100%;
  `};
`

const LoaderWithMargin = styled(Loader)`
  margin-right: 10px;
`

const CreateLabelModal = ({ onClose, toggleTopNotification }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <Subscribe to={[LabelsContainer]}>
      {({ createLabel, getAllLabels }) => (
        <Form
          onSubmit={withHandlers(
            createLabel,
            [
              onClose,
              getAllLabels,
              () => toggleTopNotification('success', t('labels.add_success')),
            ],
            () => toggleTopNotification('error', t('labels.add_error'))
          )}
          render={({ submitting, submitError, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <H3>{t('labels.add_modal_header')}</H3>
              <Paragraph>{t('labels.add_modal_description')}</Paragraph>
              <Field
                name="name"
                label="Name"
                noInline
                component={TextInput}
                format={(value = '') => value.trim()}
                formatOnBlur
                validate={isEmpty}
              />
              <Field name="color">
                {({ input, meta }) => {
                  return (
                    <ColorPickerSection
                      color="#000000"
                      title={t('default.color')}
                      onSelectColor={color => input.onChange(color.hex)}
                      meta={meta}
                    />
                  )
                }}
              </Field>

              {submitError && <FormError>{submitError}</FormError>}
              {submitting && <LoaderWithMargin />}
              <ButtonContainer>
                <PrimaryButton type="submit" disabled={submitting}>
                  {t('default.add')}
                </PrimaryButton>
              </ButtonContainer>
              <ButtonContainer>
                <Button onClick={onClose} kind="secondary">
                  {t('default.cancel')}
                </Button>
              </ButtonContainer>
            </form>
          )}
        />
      )}
    </Subscribe>
  )
}

CreateLabelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  toggleTopNotification: PropTypes.func.isRequired,
}

export default CreateLabelModal
