import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import AssetsIcon from 'react-icons/lib/md/dns'
import AnonymousUserIcon from 'react-icons/lib/md/person'
import ConvertedUserIcon from 'react-icons/lib/md/person-add'
import TimeIcon from 'react-icons/lib/md/access-time'
import WebIcon from 'react-icons/lib/md/web'
import HistoryIcon from 'react-icons/lib/md/history'

import Loader from 'presentational/Loader'
import H3 from 'presentational/H3'
import Tag from 'presentational/Tag'
import Paragraph from 'presentational/Paragraph'
import ContextMenuButton from 'presentational/ContextMenuButton'

import { cleanId } from 'util/api'
import { formatDate } from 'util/date'

const Container = styled.div`
  position: relative;
  min-width: 330px;
  max-width: 330px;
  min-height: 350px;

  background-color: #fff;

  // border-bottom-right-radius: 18px;
  // border-top-left-radius: 18px;

  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;

  margin: 15px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-right: 0px;
  align-items: flex-start;
`

const MetaContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;

  padding-top: 5px;
  padding-bottom: 5px;
`

const ButtonContainer = styled.div`
  padding-top: 24px;
`

const ChipContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  max-height: 50px;
  overflow: hidden;

  margin-bottom: 4px;
  margin-right: 0px;
  height: auto;
`

const TruncatedH3 = styled(H3)`
  word-break: break-all;
`

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`

const MetaItemText = styled.p`
  margin: 0;
  color: #554c47;
  font-size: 14px;
`

const MetaItemIcon = styled.div`
  width: 30px;
`

const MetaNumber = styled.span`
  color: #a0b6cb;
`

const LoaderRightMargin = styled(Loader)`
  margin-right: 10px;
`

const WebsiteListItem = ({ item, context }) => {
  const { t, i18n } = useTranslation('common')

  const parseWebsiteTitle = title => {
    if (title.startsWith('http://')) {
      return title.substring(7)
    }
    if (title.startsWith('https://')) {
      return title.substring(8)
    }

    return title
  }

  return (
    <Container>
      <TitleContainer>
        <TruncatedH3>{parseWebsiteTitle(item.title)}</TruncatedH3>
        {context && item['@id'] ? (
          <ButtonContainer>
            <ContextMenuButton context={context} id={cleanId(item['@id'])} />
          </ButtonContainer>
        ) : null}
      </TitleContainer>
      <MetaContainer>
        <div>
          <MetaItem>
            <MetaItemIcon>
              <WebIcon size="20px" color="#554c47" />
            </MetaItemIcon>
            <MetaItemText>
              <MetaNumber>{item.subSites.length}</MetaNumber>{' '}
              {t('websites.subsites')}
            </MetaItemText>
          </MetaItem>
          <MetaItem>
            <MetaItemIcon>
              <AssetsIcon size="20px" color="#554c47" />
            </MetaItemIcon>
            <MetaItemText>
              <MetaNumber>{item.displayedAssetsCount}</MetaNumber>{' '}
              {t('websites.displayed_assets')}
            </MetaItemText>
          </MetaItem>
          <MetaItem>
            <MetaItemIcon>
              <AnonymousUserIcon size="20px" color="#554c47" />
            </MetaItemIcon>
            <MetaItemText>
              <MetaNumber>{item.createdAnonymousContactsCount}</MetaNumber>{' '}
              {t('websites.anonymous_users')}
            </MetaItemText>
          </MetaItem>
          <MetaItem>
            <MetaItemIcon>
              <ConvertedUserIcon size="20px" color="#554c47" />
            </MetaItemIcon>
            <MetaItemText>
              <MetaNumber>{item.convertedContactsCount}</MetaNumber>{' '}
              {t('websites.converted_users')}
            </MetaItemText>
          </MetaItem>
          <MetaItem>
            <MetaItemIcon>
              <TimeIcon size="20px" color="#554c47" />
            </MetaItemIcon>
            <MetaItemText>
              {t('default.created_at')}{' '}
              <MetaNumber>{formatDate(item.createdAt)}</MetaNumber>
            </MetaItemText>
          </MetaItem>
          {item.updatedAt && (
            <MetaItem>
              <MetaItemIcon>
                <HistoryIcon size="20px" color="#554c47" />
              </MetaItemIcon>
              <MetaItemText>
                {t('default.updated_at')}{' '}
                <MetaNumber>{formatDate(item.updatedAt)}</MetaNumber>
              </MetaItemText>
            </MetaItem>
          )}
        </div>
      </MetaContainer>
    </Container>
  )
}

WebsiteListItem.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    title: PropTypes.string,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        label: PropTypes.object,
      })
    ),
  }),
  context: PropTypes.array,
  list: PropTypes.bool,
}

WebsiteListItem.defaultProps = {
  item: {},
  context: [],
  list: false,
}

export default WebsiteListItem
