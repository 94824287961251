import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { getTokenSync } from 'util'
import i18n from '../../../translation/i18n'

import AccountContainer from 'containers/AccountContainer'
import WebsiteContainer from 'containers/WebsiteContainer'

import Logo from 'presentational/Logo'
import Loader from 'presentational/Loader'
import FormError from 'presentational/FormError'
import AuthLayout from 'presentational/AuthLayout'
import AuthLogoContainer from 'presentational/AuthLogoContainer'

import Stepper from 'functional/Stepper'
import FileInput from 'functional/FileInput'
import OnboardingWizardSummary from 'functional/OnboardingWizardSummary'
import OnboardingWizardUserProfileForm from 'functional/OnboardingWizardUserProfileForm'
import OnboardingWizardCreateWebsiteForm from 'functional/OnboardingWizardCreateWebsiteForm'

const MaxWidth = styled.div`
  max-width: ${props => (props.activeStep === 2 ? '600px' : '320px')};
  margin: 20px auto;
`

const CenteredAuthLogoContainer = styled(AuthLogoContainer)`
  margin: 40px auto;
`

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
  align-items: center;
`

const WizardFileInputWrapper = styled.div`
  float: right;
  margin-right: -168px;

  > div {
    padding-top: 0;
  }
`

const CenteredLoader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

/**
 * @description Screen is shown after a new user logs in to collect more data.
 * @author Jan Mägdefrau
 */
class OnboardingWizardScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      finishedSteps: [],
      websiteInformationData: {
        backgroundColor: '#D36841',
        primaryColor: '#5F544E',
        textColor: '#ffffff',
        title: 'https://',
      },
      summary: false,
      avatarFile: '',
      websiteFile: '',
      website: {},
    }
  }

  componentDidMount() {
    this.props.getProfileData()
  }

  selectNextStep(profileData = {}) {
    const steps = [
      this.props.t('onboarding_wizard.step_profile_information'),
      this.props.t('onboarding_wizard.step_create_website'),
      this.props.t('onboarding_wizard.step_summary'),
    ]

    let { activeStep, finishedSteps } = this.state

    if (activeStep + 1 <= steps.length && !finishedSteps.includes(activeStep)) {
      finishedSteps.push(activeStep)
    }

    if (activeStep === 0) {
      let website = 'https://'

      if (profileData !== undefined && profileData.email !== undefined) {
        website += profileData.email.split('@').pop()
      }

      this.setState({
        websiteInformationData: {
          ...this.state.websiteInformationData,
          title: website,
        },
      })
    }

    if (activeStep + 1 < steps.length) {
      activeStep++
    }

    this.setState({ activeStep, finishedSteps })
  }

  saveData(profileData, websiteInformationData) {
    const userData = {
      ...profileData,
      ...this.state.userInformationData,
    }

    userData.avatarFilename = this.state.avatarUrl

    // save the user information data
    const profilePromise = this.props.updateProfileData({
      ...userData,
      registered: true,
      language: i18n.language,
    })
    const websitePromise =
      this.state.finishedSteps.length !== 3
        ? this.props.createWebsite({
            ...websiteInformationData,
            logoFilename: this.state.websiteUrl,
          })
        : this.props.updateWebsite({
            ...this.state.website,
            ...websiteInformationData,
            logoFilename: this.state.websiteUrl,
          })

    const noTemplates = ({ templates, ...rest }) => rest

    Promise.all([profilePromise, websitePromise]).then(data => {
      // go to next Step
      this.setState({
        finishedSteps: [0, 1, 2],
        websiteInformationData,
        userInformationData: userData,
        activeStep: 2,
        website: { ...noTemplates(data[1].data), subSiteIds: '[]' },
      })
    })
  }

  renderSite(profileData) {
    switch (this.state.activeStep) {
      case 0:
        return (
          <Subscribe to={[AccountContainer]}>
            {({ state: { updateProfileError } }) => {
              return (
                <React.Fragment>
                  {updateProfileError ? (
                    <FormError>{updateProfileError}</FormError>
                  ) : null}
                  <WizardFileInputWrapper>
                    <FileInput
                      label={this.props.t('default.avatar')}
                      accept="image/png, image/jpeg"
                      file={this.state.avatarFile}
                      onUpload={(url, file) =>
                        this.setState({ avatarUrl: url, avatarFile: file })
                      }
                      onDelete={() =>
                        this.setState({ avatarUrl: '', avatarFile: '' })
                      }
                      light
                    />
                  </WizardFileInputWrapper>
                  <OnboardingWizardUserProfileForm
                    data={this.state.userInformationData}
                    onSubmit={data => {
                      this.setState({
                        userInformationData: data,
                      })
                      this.selectNextStep(profileData)
                    }}
                  />
                </React.Fragment>
              )
            }}
          </Subscribe>
        )
      case 1:
        return (
          <Subscribe to={[AccountContainer, WebsiteContainer]}>
            {({ state: { profileData } }, { state: { createError } }) => (
              <Fragment>
                {createError ? <FormError>{createError}</FormError> : null}
                <WizardFileInputWrapper>
                  <FileInput
                    label={this.props.t('default.logo')}
                    light
                    file={this.state.websiteFile}
                    onUpload={(url, file) =>
                      this.setState({ websiteUrl: url, websiteFile: file })
                    }
                    onDelete={() =>
                      this.setState({ websiteUrl: '', websiteFile: '' })
                    }
                  ></FileInput>
                </WizardFileInputWrapper>
                <OnboardingWizardCreateWebsiteForm
                  onSubmit={data => {
                    this.saveData(profileData, data)
                  }}
                  colors={this.state.websiteInformationData.colors}
                  data={this.state.websiteInformationData}
                  onChange={value => {
                    this.setState({
                      websiteInformationData: {
                        ...this.state.websiteInformationData,
                        ...value,
                      },
                    })
                  }}
                  onBack={() => this.setState({ activeStep: 0 })}
                />
              </Fragment>
            )}
          </Subscribe>
        )
      case 2:
        return (
          <Subscribe to={[AccountContainer]}>
            {({ state: { updateProfileLoading, updateProfileError } }) => {
              if (updateProfileError) {
                return <FormError>Something went wrong.</FormError>
              }

              return updateProfileLoading ? (
                <CenterContainer>
                  <CenteredLoader>
                    <Loader size="large" />
                  </CenteredLoader>
                </CenterContainer>
              ) : (
                <OnboardingWizardSummary
                  userInformationData={this.state.userInformationData}
                  websiteInformationData={this.state.websiteInformationData}
                  onFinish={() => this.props.history.push('/')}
                  onBack={() => this.setState({ activeStep: 1 })}
                />
              )
            }}
          </Subscribe>
        )
    }
  }

  switchStep(index) {
    this.setState({ activeStep: index })
  }

  render() {
    const steps = [
      this.props.t('onboarding_wizard.step_profile_information'),
      this.props.t('onboarding_wizard.step_create_website'),
      this.props.t('onboarding_wizard.step_summary'),
    ]
    const { activeStep, finishedSteps } = this.state
    const errors = []
    this.state.userInformationError ? errors.push(0) : null

    return (
      <Subscribe to={[AccountContainer]}>
        {({ state: { profileData, profileLoading } }) => {
          if (profileLoading) {
            return (
              <AuthLayout maxWidth="100%">
                <Loader />
              </AuthLayout>
            )
          }

          if (
            profileData &&
            profileData.registered &&
            this.state.activeStep === 0
          ) {
            return <Redirect to="/" />
          }
          return (
            <AuthLayout maxWidth="100%">
              <CenteredAuthLogoContainer>
                <Logo width={'220px'} />
              </CenteredAuthLogoContainer>
              <Stepper
                steps={steps}
                activeStep={activeStep}
                finishedSteps={finishedSteps}
                errors={errors}
                switchStep={index =>
                  finishedSteps.includes(index) ? this.switchStep(index) : null
                }
              />
              <MaxWidth activeStep={this.state.activeStep}>
                {this.renderSite(profileData)}
              </MaxWidth>
            </AuthLayout>
          )
        }}
      </Subscribe>
    )
  }
}

const isLoggedIn = () => getTokenSync()

const OnboardingWizardScreenWrapper = props => {
  if (!isLoggedIn()) {
    props.history.push('/login')
    return <div />
  }

  return (
    <Subscribe to={[WebsiteContainer, AccountContainer]}>
      {(
        { createWebsite, updateWebsite },
        { updateProfileData, getProfileData }
      ) => (
        <OnboardingWizardScreen
          updateProfileData={updateProfileData}
          getProfileData={getProfileData}
          createWebsite={createWebsite}
          updateWebsite={updateWebsite}
          {...props}
        />
      )}
    </Subscribe>
  )
}

/** @component */
export default withTranslation('common')(OnboardingWizardScreenWrapper)
