import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

export default class AnalyticsContainer extends Container {
  state = {
    analyticsData: null,

    getAnalyticsLoading: false,
    getAnalyticsError: null,

    requestLoading: false,
    requestError: null,

    chartAnalyticsData: null,
    getChartAnalyticsLoading: true,
    getChartAnalyticsError: null,
  }

  getAnalytics = async (timespan = 'today', websiteId, type, subSiteId) => {
    try {
      await this.setState({
        getAnalyticsLoading: true,
        getAnalyticsError: null,
      })

      const params = {
        timespan,
      }

      if (type !== undefined) {
        params.type = type
      }

      if (websiteId !== undefined) {
        params.website = websiteId
      }

      if (subSiteId !== undefined) {
        params.subSite = subSiteId
      }

      const res = await apiClient.get(
        `analytics?${qs.stringify(params, { encode: false })}`
      )
      await this.setState({
        getAnalyticsLoading: false,
        analyticsData:
          type !== undefined
            ? { ...this.state.analyticsData, ...res.data }
            : res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getAnalyticsLoading: false,
        getAnalyticsError: handleFailure(e, true),
      })

      throw e
    }
  }

  getChartAnalytics = async (timespan = 'week', websiteId, subSiteId) => {
    try {
      await this.setState({
        getChartAnalyticsLoading: true,
        getChartAnalyticsError: null,
      })

      const params = {
        timespan,
      }

      if (websiteId !== undefined) {
        params.website = websiteId
      }

      if (subSiteId !== undefined) {
        params.subSite = subSiteId
      }

      const res = await apiClient.get(
        `analytics/chart?${qs.stringify(params, { encode: false })}`
      )
      await this.setState({
        getChartAnalyticsLoading: false,
        chartAnalyticsData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getChartAnalyticsLoading: false,
        getChartAnalyticsError: handleFailure(e, true),
      })

      throw e
    }
  }
}
