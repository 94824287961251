import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from 'presentational/Button'

const LicenseCard = styled.div`
  width: 300px;
  border: ${props =>
    props.active ? '2px solid #a0b6cb' : '1px solid #dcdcdc'};
  border-radius: 5px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
`

const LicenseTitle = styled.div`
  background-color: #a0b6cb;
  text-align: center;
  color: white;
  height: 40px;
  line-height: 40px;
`

const PriceSection = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Price = styled.p`
  margin: 0;
  font-size: 40px;
  font-weight: bold;
  color: #554c47;
`

const PriceMessage = styled.p`
  color: #554c47;
  margin: 0;
  font-size: 16px;
`

const Feature = styled.p`
  text-align: center;
  background: #fafafa;
  color: #554c47;
  padding: 13px 5px;
  border-top: 1px solid #dcdcdc;
  font-size: 14px;
  margin: 0;
`

const UpgradeButton = styled(Button)`
  width: auto;
`

const UpgradeButtonContainer = styled.div`
  border-top: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`

const FinalFeature = styled(Feature)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0;
  border: none;
`

const FeatureContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const LicensePlan = ({ title, price, active, features, onClickUpgrade }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <LicenseCard active={active}>
      <LicenseTitle>{title}</LicenseTitle>
      <PriceSection>
        <Price>{price}€</Price>
        <PriceMessage>{t('license.status_price_message')}</PriceMessage>
      </PriceSection>
      <FeatureContainer>
        {features.map((feature, index) => (
          <Feature key={index}>{feature}</Feature>
        ))}
        <FinalFeature></FinalFeature>
      </FeatureContainer>
      <UpgradeButtonContainer>
        <UpgradeButton
          size="small"
          disabled={active}
          onClick={() => (!active ? onClickUpgrade() : null)}
        >
          {active
            ? t('license.status_current_plan')
            : t('license.status_upgrade_button')}
        </UpgradeButton>
      </UpgradeButtonContainer>
    </LicenseCard>
  )
}

LicensePlan.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  active: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.string),
}

LicensePlan.defaultProps = {
  title: '',
  price: '',
  active: false,
  features: [],
}

export default LicensePlan
