import { Container } from 'unstated'
import saveAs from 'file-saver'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

class LeadsContainer extends Container {
  state = {
    data: null,
    metaData: null,
    details: null,

    error: null,
    loading: false,

    createLoading: false,
    createError: null,

    getOneLoading: false,
    getOneError: null,

    updateLoading: false,
    updateError: null,

    exportLoading: false,
    exportError: null,

    deleteLeadLoading: false,
    deleteLeadError: null,

    deleteLeadsLoading: false,
    deleteLeadsError: null,
  }

  getLead = async id => {
    try {
      await this.setState({
        details: null,
        getOneLoading: true,
        getOneError: null,
      })

      const res = await apiClient.get(`leads/${cleanId(id)}`)

      await this.setState({ getOneLoading: false, details: res.data })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getOneLoading: false,
        getOneError: handleFailure(e, true),
      })

      throw e
    }
  }

  getLeads = async (params, pageChanged) => {
    try {
      await this.setState({ loading: true, error: null })

      let page

      if (pageChanged) {
        if (!this.state.metaData || !this.state.metaData['hydra:view']) {
          await this.setState({ loading: false })
          return
        }

        const { 'hydra:next': nextPage } = this.state.metaData['hydra:view']

        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ loading: false })
          return
        }
      }

      const res = await apiClient.get(
        `leads?${qs.stringify({ ...params, page }, { encode: false })}`
      )
      const data = res.data['hydra:member']

      await this.setState({
        loading: false,
        data: pageChanged ? [...this.state.data, ...data] : data,
        metaData: res.data,
      })

      return data
    } catch (e) {
      console.warn('in getLeads', e)

      this.setState({ loading: false, error: handleFailure(e, true) })

      throw e
    }
  }

  updateLead = async ({ '@id': id, ...data }) => {
    try {
      await this.setState({ updateLoading: false, updateError: null })

      await apiClient.put(`leads/${cleanId(id)}`, { ...data })

      await this.setState({ updateLoading: false })
    } catch (e) {
      console.warn(e)

      this.setState({
        updateLoading: false,
        updateError: handleFailure(e, true),
      })

      throw e
    }
  }

  getLeadsCanLoadMore = () => {
    if (!this.state.metaData || !this.state.metaData['hydra:view']) return false

    return !!this.state.metaData['hydra:view']['hydra:next']
  }

  deleteLead = async id => {
    try {
      await this.setState({
        deleteLeadLoading: true,
        deleteLeadError: null,
      })

      await apiClient.delete(`leads/${cleanId(id)}`)
      await this.getLeads()

      await this.setState({ deleteLeadLoading: false })
    } catch (e) {
      console.warn('in deleteLead', e)

      this.setState({
        deleteLeadLoading: false,
        deleteLeadError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteLeads = async ids => {
    try {
      await this.setState({
        deleteLeadsLoading: true,
        deleteLeadsError: null,
      })

      const query = qs.stringify(
        { ids: ids.map(id => cleanId(id)) },
        { encode: false }
      )

      await apiClient.delete(`leads/bulk_delete?${query}`)

      await this.setState({ deleteLeadsLoading: false })
    } catch (e) {
      console.warn('in deleteLeads', e)

      this.setState({
        deleteLeadsLoading: false,
        deleteLeadsError: handleFailure(e, true),
      })

      throw e
    }
  }
}

export default LeadsContainer
