import { Container } from 'unstated'
import qs from 'qs'

import { handleFailure } from 'util/form'
import { apiClient, cleanId } from 'util/api'

class WebsiteContainer extends Container {
  state = {
    websitesLoading: false,
    websitesData: null,
    websitesMetaData: null,
    websitesError: null,

    getWebsiteLoading: false,
    websiteData: null,
    getWebsiteError: null,

    deleteLoading: false,
    deleteError: null,

    createLoading: false,
    createError: null,

    updateLoading: false,
    updateError: null,

    resetAllLoading: false,
    resetAllError: null,

    resetLoading: false,
    resetError: null,

    deleteWebsitesLoading: false,
    deleteWebsitesError: null,

    crawlWebsiteLoading: false,
    crawlWebsiteError: null,
  }

  getWebsites = async (params, pageChanged) => {
    try {
      await this.setState({ websitesLoading: true, websitesError: null })

      let page

      if (pageChanged && this.state.websitesMetaData) {
        const { 'hydra:next': nextPage } = this.state.websitesMetaData[
          'hydra:view'
        ]
        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ websitesLoading: false })
          return
        }
      }

      const res = await apiClient.get(
        `websites?${qs.stringify({ ...params, page }, { encode: false })}`
      )
      const data = res.data['hydra:member']

      await this.setState({
        websitesLoading: false,
        websitesData: pageChanged
          ? [...this.state.websitesData, ...data]
          : data,
        websitesMetaData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        websitesLoading: false,
        websitesError: handleFailure(e, true),
      })

      throw e
    }
  }

  createWebsite = async data => {
    try {
      await this.setState({
        createLoading: true,
        createError: null,
      })

      if (data.title.slice(-1) === '/') {
        data.title = data.title.slice(0, -1)
      }

      const response = await apiClient.post('websites', data)

      await this.setState({ createLoading: false })

      return response
    } catch (e) {
      console.warn(e)

      await this.setState({
        createLoading: false,
        createError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateWebsite = async data => {
    await this.setState({
      updateLoading: false,
      updateError: null,
    })

    if (data.title.slice(-1) === '/') {
      data.title = data.title.slice(0, -1)
    }

    const { '@id': id, ...restData } = data
    const response = await apiClient.put(`websites/${cleanId(id)}`, restData)

    await this.setState({ updateLoading: false })

    return response
  }

  getWebsite = async id => {
    try {
      await this.setState({
        getWebsiteLoading: true,
        getWebsiteError: null,
      })

      const res = await apiClient.get(`websites/${cleanId(id)}`)

      await this.setState({
        getWebsiteLoading: false,
        websiteData: res.data,
      })
    } catch (e) {
      console.warn('in getWebsite', e)

      await this.setState({
        getWebsiteLoading: false,
        getWebsiteError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteWebsite = async id => {
    try {
      await this.setState({
        deleteLoading: true,
        deleteError: null,
      })

      await apiClient.delete(`websites/${id}`)

      this.getWebsites()

      await this.setState({ deleteLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        deleteError: handleFailure(e, true),
        deleteLoading: false,
      })

      throw e
    }
  }

  getWebsitesCanLoadMore = () => {
    if (
      !this.state.websitesMetaData ||
      !this.state.websitesMetaData['hydra:view']
    )
      return false

    return !!this.state.websitesMetaData['hydra:view']['hydra:next']
  }

  resetAllTrackingData = async () => {
    try {
      await this.setState({
        resetAllLoading: true,
        resetAllError: null,
      })

      await apiClient.post(`contacts/track_reset`)

      await this.setState({ resetAllLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        resetAllError: handleFailure(e, true),
        resetAllLoading: false,
      })

      throw e
    }
  }

  resetTrackingData = async id => {
    try {
      await this.setState({
        resetLoading: true,
        resetError: null,
      })

      await apiClient.post(`contacts/track_reset`, { websiteId: id })

      this.getWebsites()

      await this.setState({ resetLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        resetError: handleFailure(e, true),
        resetLoading: false,
      })
    }
  }

  deleteWebsites = async ids => {
    try {
      await this.setState({
        deleteWebsitesLoading: true,
        deleteWebsitesError: null,
      })

      const query = qs.stringify(
        { ids: ids.map(id => cleanId(id)) },
        { encode: false }
      )

      await apiClient.delete(`websites/bulk_delete?${query}`)

      await this.setState({ deleteWebsitesLoading: false })
    } catch (e) {
      console.warn('in deleteContactsContact', e)

      this.setState({
        deleteWebsitesLoading: false,
        deleteWebsitesError: handleFailure(e, true),
      })

      throw e
    }
  }

  // crawlWebsite = async id => {
  //   try {
  //     await this.setState({
  //       crawlWebsiteLoading: true,
  //       crawlWebsiteError: null,
  //     })

  //     await apiClient.post(`websites/${id}/crawl`, {
  //       crawlSiteLimit: 500,
  //       tagLimit: 10,
  //     })

  //     await this.setState({ crawlWebsiteLoading: false })
  //   } catch (e) {
  //     console.warn('in crawlWebsite', e)

  //     this.setState({
  //       crawlWebsiteLoading: false,
  //       crawlWebsiteError: handleFailure(e, true),
  //     })

  //     throw e
  //   }
  // }

  parseSitemap = async id => {
    try {
      await this.setState({
        parseSitemapLoading: true,
        parseSitemapError: null,
      })

      await apiClient.post(`websites/${id}/parse_sitemap`, {
        parseSiteLimit: 500,
        tagLimit: 10,
      })

      await this.setState({ parseSitemapLoading: false })
    } catch (e) {
      console.warn('in parseSitemap', e)

      this.setState({
        parseSitemapLoading: false,
        parseSitemapError: handleFailure(e, true),
      })

      throw e
    }
  }

  parseSitemapStatus = async id => {
    return await apiClient.get(`websites/${id}/parse_sitemap_status`)
  }

  subsiteWithLabel = async data => {
    try {
      await this.setState({
        subsitewithlabelLoading: true,
        subsitewithlabelError: null,
      })

      let response = await apiClient.post(`websites/${data.id}/subsite_with_label`, {
        parseSiteLimit: 1,
        tagLimit: 10,
        link: data.link
      })

      await this.setState({ subsitewithlabelLoading: false })

      return response
    } catch (e) {
      console.warn('in subsitewithlabel', e)

      this.setState({
        subsitewithlabelLoading: false,
        subsitewithlabelError: handleFailure(e, true),
      })

      throw e
    }
  }

  checkSPF = async ({ mailAddress, websiteId }) => {
    const requestData = {
      mailAddress,
    }

    if (websiteId != undefined) {
      requestData['websiteId'] = websiteId
    }

    return await apiClient.post(`websites/check_spf`, requestData)
  }

  // crawlWebsiteStatus = async id => {
  //   return await apiClient.get(`websites/${id}/crawl_status`)
  // }
}

export default WebsiteContainer
export const WebsiteStore = new WebsiteContainer()
