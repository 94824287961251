import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 20px;
`

const ButtonLeft = styled(Button)`
  margin-right: 5px;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
`

const ConfirmNewAPIKeyModal = ({ onSubmit, onClose }) => {
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <H3>{t('api.api_modal_header')}</H3>
      <Paragraph>{t('api.api_modal_description')}</Paragraph>
      <ButtonWrapper>
        <ButtonLeft
          onClick={() => {
            onSubmit()
            onClose()
          }}
        >
          {t('api.api_modal_confirm')}
        </ButtonLeft>
        <ButtonRight onClick={onClose} type="nobg">
          {t('default.cancel')}
        </ButtonRight>
      </ButtonWrapper>
    </React.Fragment>
  )
}

ConfirmNewAPIKeyModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ConfirmNewAPIKeyModal
