import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ConvertedUserIcon from 'react-icons/lib/md/person-add'
import TimeIcon from 'react-icons/lib/md/access-time'
import HistoryIcon from 'react-icons/lib/md/history'
import DesktopIcon from 'react-icons/lib/md/desktop-windows'

import Tag from 'presentational/Tag'
import ContextMenuButton from 'presentational/ContextMenuButton'
import Paragraph from 'presentational/Paragraph'
import H3 from 'presentational/H3'
import { makeId } from 'presentational/TemplateListItem'

import { cleanId } from 'util/api'
import { formatDate } from 'util/date'

const Container = styled.div`
  position: relative;
  min-width: 330px;
  max-width: 330px;
  min-height: 350px;

  background-color: #fff;

  // border-bottom-right-radius: 18px;
  // border-top-left-radius: 18px;

  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;

  margin: 15px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-right: 0px;
  align-items: flex-start;
`

const MetaContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;

  padding: 5px 0;
`

const ButtonContainer = styled.div`
  padding-top: 24px;
`
const ChipContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  max-height: 50px;
  overflow: hidden;

  margin-bottom: 4px;
  margin-right: 0px;
  height: auto;
`

const TruncatedH3 = styled(H3)`
  word-break: break-all;
`

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`

const MetaItemText = styled.p`
  margin: 0;
  color: #554c47;
  font-size: 14px;
`

const MetaItemIcon = styled.div`
  width: 30px;
`

const MetaNumber = styled.span`
  color: #a0b6cb;
`

const ContentAssetListItem = ({ item, context }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <Container>
      <TitleContainer>
        <TruncatedH3>{item.name || item.title}</TruncatedH3>
        {context && item['@id'] ? (
          <ButtonContainer>
            <ContextMenuButton context={context} id={makeId(item['@id'])} />
          </ButtonContainer>
        ) : null}
      </TitleContainer>
      <ChipContainer>
        {item.labels.map(label => (
          <Tag key={label['@id']} tag={label.label} noMargin />
        ))}
      </ChipContainer>
      <MetaContainer>
        <MetaItem>
          <MetaItemIcon>
            <DesktopIcon size="20px" color="#554c47" />
          </MetaItemIcon>
          <MetaItemText>
            <MetaNumber>{item.displaysCount}</MetaNumber>{' '}
            {t('content_assets.displays')}
          </MetaItemText>
        </MetaItem>
        <MetaItem>
          <MetaItemIcon>
            <ConvertedUserIcon size="20px" color="#554c47" />
          </MetaItemIcon>
          <MetaItemText>
            <MetaNumber>{item.convertedContactsCount}</MetaNumber>{' '}
            {t('content_assets.converted_users')}
          </MetaItemText>
        </MetaItem>
        <MetaItem>
          <MetaItemIcon>
            <TimeIcon size="20px" color="#554c47" />
          </MetaItemIcon>
          <MetaItemText>
            {t('default.created_at')}{' '}
            <MetaNumber>{formatDate(item.createdAt)}</MetaNumber>
          </MetaItemText>
        </MetaItem>
        {item.updatedAt && (
          <MetaItem>
            <MetaItemIcon>
              <HistoryIcon size="20px" color="#554c47" />
            </MetaItemIcon>
            <MetaItemText>
              {t('default.updated_at')}{' '}
              <MetaNumber>{formatDate(item.updatedAt)}</MetaNumber>
            </MetaItemText>
          </MetaItem>
        )}
      </MetaContainer>
    </Container>
  )
}

ContentAssetListItem.propTypes = {
  item: PropTypes.object,
  context: PropTypes.array,
  list: PropTypes.bool,
}

ContentAssetListItem.defaultProps = {
  item: {},
  context: [],
  list: false,
}

export default ContentAssetListItem
