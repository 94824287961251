import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Step from 'presentational/Step'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Spacer = styled.div`
  width: 40px;
  height: 1px;
  background: white;
`

/**
 * @description A list of steps in a horizontal line. Creates a wizard-like workflow.
 * @author Jan Mägdefrau
 */
class Stepper extends React.Component {
  renderSteps() {
    const stepElements = []
    const { steps, switchStep, activeStep, finishedSteps, errors } = this.props

    steps.map((step, index) => {
      stepElements.push(
        <Step
          key={stepElements.length}
          title={step}
          onClick={() => switchStep(index)}
          active={index === activeStep}
          index={index + 1}
          color={'#c4944d'}
          finished={finishedSteps.includes(index)}
          error={errors.includes(index)}
        />
      )

      if (index + 1 < steps.length) {
        stepElements.push(<Spacer key={stepElements.length} />)
      }
    })

    return stepElements
  }

  render() {
    return <Container>{this.renderSteps()}</Container>
  }
}

Stepper.propTypes = {
  steps: PropTypes.array,
  switchStep: PropTypes.func,
  activeStep: PropTypes.number,
  finishedSteps: PropTypes.array,
  errors: PropTypes.array,
}

Stepper.defaultProps = {
  steps: [],
  switchStep: () => {},
  activeStep: 0,
  finishedSteps: [],
  errors: [],
}

/** @component */
export default Stepper
