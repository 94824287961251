import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

export default class ApiContainer extends Container {
  state = {
    data: null,
    webhookData: null,

    getLoading: false,
    getError: null,

    requestLoading: false,
    requestError: null,

    updateWebhookLoading: false,
    updateWebhookError: null,

    getWebhookLoading: false,
    getWebookError: null,

    testWebhookLoading: false,
    testWebhookError: null,
  }

  getApiKey = async () => {
    try {
      await this.setState({ getLoading: true, getError: null })

      const res = await apiClient.get('api_key')

      await this.setState({ getLoading: false, data: res.data })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getLoading: false,
        getError: handleFailure(e, true),
      })

      throw e
    }
  }

  generateApiKey = async () => {
    try {
      await this.setState({
        requestLoading: true,
        requestError: null,
      })

      const res = await apiClient.get('api_key/generate')

      await this.setState({
        requestLoading: false,
        data: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        requestLoading: false,
        requestError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateWebhook = async webhook => {
    try {
      await this.setState({
        updateWebhookLoading: true,
        updateWebhookError: null,
      })

      const res = await apiClient.post('webhook', webhook)

      await this.setState({
        updateWebhookLoading: false,
        webhookData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        updateWebhookLoading: false,
        updateWebhookError: handleFailure(e, true),
      })

      throw e
    }
  }

  testWebhook = async () => {
    try {
      await this.setState({
        testWebhookLoading: true,
        testWebhookError: null,
      })

      const res = await apiClient.get('webhook/test')

      await this.setState({
        testWebhookLoading: false,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        testWebhookLoading: false,
        testWebhookError: handleFailure(e, true),
      })

      throw e
    }
  }

  getWebhook = async () => {
    try {
      await this.setState({
        getWebhookLoading: true,
        getWebookError: null,
      })

      const res = await apiClient.get('webhook')

      await this.setState({
        getWebhookLoading: false,
        webhookData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getWebhookLoading: false,
        getWebookError: handleFailure(e, true),
      })

      throw e
    }
  }
}
