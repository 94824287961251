import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ChromePicker } from 'react-color'

import { shouldRenderError } from 'util'
import InputError from 'presentational/InputError'

import { withTranslation } from 'react-i18next'

const ColorField = styled.div`
  background: ${props => props.color};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #443c38;
  cursor: pointer;
  margin-right: 20px;
`

const Wrapper = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  position: relative;
`

const ColorTitle = styled.p`
  color: ${props => (props.light ? '#f1efed' : '#554c47')};
  font-size: 1em;
  white-space: nowrap;
`

const Popover = styled.div`
  position: absolute;
  margin-top: 5px;
  margin-left: ${props => (props.leftOrientation ? '-200px' : '')};
  z-index: 2;
`

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
`

const LeftInputError = styled(InputError)`
  text-align: left;
`

/**
 * The prop controlled speficies if the component itself should manage the state.
 * If true the parent component needs to store the state.
 *
 * @description Component that displays a colored div which will open a Color-Picker on click.
 * @author Jan Mägdefrau
 */
const ColorPickerSection = ({
  color,
  title,
  onSelectColor,
  leftOrientation,
  meta,
  controlled,
  light = false,
  t,
}) => {
  const [colorPickerVisibility, setColorPickerVisibility] = useState(false)
  const [selectedColor, setSelectedColor] = useState(color)

  const selectColor = color => {
    onSelectColor(color)
    if (!controlled) setSelectedColor(color.hex)
  }

  const renderError = () => {
    if (shouldRenderError(meta)) {
      const { error, submitError } = meta

      return <LeftInputError>{error || submitError}</LeftInputError>
    }

    return null
  }

  return (
    <Wrapper
      onClick={() =>
        !colorPickerVisibility ? setColorPickerVisibility(true) : null
      }
    >
      {title && <ColorTitle light={light}>{title}</ColorTitle>}
      <ColorField
        color={controlled ? color : selectedColor}
        title={t('default.color_picker')}
      />
      {colorPickerVisibility ? (
        <Popover leftOrientation={leftOrientation}>
          <Cover onClick={() => setColorPickerVisibility(false)} />
          <ChromePicker
            disableAlpha
            onChangeComplete={selectColor}
            color={controlled ? color : selectedColor}
          />
        </Popover>
      ) : null}
      {renderError()}
    </Wrapper>
  )
}

ColorPickerSection.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  onSelectColor: PropTypes.func,
  leftOrientation: PropTypes.bool,
  controlled: PropTypes.bool,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    data: PropTypes.object,
    dirty: PropTypes.bool,
    error: PropTypes.any,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitError: PropTypes.any,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
}

ColorPickerSection.defaultProps = {
  color: '',
  title: '',
  meta: {},
  onSelectColor: () => {},
  leftOrientation: false,
  controlled: false,
}

/** @component */
export default withTranslation('common')(ColorPickerSection)
