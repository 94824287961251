import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CheckIcon from 'react-icons/lib/md/check'
import CrossIcon from 'react-icons/lib/md/clear'

const Container = styled.div`
  color: white;
  display: flex;
  line-heigh: 20px;
  margin: 0 10px;
`

const IndexBatch = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: ${props => (props.finished ? '20px' : '14px')};
  line-height: ${props => (props.finished ? '20px' : '24px')};
  text-align: center;
  background: ${props => {
    if (props.finished) {
      return '#d36841'
    }

    return props.active ? props.color : '#9e9e9e'
  }};
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  padding-left: 10px;
  font-weight: ${props => (props.active ? '600' : 'normal')};
`

/**
 * @description Child Component of the Stepper that displays on section.
 * @author Jan Mägdefrau
 */
const Step = props => {
  return (
    <Container>
      <IndexBatch
        active={props.active}
        color={props.color}
        finished={props.finished}
      >
        {!props.finished && !props.error ? props.index : null}
        {props.finished && !props.error ? <CheckIcon /> : null}
        {props.error ? <CrossIcon /> : null}
      </IndexBatch>
      <Button onClick={props.onClick} active={props.active}>
        {props.title}
      </Button>
    </Container>
  )
}

Step.propTypes = {
  active: PropTypes.bool,
  finished: PropTypes.bool,
  color: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  error: PropTypes.bool,
}

Step.defaultProps = {
  active: false,
  finished: false,
  color: '#9e9e9e',
  index: 0,
  onClick: () => {},
  title: '',
  error: false,
}

/** @component */
export default Step
