import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import ExpandMore from 'react-icons/lib/md/expand-more'

const Wrapper = styled.div`
  width: ${props => (props.small ? '300px' : '450px')};
  margin-left: 20px;
  border-bottom: 2px solid #eeeeee;
  position: relative;
`

const Input = styled.input`
  width: 450px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 21px;
  padding-left: 16px;

  &[type='select'] {
    padding-left: 0px;
  }

  border: none;
  outline: none;

  color: ${props => props.theme.colors.text.normal};

  border-radius: 2px;
  font-size: 1em;

  :disabled {
    background: #f1efed;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.colors.text.normal};
    -webkit-box-shadow: 0 0 0px
      ${props => props.theme.colors.backgrounds.normal};
    transition: background-color 5000s ease-in-out 0s;
  }
`

const DropdownContainer = styled.div`
  position: absolute;

  display: ${props => (props.hide ? 'none' : 'block')};
  width: 450px;
  max-height: 225px;
  overflow-x: hidden;
  overflow-y: auto;
`

const DropdownMenuItem = styled.button`
  background-color: #e8e8e8;
  border-radius: 2px;
  width: 100%;
  border: none;
  text-align: left;

  color: #0b0b0b;
  font-size: 14px;
  line-height: 40px;

  white-space: nowrap;

  padding: 2.5px 10px;
  cursor: pointer;

  :hover {
    background-color: #d7d7d7;
  }
`

const DropdownIcon = styled(ExpandMore)`
  color: #554c47;
  cursor: pointer;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`

const SearchSelectInput = ({
  t,
  list,
  onSearch,
  onSelect,
  displayKey,
  placeholder,
  small = false,
}) => {
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [searchResults, setSearchResults] = useState(list)
  const [searchValue, setSearchValue] = useState('')
  const [selectedValue, setSelectedValue] = useState({
    '@id': undefined,
    title: t('dashboard.select_website_all'),
    url: t('dashboard.select_subsite_all'),
  })

  const wrapperRef = useRef(null)

  const handleClickOutside = event => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setMenuVisibility(false)
      setSearchResults(list)
      setSearchValue('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
  })

  const selectLatestWebsite = (items, selectedValue) => {
    if (selectedValue !== undefined) {
      return
    }
    let latestDate = null
    items.map(item => {
      const currentDate = new Date(item.createdAt)
      if (latestDate === null || currentDate > latestDate) {
        latestDate = currentDate
        setSelectedValue(item)
      }
    })
  }

  selectLatestWebsite(searchResults, selectedValue)

  const onSelectItem = item => {
    setSelectedValue(item)
    setMenuVisibility(false)
    onSelect(item)
  }

  const renderSelectMenu = () => {
    return (
      <DropdownContainer hide={!menuVisibility}>
        <DropdownMenuItem
          key={t('dashboard.select_website_all')}
          onClick={() =>
            onSelectItem({
              '@id': undefined,
              title: t('dashboard.select_website_all'),
              url: t('dashboard.select_subsite_all'),
            })
          }
        >
          {t('dashboard.select_website_all')}
        </DropdownMenuItem>
        {searchResults.map((item, index) => (
          <DropdownMenuItem key={index} onClick={() => onSelectItem(item)}>
            {item[displayKey]}
          </DropdownMenuItem>
        ))}
      </DropdownContainer>
    )
  }

  const onSearchChange = event => {
    const value = event.target.value
    setSearchValue(value)

    const searchResult = list.filter(item =>
      item[displayKey].toLowerCase().includes(value.toLowerCase())
    )
    setSearchResults(searchResult)

    onSearch(value)
  }

  const getInputValue = () => {
    if (menuVisibility) {
      return searchValue
    }

    if (selectedValue !== undefined) {
      return selectedValue[displayKey]
    }

    return ''
  }

  const openDropdown = () => {
    if (selectedValue !== undefined) setSearchValue(selectedValue[displayKey])

    setMenuVisibility(true)
  }

  return (
    <Wrapper innerRef={wrapperRef} small={small}>
      <InputContainer onClick={() => openDropdown()}>
        <Input
          placeholder={placeholder}
          onChange={e => onSearchChange(e)}
          value={getInputValue()}
        />
        <div>
          <DropdownIcon size={25} />
        </div>
      </InputContainer>
      {renderSelectMenu()}
    </Wrapper>
  )
}

SearchSelectInput.propTypes = {
  list: PropTypes.array,
  small: PropTypes.bool,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  displayKey: PropTypes.string,
}

SearchSelectInput.defaultProps = {
  list: [],
  onSearch: () => {},
  onSearch: () => {},
  placeholder: '',
  displayKey: '',
  small: false,
}

export default withTranslation('common')(SearchSelectInput)
