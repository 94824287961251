import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'

import LabelsTable from 'functional/LabelsTable'
import { NotificationContext } from 'functional/NotificationProvider'
import CreateLabelModal from 'functional/CreateLabelModal'

const LabelListScreen = () => {
  const { t, i18n } = useTranslation('common')

  return (
    <LoggedInScreenContainer>
      <HeaderContainer>
        <H1>{t('labels.title')}</H1>
        <HeaderButtonContainer>
          <NavLink to="/settings">
            <Button kind="secondary">{t('default.back_to_overview')}</Button>
          </NavLink>
          <NotificationContext.Consumer>
            {context => (
              <Button
                bold
                onClick={() => {
                  context.methods.openModal(() => (
                    <CreateLabelModal
                      toggleTopNotification={
                        context.methods.toggleTopNotification
                      }
                      onClose={context.methods.closeModal}
                    />
                  ))
                }}
              >
                + {t('labels.add_button')}
              </Button>
            )}
          </NotificationContext.Consumer>
        </HeaderButtonContainer>
      </HeaderContainer>
      <SubHeaderContainer>
        <Paragraph>{t('labels.sub_header_description')}</Paragraph>
      </SubHeaderContainer>
      <ContentContainer>
        <LabelsTable />
      </ContentContainer>
    </LoggedInScreenContainer>
  )
}

export default withRouter(LabelListScreen)
