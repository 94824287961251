import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

export default class HelpContainer extends Container {
  state = {
    sendLoading: false,
    sendError: null,
  }

  sendContactMessage = async data => {
    try {
      await this.setState({ sendLoading: true, sendError: null })

      await apiClient.post('/help/contact', data)

      await this.setState({ sendLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        sendLoading: false,
        sendError: handleFailure(e, true),
      })

      throw e
    }
  }
}
