import React, { Component } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import documentationDataDE from '../../../translation/de/documentation.json'
import documentationDataEN from '../../../translation/en/documentation.json'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import CollapsibleNavigationList from 'presentational/CollapsibleNavigationList'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'

const NavigationContainer = styled.div`
  width: 300px;
`

const DocumentationContainer = styled.div`
  width: calc(100% - 300px);
  padding: 0 48px;
`

const StyledContentContainer = styled(ContentContainer)`
  padding: 48px;
  display: flex;
`

const StyledH2 = styled(H2)`
  margin-top: 0px;
`

const Content = styled.p`
  font-size: 14px;
  color: #554c47;
`

class HelpDocumentationScreen extends Component {
  constructor(props) {
    super(props)

    const { i18n } = this.props

    this.state = {
      subMenuIndex: 0,
      itemIndex: 0,
      listData:
        i18n.language === 'german' ? documentationDataDE : documentationDataEN,
    }
  }

  handleMenuChange(subMenuIndex, itemIndex) {
    this.setState({
      subMenuIndex,
      itemIndex,
    })
  }

  getActiveItem() {
    return this.state.listData[this.state.subMenuIndex].items[
      this.state.itemIndex
    ]
  }

  render() {
    const { t } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('help.documentation_site_title')}</H1>
          <HeaderButtonContainer>
            <NavLink to="/support/help">
              <Button kind="secondary">{t('default.back_to_overview')}</Button>
            </NavLink>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('help.documentation_site_description')}</Paragraph>
        </SubHeaderContainer>
        <StyledContentContainer>
          <NavigationContainer>
            <CollapsibleNavigationList
              items={this.state.listData}
              defaultActiveSubMenu={this.state.subMenuIndex}
              defaultActiceItem={this.state.itemIndex}
              onChangeActiveIndex={(subMenuIndex, itemIndex) =>
                this.handleMenuChange(subMenuIndex, itemIndex)
              }
            />
          </NavigationContainer>
          <DocumentationContainer>
            <StyledH2>{this.getActiveItem().title}</StyledH2>
            <Content
              dangerouslySetInnerHTML={{ __html: this.getActiveItem().content }}
            />
          </DocumentationContainer>
        </StyledContentContainer>
      </LoggedInScreenContainer>
    )
  }
}

export default withTranslation('common')(HelpDocumentationScreen)
