import React, { Component } from 'react'
import { Offline, Online } from 'react-detect-offline'

import OfflineIndicator from 'presentational/OfflineIndicator'

class OfflineIndicatorProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      online: true,
      isOpen: false,
    }
  }

  autoClose() {
    let { isOpen } = this.state
    isOpen = false
    this.setState({ isOpen })
  }

  render() {
    const { online, isOpen } = this.state
    const { children } = this.props
    return (
      <OfflineIndicatorContext.Provider
        value={{ state: this.state, toggleOnline: this.toggleOnline }}
      >
        {isOpen && (
          <Online
            onChange={e => {
              if (online && e) this.autoClose()
            }}
          />
        )}
        <Offline onChange={() => this.setState({ isOpen: true })}>
          <OfflineIndicator online={false} />
        </Offline>
        {children}
      </OfflineIndicatorContext.Provider>
    )
  }
}

/** @component */
export default OfflineIndicatorProvider

/** @context */
export const OfflineIndicatorContext = React.createContext()
