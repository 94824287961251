import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { shouldRenderError } from 'util'
import InputError from '../InputError'

import queries from 'util/mediaQueries'

const ContainerInlineStyled = css`
  margin-left: ${props => props.theme.formInlineTotalOffset};

  ${queries.desktop`
    margin-left: 0px;
  `};
`

const Container = styled.div`
  ${props => !props.noInline && ContainerInlineStyled};
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 2px;
  border: 1px solid lightgray;
  transition: background ease 0.1s, border-color ease 0.1s;

  :after {
    content: '';
    opacity: 0;
    position: absolute;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    transition: opacity ease 0.1s;
  }
`

const InnerContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 40px;
  height: 28px;
  color: ${props =>
    props.dark ? props.theme.colors.text.dark : props.theme.colors.text.normal};
  font-size: 1em;
  user-select: none;
  cursor: pointer;

  &:hover ${Checkmark} {
    background: lightgray;
    transition: background ease 0.1s;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + ${Checkmark} {
    background: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    transition: background ease 0.1s, border-color ease 0.1s;

    :after {
      opacity: 1;
      transition: opacity ease 0.1s;
    }
  }

  &:focus + ${Checkmark} {
    outline: auto 5px -webkit-focus-ring-color;
  }
`

const LeftInputError = styled(InputError)`
  text-align: left;
`

/**
 * An checkbox component. Compliant with final-form props.
 * @version 1.0.0
 * @author Stjepan Golemac
 */
const CheckboxInput = props => {
  return (
    <Container noInline={props.noInline}>
      <InnerContainer {...props}>
        <Input type="checkbox" {...props} {...props.input} />
        <Checkmark
          style={{
            ...(shouldRenderError(props.meta)
              ? {
                  borderBottomColor: 'red',
                  borderBottomWidth: '2px',
                }
              : {}),
          }}
        />
        {props.label}
      </InnerContainer>
      {shouldRenderError(props.meta) && (
        <LeftInputError>
          {props.meta.error || props.meta.submitError}
        </LeftInputError>
      )}
    </Container>
  )
}

CheckboxInput.propTypes = {
  label: PropTypes.string,
  noInline: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    data: PropTypes.object,
    dirty: PropTypes.bool,
    error: PropTypes.any,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitError: PropTypes.any,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
}

CheckboxInput.defaultProps = {
  noInline: false,
  label: '',
}

/** @component */
export default CheckboxInput
