import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field, FormSpy } from 'react-final-form'

import H1 from 'presentational/H1'
import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import TextInput from 'presentational/TextInput'
import FormError from 'presentational/FormError'
import SelectInput from 'presentational/SelectInput'
import DangerButton from 'presentational/DangerButton'
import PrimaryButton from 'presentational/PrimaryButton'
import AuthFormLoader from 'presentational/AuthFormLoader'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import FormInlineWrapper from 'presentational/FormInlineWrapper'

import {
  HeaderContainer,
  HeaderButtonContainer,
  SubHeaderContainer,
} from 'presentational/ScreenLayoutContainers'

import ConfirmDeleteModal from 'functional/ConfirmDeleteModal'
import { NotificationContext } from 'functional/NotificationProvider'

import { isEmail, isSelected } from 'util'
import { cleanId } from 'util/api'

const WrapButton = styled(PrimaryButton)`
  width: auto;
  margin-right: 5px;
  height: 42px;
`

const WrapDangerButton = styled(DangerButton)`
  width: auto;
  margin-right: 5px;
`

const H3MarginBottom = styled(H3)`
  margin-bottom: 5px;
`
const CustomParagraph = styled(Paragraph)`
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 12px;
`

const ParagraphMarginBottom = styled(Paragraph)`
  margin-bottom: 32px;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const initialTransform = values => {
  if (!values) return undefined

  return {
    ...values,
    id: cleanId(values['@id']),
    sex: values.sex ? values.sex : 'divers',
    statusActive: values.status === 'enabled',
  }
}

const submitTransform = onSubmit => (values, ...rest) => {
  const transformed = {
    ...values,
    status: values.statusActive ? 'enabled' : 'disabled',
    sex: values.sex ? values.sex : 'divers',
    firstName: values.firstName ? values.firstName : '',
    lastName: values.lastName ? values.lastName : '',
    company: values.company ? values.company : '',
    phone: values.phone ? values.phone : '',
    individual: values.individual ? values.individual : '',
  }

  return onSubmit(transformed, ...rest)
}

/**
 * Add new contact component.
 *
 * @version 1.0
 * @author Adel Memariani
 */
const AddNewContactForm = props => {
  const { t, i18n } = useTranslation('common')

  return (
    <Form
      initialValues={initialTransform(props.data)}
      onSubmit={submitTransform(props.onSubmit)}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <HeaderContainer>
            <H1>{t('contacts.edit_contact_header')}</H1>
            <HeaderButtonContainer>
              <NavLink to="/contacts">
                <Button kind="secondary">
                  {t('default.back_to_overview')}
                </Button>
              </NavLink>
            </HeaderButtonContainer>
          </HeaderContainer>
          <SubHeaderContainer>
            <ParagraphMarginBottom>
              {t('contacts.edit_contact_description')}
            </ParagraphMarginBottom>
          </SubHeaderContainer>
          <SectionContainer>
            {/*<AccountStatusScreen /> */}
            <Section>
              <H3MarginBottom>
                {t('contacts.personal_details_header')}
              </H3MarginBottom>
              <CustomParagraph>
                {t('contacts.personal_details_description')}
              </CustomParagraph>
              <FormFieldWrapper>
                <Field
                  name="email"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.email')}
                  validate={isEmail}
                />
                <Field
                  name="sex"
                  component={SelectInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.gender')}
                  options={[
                    {
                      label: t('overlay_designer.gender-option_mr'),
                      value: 'male',
                    },
                    {
                      label: t('overlay_designer.gender-option_mrs'),
                      value: 'female',
                    },
                    {
                      label: t('overlay_designer.gender-option_d'),
                      value: 'divers',
                    },
                  ]}
                />
                <Field
                  name="firstName"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.firstname')}
                />
                <Field
                  name="lastName"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.lastname')}
                />
                <Field
                  name="company"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.company')}
                />
                <Field
                  name="phone"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.phone')}
                />
                <Field
                  name="individual"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  type="text"
                  label={t('default.individual')}
                />
                <Field
                  name="registeredAt"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  disabled
                  type="text"
                  label={t('default.created_at')}
                />
                <Field
                  name="id"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  disabled
                  type="text"
                  label={t('default.id')}
                />
              </FormFieldWrapper>
              <FormFieldWrapper>
                <Field
                  name="referer"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  disabled
                  type="text"
                  label={t('default.referer')}
                />
                <Field
                  name="city"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  disabled
                  type="text"
                  label={t('default.city')}
                />
                <Field
                  name="country"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  disabled
                  type="text"
                  label={t('default.country')}
                />
                <Field
                  name="client"
                  component={TextInput}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                  disabled
                  type="text"
                  label={t('default.client')}
                />
              </FormFieldWrapper>
            </Section>
            <Section>
              {submitting && <AuthFormLoader />}
              <FormInlineWrapper>
                {submitError && <FormError>{submitError}</FormError>}
              </FormInlineWrapper>
              <FormInlineWrapper>
                <FormSpy
                  subscription={{ values: true }}
                  render={({ values }) => (
                    <WrapButton type="submit" disabled={submitting}>
                      {t('contacts.save_contact')}
                    </WrapButton>
                  )}
                />
                {props.edit && (
                  <NotificationContext.Consumer>
                    {context => (
                      <WrapDangerButton
                        onClick={() =>
                          context.methods.openModal(() => (
                            <ConfirmDeleteModal
                              id={props.data['@id']}
                              onClose={context.methods.closeModal}
                              onDelete={props.onDelete}
                            />
                          ))
                        }
                      >
                        {t('contacts.delete_contact')}
                      </WrapDangerButton>
                    )}
                  </NotificationContext.Consumer>
                )}
              </FormInlineWrapper>
            </Section>
          </SectionContainer>
        </form>
      )}
    />
  )
}

AddNewContactForm.propTypes = {
  /** The submit handler */
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    address: PropTypes.string,
    postalcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    website: PropTypes.string,
  }),
}

AddNewContactForm.defaultProps = {
  /** The submit handler */
  onSubmit: () => {},
  data: {
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    address: '',
    postalcode: '',
    city: '',
    country: '',
    website: '',
  },
}

/** @component */
export default AddNewContactForm
