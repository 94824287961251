import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import Button from 'presentational/Button'
import PrimaryButton from 'presentational/PrimaryButton'
import TextInput from 'presentational/TextInput'
import FormError from 'presentational/FormError'

import { isEmpty } from 'util'
import ColorPickerSection from 'functional/ColorPickerSection'
import LabelsContainer from 'containers/LabelsContainer'
import queries from 'util/mediaQueries'
import { withHandlers } from 'util/form'

const ButtonContainer = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-top: 19px;

  ${queries.desktop`
    width: 100%;
  `};
`

const LoaderWithMargin = styled(Loader)`
  margin-right: 10px;
`

class EditLabelModal extends Component {
  componentDidMount() {
    this.props.getLabel(this.props.id)
  }

  render() {
    const { t } = this.props

    return (
      <Subscribe to={[LabelsContainer]}>
        {({ state: { details, getLoading }, getAllLabels, updateLabel }) => {
          if (getLoading) {
            return <Loader />
          }

          return (
            <Form
              onSubmit={withHandlers(updateLabel, [
                this.props.closeModal,
                getAllLabels,
              ])}
              initialValues={details}
              render={({ submitting, submitError, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <H3>{t('labels.edit_modal_header')}</H3>
                  <Paragraph>{t('labels.edit_modal_description')}</Paragraph>
                  <Field
                    name="name"
                    label="Name"
                    noInline
                    component={TextInput}
                    format={(value = '') => value.trim()}
                    formatOnBlur
                    validate={isEmpty}
                  />
                  <Field name="color">
                    {({ input, meta }) => {
                      return (
                        <ColorPickerSection
                          color={input.value}
                          title={t('default.color')}
                          onSelectColor={color => input.onChange(color.hex)}
                          meta={meta}
                        />
                      )
                    }}
                  </Field>

                  {submitError && <FormError>{submitError}</FormError>}
                  {submitting && <LoaderWithMargin />}
                  <ButtonContainer>
                    <PrimaryButton type="submit" disabled={submitting}>
                      {t('default.update')}
                    </PrimaryButton>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button onClick={this.props.closeModal} kind="secondary">
                      {t('default.cancel')}
                    </Button>
                  </ButtonContainer>
                </form>
              )}
            />
          )
        }}
      </Subscribe>
    )
  }
}

const EditLabelModalWrapper = props => (
  <Subscribe to={[LabelsContainer]}>
    {({ getLabel }) => <EditLabelModal {...props} getLabel={getLabel} />}
  </Subscribe>
)

EditLabelModal.propTypes = {
  id: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default withTranslation('common')(EditLabelModalWrapper)
