import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import WebFont from 'webfontloader'

import FormatShapesIcon from 'react-icons/lib/md/format-shapes'
import TextFieldsIcon from 'react-icons/lib/md/text-fields'
import ColorLensIcon from 'react-icons/lib/md/color-lens'
import FormatQuoteIcon from 'react-icons/lib/md/format-quote'

import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import { TabbedContentContainer } from 'presentational/ScreenLayoutContainers'

import {
  Designer,
  DesignerContainer,
  DesignerPreview,
  DesignerControlsContainer,
  DesignerFlexCenterBox,
} from 'presentational/Designer'

import DesignerControls from 'functional/DesignerControls'
import CookieBanner from 'external/CookieBanner'

const StyledButton = styled(Button)`
  width: auto;
  margin: 0 5px;
`

const ActionButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`

const LoaderWithMargin = styled(Loader)`
  margin-right: 5px;
`

const DesignerFlexBox = styled(DesignerFlexCenterBox)`
  align-items: ${props => (props.top ? 'flex-start' : 'flex-end')};
  justify-content: ${props => (props.left ? 'flex-start' : 'flex-end')};
  padding: ${props => props.padding}px;
`

const DesignerPreviewWithoutPadding = styled(DesignerPreview)`
  padding: 0;
`

/**
 * @description Component contains Design for Cookie Banners.
 * @author Jan Mägdefrau
 */
class CookieBannerDesigner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.defaultData,
      initialData: { ...props.defaultData },
    }
  }

  changeControlValue(id, value) {
    const { data } = this.state

    if (id === 'fontFamily') {
      WebFont.load({
        google: {
          families: [value, value],
        },
      })
    }

    data[id] = value
    this.setState({ data })
    this.props.onChangeData(data)
  }

  resetToIntial() {
    const newData = { ...this.state.initialData }
    this.setState({ data: newData })
    this.props.onChangeData(newData)
  }

  render() {
    const { t } = this.props

    const controlTabs = [
      {
        title: t('cookie_designer.layout'),
        icon: <FormatShapesIcon />,
        controls: [
          {
            name: t('cookie_designer.position'),
            type: 'select',
            id: 'position',
            options: [
              { label: t('cookie_designer.right_bottom'), value: 'init' },
              { label: t('cookie_designer.left_bottom'), value: 'leftBottom' },
              { label: t('cookie_designer.bottom_bar'), value: 'bottomBar' },
              { label: t('cookie_designer.top_bar'), value: 'topBar' },
            ],
          },
          {
            name: t('cookie_designer.width'),
            type: 'pixel',
            id: 'width',
            min: 0,
          },
          {
            name: t('cookie_designer.margin'),
            type: 'pixel',
            id: 'margin',
            min: 0,
          },
          {
            name: t('cookie_designer.border_width'),
            type: 'pixel',
            id: 'borderWidth',
            min: 0,
          },
          {
            name: t('cookie_designer.border_radius'),
            type: 'pixel',
            id: 'borderRadius',
            min: 0,
          },
          {
            name: t('cookie_designer.button_border_radius'),
            type: 'pixel',
            id: 'buttonBorderRadius',
            min: 0,
          },
        ],
      },
      {
        title: t('cookie_designer.font'),
        icon: <TextFieldsIcon />,
        controls: [
          {
            name: t('cookie_designer.font_family'),
            type: 'select',
            id: 'fontFamily',
            options: [
              { label: 'Plex', value: 'Plex' },
              { label: 'Cabin', value: 'Cabin' },
              { label: 'Crimson Text', value: 'Crimson Text' },
              { label: 'Lato', value: 'Lato' },
              { label: 'Montserrat', value: 'Montserrat' },
              { label: 'Lobster Two', value: 'Lobster Two' },
              { label: 'Old Standard TT', value: 'Old Standard TT' },
              { label: 'Open Sans', value: 'Open Sans' },
              { label: 'Pacifico', value: 'Pacifico' },
              { label: 'Playfair Display', value: 'Playfair Display' },
              { label: 'Raleway', value: 'Raleway' },
              { label: 'Rubik', value: 'Rubik' },
              { label: 'Source Sans Pro', value: 'Source Sans Pro' },
              { label: 'Libre Franklin', value: 'Libre Franklin' },
              { label: 'Questrial', value: 'Questrial' },
            ],
          },
          {
            name: t('cookie_designer.title_font_size'),
            type: 'pixel',
            id: 'titleFontSize',
          },
          {
            name: t('cookie_designer.text_font_size'),
            type: 'pixel',
            id: 'textFontSize',
          },
        ],
      },
      {
        title: t('cookie_designer.colors'),
        icon: <ColorLensIcon />,
        controls: [
          {
            name: t('cookie_designer.background_color'),
            type: 'color',
            id: 'backgroundColor',
          },
          {
            name: t('cookie_designer.title_color'),
            type: 'color',
            id: 'titleColor',
          },
          {
            name: t('cookie_designer.text_color'),
            type: 'color',
            id: 'textColor',
          },
          {
            name: t('cookie_designer.button_color'),
            type: 'color',
            id: 'buttonColor',
          },
          {
            name: t('cookie_designer.button_text_color'),
            type: 'color',
            id: 'buttonTextColor',
          },
          {
            name: t('cookie_designer.border_color'),
            type: 'color',
            id: 'borderColor',
          },
        ],
      },
      {
        title: t('cookie_designer.texts'),
        icon: <FormatQuoteIcon />,
        controls: [
          { name: t('cookie_designer.title'), type: 'text', id: 'title' },
          { name: t('cookie_designer.text'), type: 'textarea', id: 'text' },
          {
            name: t('cookie_designer.allow_button_text'),
            type: 'text',
            id: 'allowButtonText',
          },
          {
            name: t('cookie_designer.deny_button_text'),
            type: 'text',
            id: 'denyButtonText',
          },
        ],
      },
    ]

    return (
      <TabbedContentContainer>
        <DesignerContainer>
          <Designer>
            <DesignerPreviewWithoutPadding>
              <DesignerFlexBox
                left={this.state.data.position === 'leftBottom'}
                top={this.state.data.position === 'topBar'}
                padding={this.state.data.margin}
              >
                <CookieBanner
                  width={this.state.data.width}
                  fontFamily={this.state.data.fontFamily}
                  textColor={this.state.data.textColor}
                  titleColor={this.state.data.titleColor}
                  backgroundColor={this.state.data.backgroundColor}
                  buttonColor={this.state.data.buttonColor}
                  buttonTextColor={this.state.data.buttonTextColor}
                  textFontSize={this.state.data.textFontSize}
                  titleFontSize={this.state.data.titleFontSize}
                  title={this.state.data.title}
                  text={this.state.data.text}
                  allowButtonText={this.state.data.allowButtonText}
                  denyButtonText={this.state.data.denyButtonText}
                  borderRadius={this.state.data.borderRadius}
                  borderWidth={this.state.data.borderWidth}
                  borderColor={this.state.data.borderColor}
                  buttonBorderRadius={this.state.data.buttonBorderRadius}
                  position={this.state.data.position}
                />
              </DesignerFlexBox>
            </DesignerPreviewWithoutPadding>
            <DesignerControlsContainer>
              <DesignerControls
                tabs={controlTabs}
                data={this.state.data}
                changeControl={(id, value) =>
                  this.changeControlValue(id, value)
                }
              />
            </DesignerControlsContainer>
          </Designer>
        </DesignerContainer>
        <ActionButtonContainer>
          {this.props.saveLoading && <LoaderWithMargin size="normal" />}
          <StyledButton
            size="small"
            onClick={() => {
              this.setState({ initialData: this.state.data })
              this.props.onSave()
            }}
          >
            {t('default.save')}
          </StyledButton>
          <StyledButton
            kind="dark"
            size="small"
            onClick={() => this.resetToIntial()}
          >
            {t('default.reset')}
          </StyledButton>
        </ActionButtonContainer>
      </TabbedContentContainer>
    )
  }
}

CookieBannerDesigner.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  defaultData: PropTypes.shape({
    width: PropTypes.number,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,
    fontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    titleFontSize: PropTypes.number,
    textFontSize: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    allowButtonText: PropTypes.string,
    denyButtonText: PropTypes.string,
    borderColor: PropTypes.string,
    buttonBorderRadius: PropTypes.number,
  }),
  onSave: PropTypes.func,
  saveLoading: PropTypes.bool,
}

CookieBannerDesigner.defaultProps = {
  defaultData: {
    width: 300,
    borderWidth: 0,
    borderRadius: 0,
    fontFamily: 'Ubuntu',
    backgroundColor: 'white',
    textColor: 'black',
    buttonColor: 'lightgray',
    buttonTextColor: 'black',
    titleColor: 'black',
    titleFontSize: 16,
    textFontSize: 13,
    title: 'This website uses cookies',
    text: 'We use them to give you the best experience.',
    allowButtonText: 'Allow',
    denyButtonText: 'Deny',
    borderColor: 'gray',
    buttonBorderRadius: 0,
  },
  onSave: () => {},
  saveLoading: false,
}

/** @component */
export default withTranslation('common')(CookieBannerDesigner)
