import React, { useEffect } from 'react'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { AreaChart, Area, XAxis, Tooltip, YAxis } from 'recharts'
import { withTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Loader from 'presentational/Loader'
import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import DashboardKPIWidget from 'presentational/DashboardKPIWidget'
import TopContentAssetsWidget from 'presentational/TopContentAssetsWidget'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import HorizontalMenu from 'presentational/HorizontalMenu'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import SearchSelectInput from 'functional/SearchSelectInput'

import WebsiteContainer from 'containers/WebsiteContainer'
import ContentAssetContainer from 'containers/ContentAssetContainer'
import AnalyticsContainer from 'containers/AnalyticsContainer'

import { cleanId } from 'util/api'

const HistoryChart = styled.div`
  width: 664px;
  height: 400px;
  background-color: white;
  margin: 0;
`

const FlexContentContainer = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;

  margin-top: 0;

  padding-top: ${props => (props.first ? '40px' : '0')};
`

const SearchSelectContainer = styled.div`
  display: flex;
`

const StyledH3 = styled(H3)`
  margin-top: 0;
`

const WidgetCard = styled.div`
  background-color: white;
  margin-right: 20px;
  padding: 40px 30px;
  margin-bottom: 20px;
  flex: 1 1 0px;
`

const MarginLoader = styled(Loader)`
  margin-left: 20px;
`

class DashboardScreen extends React.Component {
  constructor() {
    super()

    this.state = {
      website: undefined,
      subSite: undefined,
      subSites: [],
      visitTimeTab: 0,
      visitsTab: 0,
      leadsTab: 0,
      topAssetsTab: 0,
      chartTab: 0,
      updateSubSiteMenu: false,
    }
  }
  componentDidMount() {
    this.props.getWebsites()
    this.props.getAnalytics()
    this.props.getContentAssetAnalytics()
    this.props.getChartAnalytics()
  }

  render() {
    const {
      t,
      getContentAssetAnalytics,
      websitesData,
      websitesLoading,
      getAnalytics,
      analyticsData,
      chartAnalyticsData,
      getChartAnalytics,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('dashboard.title')}</H1>
          <SearchSelectContainer>
            {websitesLoading || websitesData === null ? (
              <MarginLoader />
            ) : (
              <SearchSelectInput
                small
                list={websitesData}
                onSelect={item => {
                  const id = cleanId(item['@id'])
                  getAnalytics('today', id)
                  getContentAssetAnalytics('today', id)
                  getChartAnalytics('week', id)

                  this.setState({
                    website: id,
                    subSite: undefined,
                    visitTimeTab: 0,
                    visitsTab: 0,
                    leadsTab: 0,
                    topAssetsTab: 0,
                    chartTab: 0,
                    subSites: item.subSites,
                    updateSubSiteMenu: true,
                  })

                  setTimeout(
                    () => this.setState({ updateSubSiteMenu: false }),
                    50
                  )
                }}
                displayKey="title"
                placeholder={t('dashboard.select_website')}
              />
            )}
            {!websitesLoading &&
              websitesData !== null &&
              !this.state.updateSubSiteMenu && (
                <SearchSelectInput
                  list={this.state.subSites}
                  onSelect={item => {
                    getAnalytics(
                      'today',
                      this.state.website,
                      undefined,
                      item.id
                    )
                    getContentAssetAnalytics(
                      'today',
                      this.state.website,
                      item.id
                    )
                    getChartAnalytics('week', this.state.website, item.id)
                    this.setState({
                      subSite: item.id,
                      visitTimeTab: 0,
                      visitsTab: 0,
                      leadsTab: 0,
                      topAssetsTab: 0,
                      chartTab: 0,
                    })
                  }}
                  displayKey="url"
                  placeholder={t('dashboard.select_sub_site')}
                />
              )}
          </SearchSelectContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('dashboard.sub_header_description')}</Paragraph>
        </SubHeaderContainer>
        <FlexContentContainer first>
          <DashboardKPIWidget
            title={t('dashboard.visits_title')}
            mainNumber={analyticsData ? analyticsData.visits : 0}
            mainNumberDescription={t('dashboard.visits_number_description')}
            description={t('dashboard.visits_description')}
            activeTab={this.state.visitsTab}
            onChangeTab={(timespan, tabIndex) => {
              getAnalytics(
                timespan,
                this.state.website,
                'visits',
                this.state.subSite
              )
              this.setState({ visitsTab: tabIndex })
            }}
          />
          <DashboardKPIWidget
            title={t('dashboard.duration_title')}
            time={analyticsData ? analyticsData.visitTime : 0}
            description={t('dashboard.duration_description')}
            activeTab={this.state.visitTimeTab}
            secondString={t('dashboard.duration_seconds')}
            minuteString={t('dashboard.duration_minutes')}
            onChangeTab={(timespan, tabIndex) => {
              getAnalytics(
                timespan,
                this.state.website,
                'visitTime',
                this.state.subSite
              )
              this.setState({ visitTimeTab: tabIndex })
            }}
          />
          <DashboardKPIWidget
            title={t('dashboard.leads_title')}
            mainNumber={analyticsData ? analyticsData.leads : 0}
            mainNumberDescription={t('dashboard.leads_number_description')}
            description={t('dashboard.leads_description')}
            activeTab={this.state.leadsTab}
            onChangeTab={(timespan, tabIndex) => {
              getAnalytics(
                timespan,
                this.state.website,
                'leads',
                this.state.subSite
              )
              this.setState({ leadsTab: tabIndex })
            }}
          />
        </FlexContentContainer>

        <FlexContentContainer>
          <WidgetCard>
            <StyledH3>{t('dashboard.chart_title')}</StyledH3>
            <Paragraph>{t('dashboard.chart_description')}</Paragraph>
            <div>
              <HorizontalMenu
                kind="mediumsmall"
                onChange={changedTab => {
                  const type = ['week', 'month', 'year']
                  getChartAnalytics(
                    type[changedTab],
                    this.state.website,
                    this.state.subSite
                  )
                  this.setState({ chartTab: changedTab })
                }}
                activeTab={this.state.chartTab}
                underlineBackground
                tabs={[
                  { name: t('dashboard.week') },
                  { name: t('dashboard.month') },
                  { name: t('dashboard.year') },
                ]}
              />
            </div>
            <HistoryChart>
              <AreaChart
                width={664}
                height={400}
                data={chartAnalyticsData}
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                padding={{ top: 40, right: 0, left: 30, bottom: 40 }}
              >
                <Area
                  type="monotone"
                  dataKey="converts"
                  stackId="2"
                  stroke="#d36841"
                  fill="#d36841"
                />
                <Area
                  type="monotone"
                  dataKey="displays"
                  stackId="2"
                  stroke="#c4944d"
                  fill="#c4944d"
                />
                <Tooltip
                  formatter={(value, name, props) => {
                    if (name === 'converts') {
                      return [value, t('dashboard.chart_converts')]
                    }
                    return [value, t('dashboard.chart_displays')]
                  }}
                  labelFormatter={label => {
                    if (this.state.chartTab === 2) {
                      return t('dashboard.chart_week') + ' ' + label
                    }
                    return label
                  }}
                />
                <XAxis dataKey="date" hide />
              </AreaChart>
            </HistoryChart>
          </WidgetCard>
          <TopContentAssetsWidget
            getData={getContentAssetAnalytics}
            website={this.state.website}
            activeTab={this.state.topAssetsTab}
            onChangeTab={(timespan, tabIndex) => {
              getContentAssetAnalytics(
                timespan,
                this.state.website,
                this.state.subSite
              )
              this.setState({ topAssetsTab: tabIndex })
            }}
          />
        </FlexContentContainer>
      </LoggedInScreenContainer>
    )
  }
}

const DashboardScreenWrapper = props => (
  <Subscribe to={[ContentAssetContainer, AnalyticsContainer]}>
    {(
      { getContentAssetAnalytics },
      {
        getAnalytics,
        getChartAnalytics,
        state: { analyticsData, getAnalyticsLoading, chartAnalyticsData },
      }
    ) => (
      <Subscribe to={[WebsiteContainer]}>
        {({ state: { websitesLoading, websitesData }, getWebsites }) => (
          <DashboardScreen
            analyticsData={analyticsData}
            getAnalyticsLoading={getAnalyticsLoading}
            websitesLoading={websitesLoading}
            websitesData={websitesData}
            getWebsites={getWebsites}
            getContentAssetAnalytics={getContentAssetAnalytics}
            getAnalytics={getAnalytics}
            getChartAnalytics={getChartAnalytics}
            chartAnalyticsData={chartAnalyticsData}
            {...props}
          />
        )}
      </Subscribe>
    )}
  </Subscribe>
)

export default withTranslation('common')(DashboardScreenWrapper)
