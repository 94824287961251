import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Button from 'presentational/Button'

import NameFormSection from 'functional/NameFormSection'
import GenderFormSection from 'functional/GenderFormSection'
import CompanyFormSection from 'functional/CompanyFormSection'
import PhoneFormSection from 'functional/PhoneFormSection'

const FieldContainer = styled.div`
  margin: 50px 0;
`

const ButtonContainer = styled.div`
  width: 200px;
  margin-left: 120px;
`

/**
 * @description Form Component for the specification of user information in the oboarding wizard.
 * @author Jan Mägdefrau
 */
const OnboardingWizardUserProfileForm = props => {
  const { t } = useTranslation('common')
  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.data}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer>
            <GenderFormSection dark noInline required />
            <NameFormSection dark noInline required />
            <CompanyFormSection dark noInline required />
            <PhoneFormSection dark noInline />
          </FieldContainer>
          <Button kind="bold" submit disabled={submitting} size="normal">
            {t('onboarding_wizard.next')}
          </Button>
        </form>
      )}
    />
  )
}

OnboardingWizardUserProfileForm.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    sex: PropTypes.oneOf(['', 'male', 'female', 'divers']),
    company: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
}

OnboardingWizardUserProfileForm.defaultProps = {
  onSubmit: () => {},
  data: {
    sex: '',
    company: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
  },
}

/** @component */
export default OnboardingWizardUserProfileForm
