import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import WebFont from 'webfontloader'

import TextFieldsIcon from 'react-icons/lib/md/text-fields'
import ColorLensIcon from 'react-icons/lib/md/color-lens'
import FormatShapesIcon from 'react-icons/lib/md/format-shapes'
import FormatQuoteIcon from 'react-icons/lib/md/format-quote'
import MdRateReview from 'react-icons/lib/md/rate-review'

import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import { TabbedContentContainer } from 'presentational/ScreenLayoutContainers'

import DesignerControls from 'functional/DesignerControls'
import ContentAsset from 'external/ContentAssetOverlay'

import {
  Designer,
  DesignerContainer,
  DesignerPreview,
  DesignerControlsContainer,
  DesignerFlexCenterBox,
} from 'presentational/Designer'

const StyledButton = styled(Button)`
  width: auto;
  margin: 0 5px;
`

const ActionButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`

const LoaderWithMargin = styled(Loader)`
  margin-right: 5px;
`

const DesignerFlexBox = styled(DesignerFlexCenterBox)`
  align-items: ${props => (props.top ? 'flex-start' : 'flex-end')};
  justify-content: ${props => (props.left ? 'flex-start' : 'flex-end')};
  padding: ${props => props.padding}px;
`

const DesignerPreviewWithoutPadding = styled(DesignerPreview)`
  padding: 0;
`

/**
 * @description Component contains Designer where users can customize Content Asset Overlays.
 * @author Jan Mägdefrau
 */
class ContentAssetOverlayDesigner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.defaultData,
      initialData: { ...props.defaultData },
      fullHeight: 600,
    }
  }

  componentDidMount() {
    if (
      this.state.data.image !== '' &&
      this.state.data.image !== undefined &&
      this.state.data.image !== 0 &&
      typeof this.state.data.image !== 'object'
    ) {
      this.props.getFile(this.state.data.image).then(res => {
        const base64 = res.base64
        const mimetype = res.mimetype

        const { data } = this.state

        data.image = {
          imageName: data.image,
          imageFile: `data:${mimetype};base64,${base64}`,
        }

        this.setState({ data })
      })
    }
  }

  changeControlValue(id, value) {
    const { data } = this.state

    if (id === 'fontFamily') {
      WebFont.load({
        google: {
          families: [value, value],
        },
      })
    }

    data[id] = value
    this.setState({ data })
    this.props.onChangeData(data)
  }

  resetToIntial() {
    const newData = { ...this.state.initialData }
    this.setState({ data: newData })
    this.props.onChangeData(newData)
  }

  render() {
    const { t } = this.props

    const controlTabs = [
      {
        title: t('overlay_designer.layout'),
        icon: <FormatShapesIcon />,
        controls: [
          {
            name: t('cookie_designer.position'),
            type: 'select',
            id: 'position',
            options: [
              { label: t('cookie_designer.right_bottom'), value: 'init' },
              { label: t('cookie_designer.left_bottom'), value: 'leftBottom' },
              { label: t('cookie_designer.bottom_bar'), value: 'bottomBar' },
              { label: t('cookie_designer.top_bar'), value: 'topBar' },
            ],
          },
          {
            name: t('cookie_designer.margin'),
            type: 'pixel',
            id: 'margin',
            min: 0,
          },
          { name: t('overlay_designer.width'), type: 'pixel', id: 'width' },
          // { name: 'Logo Höhe', type: 'pixel', id: 'logoHeight' },
          /*
          {
            name: t('overlay_designer.image_height'),
            type: 'pixel',
            id: 'imageHeight',
          },
          */
          {
            name: t('overlay_designer.border_width'),
            type: 'pixel',
            id: 'borderWidth',
          },
          {
            name: t('overlay_designer.border_radius'),
            type: 'pixel',
            id: 'borderRadius',
          },
          {
            name: t('overlay_designer.button_radius'),
            type: 'pixel',
            id: 'buttonRadius',
          },
          // { name: t('overlay_designer.image'), type: 'image', id: 'image' },
          {
            name: t('overlay_designer.image_position'),
            type: 'select',
            id: 'imagePosition',
            options: [
              { label: t('overlay_designer.left'), value: 'init' },
              { label: t('overlay_designer.right'), value: 'right' },
              { label: t('overlay_designer.bottom'), value: 'bottom' },
              { label: t('overlay_designer.between'), value: 'between' },
              { label: t('overlay_designer.top'), value: 'top' },
            ],
          },
          /*
          {
            name: t('overlay_designer.show_logo'),
            type: 'boolean',
            id: 'displayLogo',
          },
          */
        ],
      },
      {
        title: t('overlay_designer.font'),
        icon: <TextFieldsIcon />,
        controls: [
          {
            name: t('overlay_designer.font_family'),
            type: 'select',
            id: 'fontFamily',
            options: [
              { label: 'Plex', value: 'Plex' },
              { label: 'Cabin', value: 'Cabin' },
              { label: 'Crimson Text', value: 'Crimson Text' },
              { label: 'Lato', value: 'Lato' },
              { label: 'Montserrat', value: 'Montserrat' },
              { label: 'Lobster Two', value: 'Lobster Two' },
              { label: 'Old Standard TT', value: 'Old Standard TT' },
              { label: 'Open Sans', value: 'Open Sans' },
              { label: 'Pacifico', value: 'Pacifico' },
              { label: 'Playfair Display', value: 'Playfair Display' },
              { label: 'Raleway', value: 'Raleway' },
              { label: 'Rubik', value: 'Rubik' },
              { label: 'Source Sans Pro', value: 'Source Sans Pro' },
              { label: 'Libre Franklin', value: 'Libre Franklin' },
              { label: 'Questrial', value: 'Questrial' },
            ],
          },
        ],
      },
      {
        title: t('overlay_designer.colors'),
        icon: <ColorLensIcon />,
        controls: [
          {
            name: t('overlay_designer.background_color'),
            type: 'color',
            id: 'backgroundColor',
          },
          {
            name: t('overlay_designer.text_color'),
            type: 'color',
            id: 'textColor',
          },
          {
            name: t('overlay_designer.border_color'),
            type: 'color',
            id: 'borderColor',
          },
          {
            name: t('overlay_designer.button_color'),
            type: 'color',
            id: 'buttonColor',
          },
          {
            name: t('overlay_designer.button_text_color'),
            type: 'color',
            id: 'buttonTextColor',
          },
          /*
          {
            name: t('overlay_designer.label_color'),
            type: 'color',
            id: 'labelColor',
          },
          */
          {
            name: t('overlay_designer.input_background_color'),
            type: 'color',
            id: 'inputColor',
          },
          {
            name: t('overlay_designer.privacy_color'),
            type: 'color',
            id: 'privacyLinkColor',
          },
        ],
      },
      {
        title: t('overlay_designer.texts'),
        icon: <FormatQuoteIcon />,
        controls: [
          // {
          //   name: 'text',
          //   type: 'text',
          //   id: 'bannerText',
          // },
          // {
          //   name: 'title',
          //   type: 'text',
          //   id: 'bannerTitle',
          // },
          {
            name: t('overlay_designer.download_button_text'),
            type: 'text',
            id: 'downloadButtonText',
          },
          {
            name: t('overlay_designer.submit_button_text'),
            type: 'text',
            id: 'submitButtonText',
          },
          {
            name: t('overlay_designer.final_text'),
            type: 'textarea',
            id: 'finalText',
          },
          {
            name: t('overlay_designer.final_link_label'),
            type: 'text',
            id: 'finalLinkLabel',
          },
          {
            name: t('overlay_designer.privacy_text'),
            type: 'text',
            id: 'privacyText',
          },
          {
            name: t('overlay_designer.privacy_link'),
            type: 'text',
            id: 'linkPrivacy',
          },
          {
            name: t('overlay_designer.privacy_link_text'),
            type: 'text',
            id: 'linkPrivacyText',
          },
        ],
      },
      {
        title: t('overlay_designer.fields'),
        icon: <MdRateReview />,
        controls: [
          {
            name: t('overlay_designer.gender_field'),
            type: 'text',
            id: 'genderField',
            checkboxes: [
              {
                id: 'active',
                label: 'Use',
              },
            ],
          },
          {
            name: t('overlay_designer.firstName_field'),
            type: 'text',
            id: 'firstNameField',
            checkboxes: [
              {
                id: 'active',
                label: t('overlay_designer.active_state'),
              },
              {
                id: 'required',
                label: t('overlay_designer.required_state'),
              },
            ],
          },
          {
            name: t('overlay_designer.lastName_field'),
            type: 'text',
            id: 'lastNameField',
            checkboxes: [
              {
                id: 'active',
                label: t('overlay_designer.active_state'),
              },
              {
                id: 'required',
                label: t('overlay_designer.required_state'),
              },
            ],
          },
          {
            name: t('overlay_designer.company_field'),
            type: 'text',
            id: 'companyField',
            checkboxes: [
              {
                id: 'active',
                label: t('overlay_designer.active_state'),
              },
              {
                id: 'required',
                label: t('overlay_designer.required_state'),
              },
            ],
          },
          {
            name: t('overlay_designer.phone_field'),
            type: 'text',
            id: 'phoneField',
            checkboxes: [
              {
                id: 'active',
                label: t('overlay_designer.active_state'),
              },
              {
                id: 'required',
                label: t('overlay_designer.required_state'),
              },
            ],
          },
          {
            name: t('overlay_designer.individual_field'),
            type: 'text',
            id: 'individualField',
            checkboxes: [
              {
                id: 'active',
                label: t('overlay_designer.active_state'),
              },
              {
                id: 'required',
                label: t('overlay_designer.required_state'),
              },
            ],
          },
          {
            name: t('overlay_designer.email_field'),
            type: 'text',
            id: 'emailField',
          },
        ],
      },
    ]

    return (
      <TabbedContentContainer>
        <DesignerContainer>
          <Designer style={{ height: this.state.fullHeight }}>
            <DesignerPreviewWithoutPadding
              style={{ height: this.state.fullHeight }}
            >
              <DesignerFlexBox
                left={this.state.data.position === 'leftBottom'}
                top={this.state.data.position === 'topBar'}
                padding={this.state.data.margin}
              >
                <ContentAsset
                  text={t('overlay_designer.default_text')}
                  title={t('overlay_designer.default_title')}
                  finalText={this.state.data.finalText}
                  width={this.state.data.width}
                  fontFamily={this.state.data.fontFamily}
                  backgroundColor={this.state.data.backgroundColor}
                  textColor={this.state.data.textColor}
                  // labelColor={this.state.data.labelColor}
                  // logoHeight={this.state.data.logoHeight}
                  // assetImageHeight={this.state.data.imageHeight}
                  border={`${this.state.data.borderWidth}px solid ${this.state.data.borderColor}`}
                  borderRadius={`${this.state.data.borderRadius}px`}
                  buttonColor={this.state.data.buttonColor}
                  buttonRadius={this.state.data.buttonRadius}
                  buttonTextColor={this.state.data.buttonTextColor}
                  downloadButtonText={this.state.data.downloadButtonText}
                  imagePosition={this.state.data.imagePosition}
                  inputLabelText={this.state.data.inputLabelText}
                  submitButtonText={this.state.data.submitButtonText}
                  inputBackgroundColor={this.state.data.inputColor}
                  linkPrivacy={this.state.data.linkPrivacy}
                  privacyLinkColor={this.state.data.privacyLinkColor}
                  linkPrivacyText={this.state.data.linkPrivacyText}
                  // displayLogo={this.state.data.displayLogo === 1}
                  logoSrc={this.props.logoSrc}
                  privacyText={this.state.data.privacyText}
                  position={this.state.data.position}
                  emailField={this.state.data.emailField}
                  genderField={this.state.data.genderField}
                  firstNameField={this.state.data.firstNameField}
                  lastNameField={this.state.data.lastNameField}
                  companyField={this.state.data.companyField}
                  phoneField={this.state.data.phoneField}
                  individualField={this.state.data.individualField}
                  activeEmailField={this.state.data['active-emailField']}
                  activeGenderField={this.state.data['active-genderField']}
                  activeFirstNameField={
                    this.state.data['active-firstNameField']
                  }
                  activeLastNameField={this.state.data['active-lastNameField']}
                  activeCompanyField={this.state.data['active-companyField']}
                  activePhoneField={this.state.data['active-phoneField']}
                  activeIndividualField={
                    this.state.data['active-individualField']
                  }
                  requiredEmailField={this.state.data['required-emailField']}
                  requiredGenderField={this.state.data['required-genderField']}
                  requiredFirstNameField={
                    this.state.data['required-firstNameField']
                  }
                  requiredLastNameField={
                    this.state.data['required-lastNameField']
                  }
                  requiredCompanyField={
                    this.state.data['required-companyField']
                  }
                  requiredPhoneField={this.state.data['required-phoneField']}
                  requiredIndividualField={
                    this.state.data['required-individualField']
                  }
                  setFullHeight={height => {
                    if (height > 580) {
                      this.setState({ fullHeight: height + 20 })
                    }
                    if (this.state.fullHeight > 600 && height <= 580) {
                      this.setState({ fullHeight: 600 })
                    }
                  }}
                  preview
                />
              </DesignerFlexBox>
            </DesignerPreviewWithoutPadding>
            <DesignerControlsContainer>
              <DesignerControls
                tabs={controlTabs}
                data={this.state.data}
                changeControl={(id, value) => {
                  this.changeControlValue(id, value)
                }}
              />
            </DesignerControlsContainer>
          </Designer>
        </DesignerContainer>
        <ActionButtonContainer>
          {this.props.saveLoading && <LoaderWithMargin size="normal" />}
          <StyledButton
            size="small"
            onClick={() => {
              this.setState({ initialData: this.state.data })
              this.props.onSave()
            }}
          >
            {t('default.save')}
          </StyledButton>
          <StyledButton
            kind="dark"
            size="small"
            onClick={() => this.resetToIntial()}
          >
            {t('default.reset')}
          </StyledButton>
        </ActionButtonContainer>
      </TabbedContentContainer>
    )
  }
}

ContentAssetOverlayDesigner.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  defaultData: PropTypes.shape({
    backgroundColor: PropTypes.string,
    imageHeight: PropTypes.number,
    width: PropTypes.number,
    textColor: PropTypes.string,
    fontFamily: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,
    labelColor: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    downloadButtonText: PropTypes.string,
    inputLabelText: PropTypes.string,
    finalText: PropTypes.string,
    submitButtonText: PropTypes.string,
    inputColor: PropTypes.string,
    finalLinkLabel: PropTypes.string,
  }),
  saveLoading: PropTypes.bool,
}

ContentAssetOverlayDesigner.defaultProps = {
  defaultData: {
    backgroundColor: 'white',
    imageHeight: 150,
    width: 300,
    textColor: 'black',
    fontFamily: 'Ubuntu',
    buttonColor: 'lightgray',
    buttonTextColor: 'black',
    borderColor: 'white',
    borderWidth: 0,
    borderRadius: 0,
    labelColor: 'black',
    title: 'Example Title',
    text: 'Example Text',
    downloadButtonText: 'Download',
    finalText: 'Email sent.',
    inputLabelText: 'Email',
    submitButtonText: 'Submit',
    inputColor: 'white',
    finalLinkLabel: 'view more',
  },
  saveLoading: false,
}

/** @component */
export default withTranslation('common')(ContentAssetOverlayDesigner)
