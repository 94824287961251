import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import Button from 'presentational/Button'
import WebsiteFormSection from 'functional/WebsiteFormSection'
import ColorPickerSection from 'functional/ColorPickerSection'

import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import { isEmpty, isEmail } from 'util/validations'

import FileInput from 'functional/FileInput'

const FlexWrapper = styled.div`
  display: flex;
  margin-top: 50px;
`

const ButtonWithSpace = styled(Button)`
  margin: 0 5px;
`

const FieldContainer = styled.div`
  margin-top: 50px;
`

/**
 * @description Form Component for the creation of a website in the oboarding wizard.
 * @author Jan Mägdefrau
 */
const OnboardingWizardCreateWebsiteForm = ({
  data,
  onSubmit,
  onBack,
  onChange,
}) => {
  const { t } = useTranslation('common')

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer>
            <WebsiteFormSection
              required
              onChange={value => onChange(value)}
              value={data.website}
              dark
              noInline
            />
          </FieldContainer>
          <FormFieldWrapper>
            <Field
              name="mailAddress"
              component={TextInput}
              format={(value = '') => value.trim()}
              formatOnBlur
              type="email"
              label={t('default.email_sender_address')}
              noInline
              format={(value = '') => value.trim()}
              formatOnBlur
              dark
              validate={isEmpty && isEmail}
            />
          </FormFieldWrapper>
          <div>
            <Field name="primaryColor" validate={isEmpty}>
              {({ input, meta }) => {
                return (
                  <ColorPickerSection
                    color={input.value}
                    title={t('default.primary_color')}
                    onSelectColor={color => input.onChange(color.hex)}
                    meta={meta}
                    controlled
                    light
                  />
                )
              }}
            </Field>
            <Field name="backgroundColor" validate={isEmpty}>
              {({ input, meta }) => {
                return (
                  <ColorPickerSection
                    color={input.value}
                    title={t('default.background_color')}
                    onSelectColor={color => input.onChange(color.hex)}
                    meta={meta}
                    controlled
                    light
                  />
                )
              }}
            </Field>
            <Field name="textColor" validate={isEmpty}>
              {({ input, meta }) => {
                return (
                  <ColorPickerSection
                    color={input.value}
                    title={t('default.text_color')}
                    onSelectColor={color => input.onChange(color.hex)}
                    meta={meta}
                    controlled
                    light
                  />
                )
              }}
            </Field>
          </div>
          <FlexWrapper>
            <ButtonWithSpace
              kind="secondary"
              onClick={onBack}
              disabled={submitting}
              size="normal"
            >
              {t('default.back')}
            </ButtonWithSpace>
            <ButtonWithSpace
              kind="bold"
              submit
              disabled={submitting}
              size="normal"
            >
              {t('onboarding_wizard.next')}
            </ButtonWithSpace>
          </FlexWrapper>
        </form>
      )}
    />
  )
}

OnboardingWizardCreateWebsiteForm.propTypes = {
  defaultColors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({
    website: PropTypes.string,
  }),
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
}

OnboardingWizardCreateWebsiteForm.defaultProps = {
  defaultColors: ['red', 'blue', 'green'],
  data: {
    website: '',
  },
  onSubmit: () => {},
  onBack: () => {},
  onChange: () => {},
}

/** @component */
export default OnboardingWizardCreateWebsiteForm
