import React, { Component } from 'react'
import styled from 'styled-components'

export const DesignerContainer = styled.div`
  padding: 0px 0 20px 0;
`

export const Designer = styled.div`
  width: 100%;
  display: flex;
  height: 600px;
  background-color: #e7e7e7;
  transition: 0.2s;
`

export const DesignerPreview = styled.div`
  width: 100%;
  margin: auto;
  height: 600px;
  padding: 10px;
  overflow-y: auto;
  transition: 0.2s;
`

export const DesignerControlsContainer = styled.div`
  width: calc(100% / 3 * 1);
  height: 100%;
`

export const DesignerFlexCenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const DesignerButtonBox = styled.div`
  height: 50px;
  background: #eeeeef;
  display: flex;
  justify-content: center;
  align-items: center;
`
