import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`

const EntryName = styled.p`
  color: #f1efed;
  font-size: 1em;
  margin-right: 15px;
  margin-bottom: 0;
  margin-top: 0;
`

const ColorField = styled.div`
  background-color: ${props => props.color};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  align-self: flex-end;
`
/**
 * @description Component displays a color in the onboarding summary.
 * @author Jan Mägdefrau
 */
const OnboardignWizardSummaryColorEntry = ({ name, color }) => (
  <FlexBox>
    <EntryName>{name}:</EntryName>
    <ColorField color={color} />
  </FlexBox>
)

OnboardignWizardSummaryColorEntry.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
}

OnboardignWizardSummaryColorEntry.defaultProps = {
  name: '',
  color: 'red',
}

/** @component */
export default OnboardignWizardSummaryColorEntry
