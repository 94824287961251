import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import UserRouter from 'router/UserRouter'

import './index.css'
import './translation/i18n'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(<UserRouter />, document.getElementById('user'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
