import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import styled from 'styled-components'

import FileInput from 'functional/FileInput'
import ColorPickerSection from 'functional/ColorPickerSection'
import NumberSelector from 'functional/NumberSelector'
import SelectInput from 'presentational/SelectInput'
import TextInput from 'presentational/TextInput'
import CheckboxInput from 'presentational/CheckboxInput'

import { NotificationContext } from 'functional/NotificationProvider'

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`

const Content = styled.div`
  background-color: #f9f9f9;
  width: calc(100% - 72px);
  padding: 15px 20px;
  overflow-y: auto;
`

const Tabs = styled.div`
  background-color: #d6d9dc;
  width: 72px;
`

const Tab = styled.button`
  height: 72px;
  width: 100%;
  background-color: ${props => (props.active ? '#f9f9f9' : '#d6d9dc')};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: ${props => (props.active ? '#f9f9f9' : '#c0c5c9')};
  }
`

const IconContainer = styled.span`
  font-size: 24px;
  color: #73797f;
  margin-bottom: 4px;
`

const TabTextContainer = styled.span`
  color: #485056;
  font-size: 11px;
`

const ControlName = styled.p`
  margin: 0;
  font-size: 12px;
  color: #8f9699;
  font-weight: 600;
  min-width: 120px;
`

const Control = styled.div`
  margin-left: 10px;
  padding: 15px 0;
  box-sizing: content-box;
  line-height: 30px;
  border-bottom: ${props => (props.last ? '' : '1px solid #e1e4e7')};
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.below ? 'column' : 'initial')};
  flex-wrap: wrap;
  align-items: center;
`

const ContentInput = styled.div`
  display: flex;
  align-items: center;
`

const Textarea = styled.textarea`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e1e4e7;
  width: 100%;
  padding: 16px;
  height: 200px;
  font-family: Arial;
`

const CheckboxContainer = styled.div`
  display: block;
  width: 100%;
`

class DesignerControls extends Component {
  constructor() {
    super()

    this.state = {
      activeTab: 0,
    }
  }

  switchTab(index) {
    this.setState({ activeTab: index })
  }

  renderControl(control, last = false, context) {
    let input

    const data = this.props.data[control.id]
    const { type, id, max, min, name, options } = control

    switch (type) {
      case 'color':
        input = (
          <ColorPickerSection
            color={data ? data : 'red'}
            leftOrientation
            onSelectColor={color => this.props.changeControl(id, color.hex)}
            controlled
          />
        )
        break
      case 'pixel':
      case 'number':
        input = (
          <NumberSelector
            value={data ? data : 0}
            dataType={type === 'pixel' ? 'px' : ''}
            max={max}
            min={min}
            onChange={value => this.props.changeControl(id, value)}
          />
        )
        break
      case 'select':
        input = (
          <SelectInput
            options={options}
            input={{
              name: id,
              value: data,
            }}
            onChange={value => this.props.changeControl(id, value)}
          />
        )
        break
      case 'text':
        input = (
          <TextInput
            value={data}
            onChange={event => this.props.changeControl(id, event.target.value)}
          />
        )
        break
      case 'boolean':
        input = (
          <CheckboxInput
            noInline
            input={{
              checked: data === 1,
              name: 'Checkbox',
              onChange: event =>
                this.props.changeControl(id, data === 0 ? 1 : 0),
            }}
          />
        )
        break
      case 'textarea':
        input = (
          <Textarea
            onChange={event => this.props.changeControl(id, event.target.value)}
            value={data}
          />
        )
        break
      case 'image':
        input = (
          <FileInput
            file={data ? data.imageFile : ''}
            crop={{ active: true, ratio: NaN }}
            fileName={data ? data.imageName : ''}
            onUpload={(imageName, imageFile) => {
              this.props.changeControl(id, { imageFile, imageName })
            }}
            onDelete={e => {
              this.props.changeControl(id, {})
            }}
            onUploadFailed={() => {
              context.methods.toggleTopNotification(
                'error',
                this.props.t('websites.edit_logo_upload_failed')
              )
            }}
          />
        )
        break
      default:
        input = <span />
        break
    }

    return (
      <Control
        key={id}
        last={last}
        below={type === 'text' || type === 'textarea'}
      >
        <ControlName>{name}</ControlName>
        <ContentInput>{input}</ContentInput>

        {control.checkboxes &&
          control.checkboxes.map(checkbox => (
            <CheckboxContainer key={Math.random()}>
              <input
                type="checkbox"
                id={`${checkbox.id}-${id}`}
                onChange={event =>
                  this.props.changeControl(
                    `${checkbox.id}-${id}`,
                    event.target.checked
                  )
                }
                checked={
                  this.props.data[`${checkbox.id}-${id}`] === 'true' ||
                  this.props.data[`${checkbox.id}-${id}`]
                }
              />
              <label htmlFor={`${checkbox.id}-${name}`}>{checkbox.label}</label>
            </CheckboxContainer>
          ))}
      </Control>
    )
  }

  renderContent(context) {
    const controlsData = this.props.tabs[this.state.activeTab].controls

    const controls = controlsData.map((control, index) =>
      this.renderControl(control, index === controlsData.length - 1, context)
    )

    return <Content>{controls}</Content>
  }

  renderTab(index, icon, title, onClick) {
    return (
      <Tab
        active={this.state.activeTab === index}
        key={index}
        onClick={onClick}
      >
        <IconContainer>{icon}</IconContainer>
        <TabTextContainer>{title}</TabTextContainer>
      </Tab>
    )
  }

  renderTabs() {
    const tabs = this.props.tabs.map((tab, index) =>
      this.renderTab(index, tab.icon, tab.title, () => this.switchTab(index))
    )

    return <Tabs>{tabs}</Tabs>
  }

  render() {
    return (
      <Container>
        <NotificationContext.Consumer>
          {context => (
            <React.Fragment>
              {this.renderContent(context)}
              {this.renderTabs()}
            </React.Fragment>
          )}
        </NotificationContext.Consumer>
      </Container>
    )
  }
}

DesignerControls.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.element,
      controls: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
          id: PropTypes.string,
          options: PropTypes.array,
        })
      ),
    })
  ),
}

export default withTranslation('common')(DesignerControls)
