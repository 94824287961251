import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import DangerButton from 'presentational/DangerButton'
import { LoaderContainer } from 'presentational/ScreenLayoutContainers'

import { withHandlers } from 'util/form'
import ContentAssetContainer from 'containers/ContentAssetContainer'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  button {
    max-width: 35%;
  }
`

const ConfirmContentAssetDeleteModal = ({
  deleteContentAsset,
  closeModal,
  toggleTopNotification,
  id,
  title,
}) => {
  const { t, i18n } = useTranslation('common')

  return (
    <React.Fragment>
      <H3>{t('content_assets.delete_modal_header', { title })}</H3>
      <Paragraph>{t('content_assets.delete_modal_description')}</Paragraph>
      <ButtonWrapper>
        <Subscribe to={[ContentAssetContainer]}>
          {({ state: { deleteLoading } }) => (
            <React.Fragment>
              <DangerButton
                onClick={withHandlers(
                  () => deleteContentAsset(id),
                  () => {
                    toggleTopNotification(
                      'success',
                      t('content_assets.delete_modal_success')
                    )
                    closeModal()
                  },
                  () => {
                    toggleTopNotification(
                      'error',
                      t('content_assets.delete_modal_fail')
                    )
                    closeModal()
                  }
                )}
              >
                {t('default.delete')}
              </DangerButton>
              {deleteLoading && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </React.Fragment>
          )}
        </Subscribe>
        <Button kind="secondary" onClick={() => closeModal()}>
          {t('default.cancel')}
        </Button>
      </ButtonWrapper>
    </React.Fragment>
  )
}

ConfirmContentAssetDeleteModal.propTypes = {
  closeModal: PropTypes.func,
  deleteContentAsset: PropTypes.func,
  toggleTopNotification: PropTypes.func,
  id: PropTypes.string,
}

ConfirmContentAssetDeleteModal.defaultProps = {
  closeModal: () => {},
  deleteContentAsset: () => {},
  toggleTopNotification: () => {},
  id: '',
}

export default ConfirmContentAssetDeleteModal
